import { SettingsUnits, TSettingsDescriptions, TSettingsValues } from "./types";

// All settings saved in inches.
const defaultSettings: TSettingsValues = {
  corePlanRangesSettings: {
    storiesMax: 4,
    minFloorToPlateLevelHeight: 84,
    maxFloorToPlateLevelHeight: 120,
    bedroomMin: 2,
    bedroomMax: 6,
    bathroomMin: 2,
    bathroomMax: 4,
    carsMax: 2,
  },
  corePlanDefaults: {
    numOfStories: 2,
    numOfBedrooms: 3,
    numOfBathrooms: 3,
    numOfHalfBathrooms: 0,
    numOfCars: 2,
    minBuildingNetArea: 302400,
    maxBuildingGrossArea: 345600,
    lotArea: 432000,
    lotWidth: 600,
    lotLength: 720,
    floorToPlateLevelHeight: 96,
    floorThickness: 16.75,
    lotFrontSetback: 144,
    lotBackSetback: 108,
    lotSideSetback: 72,
    lotFront2Setback: 144,
  },
  validationSettings: {
    gravityMaxFloorSpan: 258,
    gravityMaxBeamSpan: 258,
    gravityMaxCantileverSpan: 72,
    shearF1cap: 83.333333,
    shearF2cap: 58.333333,
    shearSeismicFactorCd: 0.438,
    shearComboDesignFactor: 0.7,
    shearPreliminaryFactor: 0.85,
    exteriorWallWeight: 0.0833333333,
    interiorWallWeight: 0.0611111111,
    beamWeight: 0,
    floorWeight: 0.138888889,
    roofWeight: 0.119444444,
    openingAlignmentMinGap: 8,
    areaCalculationExteriorOffset: 8,
    gridCellSizeForNewCorePlans: 8,
    stretchAllowance: 4,
    frameSize: 3,
    openingStructuralTolerance: 4.5,
    edgeOfExteriorWallTolerance: 4.5,
    firstFloorPlumbingPointMaxOffset: 36,
    pipeFallTolerance: 4,
    minimalWallSegmentLength: 4,
    roofDefaultSlope: 0.5,
    roofDefaultOverhang: 12,
    minimumGableSeparation: 12,
    windowEdgeOffset: 0,
    doorEdgeOffset: 0,
  },
  webAppUISettings: {
    displayUnits: "feet",
    selectionColor: "#7EC2B6",
    highlightColor: "#FF00C7",
    wallsColor: "#000000",
    floorsColor: "#EFEFEF",
    openingsColor: "#D9D9D9",
    gridPrimaryColor: "#EFEFEF",
    gridSecondaryColor: "#E5E5E5",
    gravityExteriorLBColor: "#2A7F77",
    gravityStackedLBColor: "#03CB6A",
    gravityNonLBColor: "#FFBD00",
    gravityBeamColor: "#FF3B47",
    shearWalls: "#9747FF",
    shearObstructionOnCurrentLevel: "#FFBD00",
    shearObstructionOnLevelBelow: "#FF3B47",
    lotLinesColor: "#2856AE",
    lotSetbackColor: "#FF3B47",
    obsoleteRoomsColor: "#FFFFFF",
    glassColor: "#B3DCF5",
    doorColor: "#B3DCF5",
    roofSurface: "#B5B2B2",
    roofEdge: "#000000",
    livingSpaces: "#F516F9",
    garage: "#EF9610",
    porchesAndPatio: "#3351F1",
    roof: "#6A6A6A",
  },
};

const defaultUnits: TSettingsDescriptions = {
  corePlanRangesSettings: {
    storiesMax: SettingsUnits.units,
    minFloorToPlateLevelHeight: SettingsUnits.ft,
    maxFloorToPlateLevelHeight: SettingsUnits.ft,
    bedroomMin: SettingsUnits.units,
    bedroomMax: SettingsUnits.units,
    bathroomMin: SettingsUnits.units,
    bathroomMax: SettingsUnits.units,
    carsMax: SettingsUnits.units,
  },
  corePlanDefaults: {
    numOfStories: SettingsUnits.units,
    numOfBedrooms: SettingsUnits.units,
    numOfBathrooms: SettingsUnits.units,
    numOfHalfBathrooms: SettingsUnits.units,
    numOfCars: SettingsUnits.units,
    minBuildingNetArea: SettingsUnits.sqft,
    maxBuildingGrossArea: SettingsUnits.sqft,
    lotArea: SettingsUnits.sqft,
    lotWidth: SettingsUnits.ft,
    lotLength: SettingsUnits.ft,
    floorToPlateLevelHeight: SettingsUnits.ft,
    floorThickness: SettingsUnits.ft,
    lotFrontSetback: SettingsUnits.ft,
    lotBackSetback: SettingsUnits.ft,
    lotSideSetback: SettingsUnits.ft,
    lotFront2Setback: SettingsUnits.ft,
  },
  validationSettings: {
    gravityMaxFloorSpan: SettingsUnits.ft,
    gravityMaxBeamSpan: SettingsUnits.ft,
    gravityMaxCantileverSpan: SettingsUnits.ft,
    shearF1cap: SettingsUnits.plf,
    shearF2cap: SettingsUnits.plf,
    shearSeismicFactorCd: SettingsUnits.percent,
    shearComboDesignFactor: SettingsUnits.percent,
    shearPreliminaryFactor: SettingsUnits.percent,
    exteriorWallWeight: SettingsUnits.psf,
    interiorWallWeight: SettingsUnits.psf,
    beamWeight: SettingsUnits.psf,
    floorWeight: SettingsUnits.psf,
    roofWeight: SettingsUnits.psf,
    openingAlignmentMinGap: SettingsUnits.ft,
    areaCalculationExteriorOffset: SettingsUnits.ft,
    gridCellSizeForNewCorePlans: SettingsUnits.ft,
    stretchAllowance: SettingsUnits.ft,
    frameSize: SettingsUnits.ft,
    openingStructuralTolerance: SettingsUnits.ft,
    edgeOfExteriorWallTolerance: SettingsUnits.ft,
    firstFloorPlumbingPointMaxOffset: SettingsUnits.ft,
    pipeFallTolerance: SettingsUnits.ft,
    minimalWallSegmentLength: SettingsUnits.ft,
    roofDefaultSlope: SettingsUnits.percent,
    roofDefaultOverhang: SettingsUnits.ft,
    minimumGableSeparation: SettingsUnits.ft,
    windowEdgeOffset: SettingsUnits.ft,
    doorEdgeOffset: SettingsUnits.ft,
  },
  webAppUISettings: {
    livingSpaces: SettingsUnits.hex,
    garage: SettingsUnits.hex,
    porchesAndPatio: SettingsUnits.hex,
    roof: SettingsUnits.hex,
    displayUnits: "feet",
    selectionColor: SettingsUnits.hex,
    highlightColor: SettingsUnits.hex,
    wallsColor: SettingsUnits.hex,
    floorsColor: SettingsUnits.hex,
    openingsColor: SettingsUnits.hex,
    gridPrimaryColor: SettingsUnits.hex,
    gridSecondaryColor: SettingsUnits.hex,
    gravityExteriorLBColor: SettingsUnits.hex,
    gravityStackedLBColor: SettingsUnits.hex,
    gravityNonLBColor: SettingsUnits.hex,
    gravityBeamColor: SettingsUnits.hex,
    shearWalls: SettingsUnits.hex,
    shearObstructionOnCurrentLevel: SettingsUnits.hex,
    shearObstructionOnLevelBelow: SettingsUnits.hex,
    lotLinesColor: SettingsUnits.hex,
    lotSetbackColor: SettingsUnits.hex,
    obsoleteRoomsColor: SettingsUnits.hex,
    glassColor: SettingsUnits.hex,
    doorColor: SettingsUnits.hex,
    roofSurface: SettingsUnits.hex,
    roofEdge: SettingsUnits.hex,
  },
};

const defaultDescriptions: TSettingsDescriptions = {
  corePlanRangesSettings: {
    storiesMax: "Stories max",
    minFloorToPlateLevelHeight: "Min. Floor to Plate level height",
    maxFloorToPlateLevelHeight: "Max. Floor to Plate level height",
    bedroomMin: "Bedroom min",
    bedroomMax: "Bedroom max",
    bathroomMin: "Bathroom min",
    bathroomMax: "Bathroom max",
    carsMax: "Cars max",
  },
  corePlanDefaults: {
    numOfStories: "Number of stories",
    numOfBedrooms: "Number of bedrooms",
    numOfBathrooms: "Number of bathrooms",
    numOfHalfBathrooms: "Number of half bathrooms",
    numOfCars: "Number of cars",
    floorToPlateLevelHeight: "Default Floor to Plate level height",
    floorThickness: "Floor thickness",
    minBuildingNetArea: "Min building net area",
    maxBuildingGrossArea: "Max building gross area",
    lotArea: "Lot area",
    lotWidth: "Lot width",
    lotLength: "Lot length",
    lotFrontSetback: "Lot front setback",
    lotBackSetback: "Lot back setback",
    lotSideSetback: "Lot side setback",
    lotFront2Setback: "Lot front 2 setback",
  },
  validationSettings: {
    gravityMaxFloorSpan: "Gravity max floor span",
    gravityMaxBeamSpan: "Gravity max beam span",
    gravityMaxCantileverSpan: "Gravity max cantilever span",
    shearF1cap: "Shear F1cap",
    shearF2cap: "Shear F2cap",
    shearSeismicFactorCd: "Shear Seismic factor (Cd)",
    shearComboDesignFactor: "Shear combo design factor",
    shearPreliminaryFactor: "Shear preliminary  factor",
    exteriorWallWeight: "Exterior wall weight",
    interiorWallWeight: "Interior wall weight",
    beamWeight: "Beam weight",
    floorWeight: "Floor weight",
    roofWeight: "Roof weight",
    openingAlignmentMinGap: "Opening alignment min gap",
    areaCalculationExteriorOffset: "Area calculation exterior offset",
    gridCellSizeForNewCorePlans: "Grid cell size (for new corePlans)",
    stretchAllowance: "Stretch allowance",
    frameSize: "3D frame size",
    openingStructuralTolerance: "Opening structural tolerance",
    edgeOfExteriorWallTolerance: "Edge of exterior wall tolerance",
    firstFloorPlumbingPointMaxOffset: "First floor plumbing point max offset",
    pipeFallTolerance: "Pipe fall tolerance",
    minimalWallSegmentLength: "Minimal wall segment length",
    roofDefaultSlope: "Roof default slope",
    roofDefaultOverhang: "Roof default overhang",
    minimumGableSeparation: "Minimum gable separation",
    windowEdgeOffset: "Window edge offset",
    doorEdgeOffset: "Door edge offset",
  },
  webAppUISettings: {
    displayUnits: "Display units",
    selectionColor: "Selection color",
    highlightColor: "Highlight color",
    wallsColor: "Walls color",
    floorsColor: "Floors color",
    openingsColor: "Openings color",
    gridPrimaryColor: "Grid Primary color",
    gridSecondaryColor: "Grid Secondary color",
    gravityExteriorLBColor: "Gravity exterior LB color",
    gravityStackedLBColor: "Gravity stacked LB color",
    gravityNonLBColor: "Gravity non LB color",
    gravityBeamColor: "Gravity beam color",
    shearWalls: "Shear walls",
    shearObstructionOnCurrentLevel: "Shear obstruction on 0",
    shearObstructionOnLevelBelow: "Shear obstruction on -1",
    lotLinesColor: "Lot lines color",
    lotSetbackColor: "Lot setback color",
    obsoleteRoomsColor: "Obsolete rooms color",
    glassColor: "3D Preview window color",
    doorColor: "3D Preview door color",
    roofSurface: "3D Preview roof surface color",
    roofEdge: "3D Preview roof edge color",
    livingSpaces: "Living Spaces",
    garage: "Garage",
    porchesAndPatio: "Porches And Patio",
    roof: "Roof",
  },
};

export { defaultSettings, defaultDescriptions, defaultUnits };
