import { soGroup } from "../soGroup";
import { soRoom2D } from "../Room/soRoom2D";
import { GraphAnalysisUtils } from "../../../utils/GraphAnalysisUtils";
import SceneManager from "../../../managers/SceneManager/SceneManager";
import SceneUtils from "../../../utils/SceneUtils";
import { MessageKindsEnum, showToastMessage } from "../../../../helpers/messages";
import { MESSAGE_DURATION, ROOM_OVERLAP_MESSAGE } from "../../../consts";
import GeometryUtils from "../../../utils/GeometryUtils/GeometryUtils";

/**
 * Class representing a 2D floor in the scene.
 * Inherits from soGroup to allow handling multiple child components.
 */
export class soFloor2D extends soGroup {
  floorName: string;
  soRooms: soRoom2D[] = [];
  floorIndex: number;
  fileId: string;
  bindingId: string;
  properties: any[];

  /**
   * Constructs an instance of soFloor2D.
   * @param floorName - Name of the floor.
   * @param soRooms - Array of rooms contained in the floor.
   * @param floorIndex - Index of the floor.
   */
  constructor(floorId, floorName, floorIndex) {
    super();
    this.soId = floorId;
    this.floorName = floorName;
    this.floorIndex = floorIndex;
  }

  addRoom(soRoom: soRoom2D): void {
    soRoom.ParentFloorId = this.soId;
    this.soRooms.push(soRoom);
    this.add(soRoom);
  }
  addRooms(soRooms: soRoom2D[]): void {
    soRooms.forEach(soRoom => (soRoom.ParentFloorId = this.soId));
    this.soRooms.push(...soRooms);
    this.add(...soRooms);
  }
  removeRoom(soRoom: soRoom2D): void {
    const index = this.soRooms.indexOf(soRoom);
    if (index > -1) {
      this.soRooms.splice(index, 1);
      this.remove(soRoom);
      GeometryUtils.disposeObject(soRoom);
    }
  }
  //todo: update to standardize with other methods
  public regenerateRoomsWalls(SceneManager: SceneManager, updateSegements: boolean = true): void {
    const soRooms = this.soRooms;
    // Dispose of existing synthetic walls in all rooms
    soRooms.forEach(soRoom => soRoom.disposeRoomWalls());
    if (updateSegements) SceneManager.updateSegmentsCache();
    // Collect all wall segments
    const { externalSegments, internalSegments } = GraphAnalysisUtils.collectSegments(soRooms);

    const floorSegments = SceneManager.getFloorSegments(this.soId); // Retrieve the wall segments for the current floor.
    // Process segments with walls (both external and internal)
    externalSegments.concat(internalSegments).forEach(segment => {
      if (segment.hasWall) {
        GraphAnalysisUtils.processAndCreateWallSegments(segment, floorSegments, soRooms, segment);
      }
    });

    // Process external segments without walls
    externalSegments.forEach(segment => {
      if (!segment.hasWall) {
        GraphAnalysisUtils.createAndPositionWall(segment, floorSegments, soRooms, segment);
      }
    });
  }

  checkRoomsOverlapping(showErrorMessage = true): void {
    const intersectedRooms: Set<soRoom2D> = new Set<soRoom2D>();
    const toUnHighLight: Set<soRoom2D> = new Set<soRoom2D>();

    const soRooms = this.soRooms;

    for (let i = 0; i < soRooms.length; i++) {
      // reset helper properties
      delete soRooms[i].userData.isOverlapped;
      delete soRooms[i].userData.isTooCloseToOther;

      for (let j = i + 1; j < soRooms.length; j++) {
        if (intersectedRooms.has(soRooms[i]) && intersectedRooms.has(soRooms[j])) {
          continue;
        }
        if (SceneUtils.areSoRoomsOverlapping(soRooms[i], soRooms[j])) {
          intersectedRooms.add(soRooms[i]);
          intersectedRooms.add(soRooms[j]);
        }
      }

      if (!intersectedRooms.has(soRooms[i])) {
        toUnHighLight.add(soRooms[i]);
      }
    }

    intersectedRooms.forEach(room => SceneUtils.highlightIntersectedRoom(room));
    toUnHighLight.forEach(room => SceneUtils.unhighlightIntersectedRoom(room));

    if (showErrorMessage && soRooms.some(soRoom => soRoom.userData.isOverlapped)) {
      showToastMessage(MessageKindsEnum.Error, ROOM_OVERLAP_MESSAGE, { autoClose: MESSAGE_DURATION });
    }
  }
}
