import * as THREE from "three";
import { soObject } from "../soObject";
import RoomUtils from "../../../utils/RoomUtils";
import { RawRoomEntity } from "../../../../services/rawTypes";
import { deepCopy2DTO } from "../../../../helpers/pojo";
import { EntityDataType } from "../../../../entities/entityManager";

/**
 * Class representing a boundary line in the scene.
 * Inherits from soObject.
 */
export class soBoundaryLine extends soObject {
  line: THREE.Line;
  lineType: string;
  fileId: string;
  bindingId: string;
  properties: any[];
  // side: RoomUtils.getRoomLinesByType()
  //modelLine
  /**
   * Constructs an instance of soBoundaryLine.
   * @param soId - soId representing the line id.
   * @param line - THREE.Line representing the boundary line.
   */
  constructor(soId: string, line: THREE.Line) {
    super();

    this.soId = soId;
    this.line = line;
    if (line) {
      this.add(line);
    }
    this.lineType = line.name;
  }
}
