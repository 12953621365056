import { Segment } from "../../models/segments/Segment";
import * as THREE from "three";

export class SegmentOffsetManager {
  // map of wall segments that have offset by start point (start:x,start:y,end:x,end:y)
  private segmentsOffsets = new Map<string, THREE.Object3D>();

  addSegmentOffset(obj: THREE.Object3D): void {
    const index = SegmentOffsetManager.getOffsetObject3DIndex(obj);
    this.segmentsOffsets.set(index, obj);
  }

  removeSegmentOffset(obj: THREE.Object3D): void {
    const index = SegmentOffsetManager.getOffsetObject3DIndex(obj);
    if (this.segmentsOffsets.has(index)) {
      this.segmentsOffsets.delete(index);
    }
  }

  findSegmentOffset(segment: Segment): number {
    const index = SegmentOffsetManager.getOffsetSegmentIndex(segment);
    return this.segmentsOffsets.get(index)?.userData?.offset ?? 0;
  }

  clearSegmentOffsets(): void {
    this.segmentsOffsets.clear();
  }

  getAllOffsets() {
    return this.segmentsOffsets;
  }

  static getOffsetObject3DIndex(obj: THREE.Object3D): string {
    return SegmentOffsetManager.generateOffsetIndex(
      obj.userData.segment.start.x,
      obj.userData.segment.start.y,
      obj.userData.segment.end.x,
      obj.userData.segment.end.y
    );
  }

  static getOffsetSegmentIndex(segment: Segment): string {
    return SegmentOffsetManager.generateOffsetIndex(segment.start.x, segment.start.y, segment.end.x, segment.end.y);
  }

  static generateOffsetIndex(startX: number, startY: number, endX: number, endY: number): string {
    return `${startX.toFixed(2)},${startY.toFixed(2)},${endX.toFixed(2)},${endY.toFixed(2)}`;
  }
}
