import * as ExcelJS from "exceljs";
import * as THREE from "three";
import { appModel } from "../../models/AppModel";
import { Floor } from "../../models/Floor";
import { CorePlan } from "../../models/CorePlan";
import { Room } from "../../models/Room";
import { RoomEntityType } from "../../models/RoomEntityType";
import { Segment } from "../models/segments/Segment";
import GeometryUtils from "../utils/GeometryUtils/GeometryUtils";
import RoomManager from "../managers/RoomManager/RoomManager";
import { SceneEntityType } from "../models/SceneEntityType";
import SceneUtils from "../utils/SceneUtils";
import { RoomEntityProperties } from "../../models/RevitRoomType";
import UnitsUtils from "../utils/UnitsUtils";
import { inches2feet, inches2feetSq } from "../../helpers/measures";
import MathUtils from "../utils/MathUtils";
import { WallAnalysisUtils } from "../utils/WallAnalysisUtils";
import RoomUtils from "../utils/RoomUtils";
import VectorUtils from "../utils/GeometryUtils/VectorUtils";
import SceneManager from "../managers/SceneManager/SceneManager";
import { GraphAnalysisUtils } from "../utils/GraphAnalysisUtils";

export default class TotalMaterialListTool {
  fillColor: string = "ffd9d9d9";
  analysisUtils = this.roomManager instanceof SceneManager ? GraphAnalysisUtils : WallAnalysisUtils;
  constructor(private roomManager: any) {
    if (!(this.roomManager instanceof RoomManager) && !(this.roomManager instanceof SceneManager)) {
      throw new Error("Manager is not an instance of RoomManager");
    }
  }

  public async generateReport(): Promise<File> {
    const CorePlanSummary = this.collectCorePlanSummary(appModel.activeCorePlan);
    const workbook = new ExcelJS.Workbook();

    let worksheet = workbook.addWorksheet("CorePlan information");
    this.writeCorePlanSheet(worksheet, CorePlanSummary);

    CorePlanSummary.floors.forEach(floorSummary => {
      worksheet = workbook.addWorksheet(floorSummary.name);
      this.writeFloorSheet(worksheet, floorSummary);
    });
    const fileName = `${appModel.activeCorePlan.name}.xlsx`;
    const buffer = await workbook.xlsx.writeBuffer();
    return new File([buffer], fileName, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  }

  public calculateGrossArea(floor: Floor): number {
    const soFloor = this.roomManager.getSoFloor(floor.id);
    const rooms = floor.rooms.filter(room => appModel.getRoomType(room.roomTypeId).attributes.indoor);
    const soRooms = soFloor.children.filter(child => rooms.some(room => room.id === child.userData.id));

    const offset = appModel.includeCladdingThickness ? UnitsUtils.getAreaCalculationExteriorOffset() : UnitsUtils.getSyntheticWallHalfSize();
    return this.calculateGrossAreaForSoRooms(soRooms, offset);
  }

  public calculateNetArea(floor: Floor): number {
    const soFloor = this.roomManager.getSoFloor(floor.id);
    const rooms = floor.rooms.filter(room => {
      const roomType = appModel.getRoomType(room.roomTypeId);
      return roomType.attributes.netArea && roomType.attributes.indoor;
    });
    const soRooms = soFloor.children.filter(child => rooms.some(room => room.id === child.userData.id));

    const offset = appModel.includeCladdingThickness ? UnitsUtils.getAreaCalculationExteriorOffset() : UnitsUtils.getSyntheticWallHalfSize();
    return this.calculateGrossAreaForSoRooms(soRooms, offset);
  }

  private calculateGrossAreaForSoRooms(soRooms: any, grossAreaOffset: number): number {
    // The algorithm assumes that all segments are oriented from bottom to top or from left to right
    const segments = this.analysisUtils.collectSegments(soRooms).externalSegments;
    if (segments.length === 0) {
      return 0;
    }

    const contours: Segment[][] = [];
    let contour: Segment[] = [this.extractStartSegment(segments)];

    // build floor contours directed counterclockwise
    while (segments.length) {
      const lastSegment = contour[contour.length - 1];

      const linkedSegments = segments.filter(
        segment => VectorUtils.areVectors2Equal(lastSegment.end, segment.start) || VectorUtils.areVectors2Equal(lastSegment.end, segment.end)
      );
      linkedSegments.forEach(segment => {
        if (VectorUtils.areVectors2Equal(lastSegment.end, segment.end)) {
          segment.revert();
        }
      });

      if (linkedSegments.length) {
        let nextSegment: Segment = linkedSegments[0];

        if (linkedSegments.length > 1) {
          // Find right segment.
          const last = lastSegment.delta().normalize();

          linkedSegments.forEach(segment => {
            if (last.cross(segment.delta().normalize()) < last.cross(nextSegment.delta().normalize())) {
              nextSegment = segment;
            }
          });
        }
        segments.splice(segments.indexOf(nextSegment), 1);

        // Merge same oriented linked segments.
        if ((lastSegment.isHorizontal() && nextSegment.isHorizontal()) || (!lastSegment.isHorizontal() && !nextSegment.isHorizontal())) {
          lastSegment.end = nextSegment.end;
        } else {
          contour.push(nextSegment);
        }
      } else {
        contours.push(contour);
        contour = [this.extractStartSegment(segments)];
      }
    }
    contours.push(contour);

    const roomBoxesMap = soRooms.reduce(
      (map, soRoom) => map.set(soRoom.userData.id, RoomUtils.getRoomBoundingBoxByModelLines(soRoom)),
      new Map<string, THREE.Box3>()
    );

    let area: number = 0;
    for (const bb of roomBoxesMap.values()) {
      const size = bb.getSize(new THREE.Vector3());
      area += size.x * size.y;
    }

    // Calculate offset addendum.
    contours.forEach((contour: Segment[]) => {
      // In counterclockwise direction: leftRotations = rightRotations + 4.
      //        * ----<-----*
      //        \/         /\
      //        |          |
      //  * -<--*-----*-->--*
      //  \/         /\
      //  |          |
      //  *---->-----*

      const roomCenter = roomBoxesMap.get(contour[0].roomId.length && contour[0].roomId[0]).getCenter(new THREE.Vector3());
      const isOuterContour =
        contour[0]
          .delta()
          .normalize()
          .cross(new THREE.Vector2(roomCenter.x - contour[0].start.x, roomCenter.y - contour[0].start.y)) > 0;
      const rightRotations = (contour.length - 4) / 2;
      const leftRotations = rightRotations + 4;
      const offset2 = grossAreaOffset * grossAreaOffset;

      if (isOuterContour) {
        area += rightRotations * offset2 + leftRotations * 3 * offset2;
      } else {
        area += leftRotations * offset2 + rightRotations * 3 * offset2;
      }
      area += contour.reduce((sum, seg) => sum + (seg.length() - 2 * grossAreaOffset) * grossAreaOffset, 0);
    });

    return area;
  }
  private extractStartSegment(segments: Segment[]): Segment {
    let startSegment: Segment;
    let fallback: Segment;
    segments.forEach(seg => {
      if (!seg.isHorizontal()) {
        if (!fallback || seg.start.x > fallback.start.x) {
          fallback = seg;
        }
        return;
      }

      if (!startSegment || seg.start.y < startSegment.start.y) {
        startSegment = seg;
      }
    });

    const result = startSegment || fallback;
    segments.splice(segments.indexOf(result), 1);

    return result;
  }

  private calculateAirConditionedArea(floor: Floor): number {
    const soFloor = this.roomManager.getSoFloor(floor.id);
    const rooms = floor.rooms.filter(room => {
      const roomType = appModel.getRoomType(room.roomTypeId);
      return roomType.attributes.netArea && roomType.attributes.indoor;
    });
    const soRooms = soFloor.children.filter(child => rooms.some(room => room.id === child.userData.id));
    const wallBbs = soRooms
      .flatMap(soRoom =>
        soRoom.children.filter(
          child =>
            child.userData.type === RoomEntityType.Wall ||
            child.userData.type === RoomEntityType.PlumbingWall ||
            child.userData.type === SceneEntityType.SyntheticWall
        )
      )
      .map(child => GeometryUtils.getGeometryBoundingBox2D(child));

    let area = 0;

    soRooms.forEach(soRoom => {
      const bb = RoomUtils.getRoomBoundingBoxByModelLines(soRoom);
      const segments = this.analysisUtils.findRoomInnerContour(
        bb,
        wallBbs.filter(wallBb => bb.intersectsBox(wallBb))
      );

      segments.forEach(segment => {
        area += segment.start.x * segment.end.y - segment.start.y * segment.end.x;
      });
    });

    area /= 2;

    return area;
  }

  private collectDemandData(corePlan: CorePlan): DemandData {
    const roundPrecision = UnitsUtils.getRoundPrecision();

    const data = new DemandData();
    data.floorCount = corePlan.attributes.floors;
    data.floorHeight = corePlan.firstFloorToPlateHeight;
    data.homeSize = corePlan.homeSize;
    data.grossArea = MathUtils.round(
      corePlan.floors.reduce((sum, floor) => sum + this.calculateGrossArea(floor), 0),
      roundPrecision * roundPrecision
    );
    data.netArea = MathUtils.round(
      corePlan.floors.reduce((sum, floor) => sum + this.calculateNetArea(floor), 0),
      roundPrecision * roundPrecision
    );
    data.acArea = MathUtils.round(
      corePlan.floors.reduce((sum, floor) => sum + this.calculateAirConditionedArea(floor), 0),
      roundPrecision * roundPrecision
    );
    data.lotSize = corePlan.attributes.lotSize || 0;

    return data;
  }
  private collectQuantitySummary(corePlan: CorePlan): QuantitySummary {
    const summary = new QuantitySummary();

    corePlan.getRooms().forEach(room => {
      const roomType = appModel.getRoomType(room.roomTypeId);
      const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);

      roomType.roomEntities.forEach(entity => {
        const familyType = entity.properties?.find(prop => prop.name === RoomEntityProperties.FamilyType)?.value;
        const familyName = entity.properties?.find(prop => prop.name === RoomEntityProperties.FamilyName)?.value ?? "";
        switch (entity.type) {
          case RoomEntityType.Door: {
            const count = summary.door.get(familyType);
            summary.door.set(familyType, (count ?? 0) + 1);
            break;
          }
          case RoomEntityType.Window: {
            const count = summary.window.get(familyType);
            summary.window.set(familyType, (count ?? 0) + 1);
            break;
          }
          case RoomEntityType.Furniture: {
            const family = `${familyName}: ${familyType}`;
            const count = summary.furniture.get(family);
            summary.furniture.set(family, (count ?? 0) + 1);
            break;
          }
        }
      });

      if (roomCategory.isBedroom) {
        summary.bedroomCount++;
      }

      if (roomCategory.isGarage) {
        summary.garageCount++;
      }

      if (roomCategory.isKitchen) {
        const count = summary.kitchen.get(room.name);
        summary.kitchen.set(appModel.getRoomType(room.roomTypeId).name, (count ?? 0) + 1);
      }

      if (roomCategory.isStorage) {
        const count = summary.storage.get(room.name);
        summary.storage.set(appModel.getRoomType(room.roomTypeId).name, (count ?? 0) + 1);
      }
    });

    return summary;
  }
  private collectRoomSummary(room: Room): RoomSummary {
    const roomType = appModel.getRoomType(room.roomTypeId);
    const summary = new RoomSummary();

    summary.name = roomType.name;
    summary.indoor = !!roomType.attributes.indoor;
    summary.length = room.height;
    summary.width = room.width;
    summary.perimeter = 2 * summary.length + 2 * summary.width;
    summary.area = summary.length * summary.width;

    return summary;
  }
  private collectFloorSummary(floor: Floor): FloorSummary {
    const summary = new FloorSummary();
    const soFloor = this.roomManager.getSoFloor(floor.id);

    summary.name = floor.name;
    summary.index = floor.index;
    summary.rooms = floor.rooms.map(room => this.collectRoomSummary(room));
    summary.area = summary.rooms.reduce((sum, rs) => (rs.indoor ? sum + rs.area : sum), 0);

    const { externalSegments, internalSegments } = this.analysisUtils.collectSegments(soFloor.children);

    summary.perimeter = externalSegments.reduce((sum, seg) => sum + seg.length(), 0);
    summary.interiorWallLength = internalSegments.reduce((sum, seg) => (seg.hasWall ? sum + seg.length() : sum), 0);
    summary.totalWallLength = summary.perimeter + summary.interiorWallLength;

    return summary;
  }
  private collectCorePlanSummary(corePlan: CorePlan): CorePlanSummary {
    const summary = new CorePlanSummary();

    summary.name = corePlan.name;
    //summary.address = corePlan.location && corePlan.location !== "" ? corePlan.location : "-";
    summary.demandData = this.collectDemandData(corePlan);
    summary.quantitySummary = this.collectQuantitySummary(corePlan);

    summary.floors = corePlan.floors.map(floor => this.collectFloorSummary(floor));
    summary.floors.sort((a, b) => a.index - b.index);
    summary.cost = corePlan.cost;
    summary.costPerSqft = corePlan.costPerSqft;
    summary.co2Emission = corePlan.co2Emission;

    return summary;
  }

  private writeCorePlanSheet(worksheet: ExcelJS.Worksheet, summary: CorePlanSummary): void {
    const applyHeaderStyle = (cell: ExcelJS.Cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.fillColor },
      };
      cell.border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
      };
    };

    let row = worksheet.addRow(["CorePlan information", "", "units", "value", "", ""]);
    row.eachCell(applyHeaderStyle);

    worksheet.addRow(["Name", "", "-", summary.name]);
    worksheet.addRow(["Address", "", "-", summary.address]);

    this.addRow(worksheet, ["Lot size", "", "sqft", this.createNumericCellDescriptor(inches2feetSq(summary.demandData.lotSize))]);
    this.addRow(worksheet, ["Home size", "", "sqft", this.createNumericCellDescriptor(inches2feetSq(summary.demandData.homeSize))]);
    this.addRow(worksheet, ["Gross area", "", "sqft", this.createNumericCellDescriptor(inches2feetSq(summary.demandData.grossArea))]);
    this.addRow(worksheet, ["Net area", "", "sqft", this.createNumericCellDescriptor(inches2feetSq(summary.demandData.netArea))]);
    // this.addRow(worksheet, ["AC area", "", "sqft", this.createNumericCellDescriptor(inches2feetSq(summary.demandData.acArea))]);
    this.addRow(worksheet, ["Stories", "", "-", this.createNumericCellDescriptor(summary.demandData.floorCount)]);
    this.addRow(worksheet, ["Garage", "", "-", this.createNumericCellDescriptor(summary.quantitySummary.garageCount)]);
    this.addRow(worksheet, ["Bedrooms", "", "-", this.createNumericCellDescriptor(summary.quantitySummary.bedroomCount)]);
    this.addRow(worksheet, ["Story height", "", "ft", this.createNumericCellDescriptor(inches2feet(summary.demandData.floorHeight))]);
    this.addRow(worksheet, ["Cost", "", "USD", summary.cost ? this.createNumericCellDescriptor(Math.round(summary.cost)) : "-"]);
    this.addRow(worksheet, ["Cost per sqft", "", "USD", summary.costPerSqft ? this.createNumericCellDescriptor(Math.round(summary.costPerSqft)) : "-"]);
    this.addRow(worksheet, ["CO2 Emission", "", "kg per sqft", summary.co2Emission ? this.createNumericCellDescriptor(Math.round(summary.co2Emission)) : "-"]);

    row = worksheet.addRow(["Component quantity", "", "units", "type", "", "quantity"]);
    row.eachCell(applyHeaderStyle);

    const window = Array.from(summary.quantitySummary.window, ([key, value]) => ({ key, value }));
    const door = Array.from(summary.quantitySummary.door, ([key, value]) => ({ key, value }));
    const furniture = Array.from(summary.quantitySummary.furniture, ([key, value]) => ({ key, value }));
    const kitchen = Array.from(summary.quantitySummary.kitchen, ([key, value]) => ({ key, value }));
    const storage = Array.from(summary.quantitySummary.storage, ([key, value]) => ({ key, value }));

    this.addRow(worksheet, ["Window", "", "-", "", "", this.createNumericCellDescriptor(window.reduce((sum, it) => sum + it.value, 0))]);
    for (const pair of window) {
      this.addRow(worksheet, ["", "", "", pair.key, "", this.createNumericCellDescriptor(pair.value)]);
    }

    this.addRow(worksheet, ["Door", "", "-", "", "", this.createNumericCellDescriptor(door.reduce((sum, it) => sum + it.value, 0))]);
    for (const pair of door) {
      this.addRow(worksheet, ["", "", "", pair.key, "", this.createNumericCellDescriptor(pair.value)]);
    }

    this.addRow(worksheet, ["Furniture", "", "-", "", "", this.createNumericCellDescriptor(furniture.reduce((sum, it) => sum + it.value, 0))]);
    for (const pair of furniture) {
      this.addRow(worksheet, ["", "", "", pair.key, "", this.createNumericCellDescriptor(pair.value)]);
    }

    if (kitchen.length) {
      this.addRow(worksheet, ["Kitchen", "", "-", kitchen[0].key, "", this.createNumericCellDescriptor(kitchen[0].value)]);
      for (let i = 1; i < kitchen.length; i++) {
        this.addRow(worksheet, ["", "", "", kitchen[i].key, "", this.createNumericCellDescriptor(kitchen[i].value)]);
      }
    } else {
      worksheet.addRow(["Kitchen", "", "-", "", "", 0]);
    }

    if (storage.length) {
      this.addRow(worksheet, ["Storage", "", "-", storage[0].key, "", this.createNumericCellDescriptor(storage[0].value)]);
      for (let i = 1; i < storage.length; i++) {
        this.addRow(worksheet, ["", "", "", storage[i].key, "", this.createNumericCellDescriptor(storage[i].value)]);
      }
    } else {
      worksheet.addRow(["Storage", "", "-", "", "", 0]);
    }

    for (let i = 1; i <= worksheet.rowCount; i++) {
      worksheet.mergeCells(`A${i}:B${i}`);
      worksheet.mergeCells(`D${i}:E${i}`);
    }

    worksheet.lastRow.eachCell(cell => {
      cell.border = {
        bottom: { style: "thin" },
      };
    });
  }

  private writeFloorSheet(worksheet: ExcelJS.Worksheet, summary: FloorSummary): void {
    const fillHeaderColor = (cell: ExcelJS.Cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.fillColor },
      };
    };

    worksheet.getColumn("A").width = 15;

    let row = worksheet.addRow(["Calculations per story", "", "units", "value", ""]);
    row.eachCell(cell => {
      fillHeaderColor(cell);
      cell.border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
      };
    });

    worksheet.addRow(["Story name", "", "-", summary.name]);
    this.addRow(worksheet, ["Story perimeter", "", "ft", this.createNumericCellDescriptor(inches2feet(summary.perimeter))]);
    this.addRow(worksheet, ["Story perimeter area", "", "sqft", this.createNumericCellDescriptor(inches2feetSq(summary.area))]);
    this.addRow(worksheet, ["Story total wall length", "", "ft", this.createNumericCellDescriptor(inches2feet(summary.totalWallLength))]);
    this.addRow(worksheet, ["Story Interior wall length", "", "ft", this.createNumericCellDescriptor(inches2feet(summary.interiorWallLength))]);

    for (let i = 1; i <= worksheet.rowCount; i++) {
      worksheet.mergeCells(`A${i}:B${i}`);
    }

    row = worksheet.addRow(["Room name", "Room centerline area", "Room length", "Room width", "Room perimeter"]);
    row.height = 50;
    row.eachCell(cell => {
      fillHeaderColor(cell);
      cell.alignment = { wrapText: true };
      cell.border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
      };
    });

    row = worksheet.addRow(["units", "sqft", "ft", "ft", "ft"]);

    row.eachCell(cell => {
      fillHeaderColor(cell);
      cell.border = {
        bottom: { style: "thin" },
      };
    });

    summary.rooms.forEach(rs => {
      const area = inches2feetSq(rs.area);
      const length = inches2feet(rs.length);
      const width = inches2feet(rs.width);
      const perimeter = inches2feet(rs.perimeter);

      this.addRow(worksheet, [
        rs.name,
        this.createNumericCellDescriptor(area),
        this.createNumericCellDescriptor(length),
        this.createNumericCellDescriptor(width),
        this.createNumericCellDescriptor(perimeter),
      ]);
    });

    worksheet.lastRow.eachCell(cell => {
      cell.border = {
        bottom: { style: "thin" },
      };
    });
  }

  private addRow(worksheet: ExcelJS.Worksheet, values: any[]): ExcelJS.Row {
    const row = worksheet.addRow([]);
    const descriptors = values.map(it => (it instanceof CellDescriptor ? it : new CellDescriptor({ value: it })));

    descriptors.forEach(descriptor => descriptor.createCell(row));

    return row;
  }

  private createNumericCellDescriptor(value: number): CellDescriptor {
    return new CellDescriptor({ value, numFmt: "#,##0" });
  }

  private saveReport(byte: ExcelJS.Buffer, fileName: string): void {
    const blob = new Blob([byte], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  }
}

class RoomSummary {
  name: string = "";
  area: number = 0;
  length: number = 0;
  width: number = 0;
  perimeter: number = 0;
  indoor: boolean = false;
}

class FloorSummary {
  name: string = "";
  index: number = 0;
  perimeter: number = 0;
  area: number = 0;
  totalWallLength: number = 0;
  interiorWallLength: number = 0;
  rooms: RoomSummary[];
}

class DemandData {
  lotSize: number = 0;
  homeSize: number = 0;
  grossArea: number = 0;
  netArea: number = 0;
  acArea: number = 0;
  floorCount: number = 0;
  floorHeight: number = 0;
}
class QuantitySummary {
  garageCount: number = 0;
  bedroomCount: number = 0;
  window: Map<string, number> = new Map();
  door: Map<string, number> = new Map();
  furniture: Map<string, number> = new Map();
  kitchen: Map<string, number> = new Map();
  storage: Map<string, number> = new Map();
}

class CorePlanSummary {
  name: string = "";
  address: string = "";
  demandData: DemandData;
  quantitySummary: QuantitySummary;
  floors: FloorSummary[];
  cost: number;
  costPerSqft: number;
  co2Emission: number;
}

interface ICellDescriptor {
  value: any;
  numFmt?: string;
}

class CellDescriptor implements ICellDescriptor {
  public value: any;
  public numFmt?: string;

  public constructor(data: ICellDescriptor) {
    this.value = data.value;
    this.numFmt = data.numFmt;
  }

  public createCell(row: ExcelJS.Row): ExcelJS.Cell {
    const cell = row.getCell(row.cellCount + 1);

    cell.value = this.value;
    cell.numFmt = this.numFmt;

    return cell;
  }
}
