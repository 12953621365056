import log from "loglevel";
import { IReactionDisposer, reaction, runInAction } from "mobx";
import * as THREE from "three";
import * as _ from "lodash";

import RoomOpening from "../../../models/RoomOpening";
import TrackballControls from "../../libs/TrackballControls";
import lineclip from "../../libs/polyline";
import MeasureTool from "../../tools/MeasureTool";
import OpeningAlignmentTool from "../../tools/OpeningAlignmentTool";
import RoomDimensionTool from "../../tools/SoRoomDimensionTool";
import RoomDragAndDropTool from "../../tools/RoomDragAndDropTool";
import RoomSnapTool from "../../tools/RoomSnapTool";
import ShortWallSegmentsTool from "../../tools/ShortWallSegmentsTool";
import TotalMaterialListTool from "../../tools/TotalMaterialListTool";
import ValidationTool from "../../tools/ValidationTools/SoValidationTool";
import WindowSelectionTool from "../../tools/WindowSelectionTool";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import MathUtils from "../../utils/MathUtils";
import SceneUtils from "../../utils/SceneUtils";
import SegmentsUtils from "../../utils/SegmentsUtils";
import UnitsUtils from "../../utils/UnitsUtils";
import BaseManager from "../BaseManager";
import GridAndRulersManager from "./GridAndRulersManager";
import RoomOpeningManager from "./RoomOpeningManager";
import RoomEditToolPosition from "../../tools/RoomEditToolPosition";
import RoomWallManager from "./RoomWallManager";
import RoomUtils from "../../utils/RoomUtils";
import VectorUtils from "../../utils/GeometryUtils/VectorUtils";
import SnapEvent from "../../models/SnapEvent";
import { Direction } from "../../models/Direction";
import { DragMode } from "../../models/DragMode";
import { Keys } from "../../models/Keys";
import { ReferenceLine } from "../../models/ReferenceLine";
import { RoomDrawing, RoomOpeningData, RoomStretchData } from "../../models/RoomDrawing";
import { SceneEditorMode } from "../../models/SceneEditorMode";
import { SceneEntityType } from "../../models/SceneEntityType";
import { ValidationMode } from "../../models/ValidationType";
import { SoAddRoomCommand } from "../../models/commands/SoAddRoomCommand";
import { SoDeleteRoomCommand } from "../../models/commands/SoDeleteRoomCommand";
import { MirrorRoomCommand } from "../../models/commands/MirrorRoomCommand";
import { MultiCommand } from "../../models/commands/MultiCommand";
import { PasteRoomCommand } from "../../models/commands/SoPasteRoomCommand";
import { RoomCommand } from "../../models/commands/RoomCommand";
import { RotateRoomCommand } from "../../models/commands/RotateRoomCommand";
import { RotateRoomContentCommand } from "../../models/commands/RotateRoomContentCommand";
import { StretchRoomCommand } from "../../models/commands/StretchRoomCommand";
import { TranslateRoomCommand } from "../../models/commands/TranslateRoomCommand";
import { ISegments } from "../../models/segments/ISegments";
import { ISegmentsCache } from "../../models/segments/ISegmentsCache";
import { SoRoomCommandManager } from "../CommandManager/SoRoomCommandManager";
import { BackgroundManager } from "./BackgroundManager";
import { LotLineManager } from "./LotLineManager";
import { SoPreviewRoom } from "../../models/scene/SoPreviewRoom";
import { FuncCode } from "../../../entities/catalogSettings/types";
import { IManager } from "../IManager";
import { soFloor2D } from "../../models/SceneObjects/Floor/soFloor2D";
import { soRoom2D } from "../../models/SceneObjects/Room/soRoom2D";
import { soFloor2DRoot } from "../../models/SceneObjects/Floor/soFloor2DRoot";
import { settings } from "../../../entities/settings";
import { MessageKindsEnum, showToastMessage } from "../../../helpers/messages";
import { isWooMode } from "../../../helpers/utilities";
import { appModel } from "../../../models/AppModel";
import { CursorStyle } from "../../../models/CursorStyle";
import { Floor } from "../../../models/Floor";
import { CorePlan } from "../../../models/CorePlan";
import { Room } from "../../../models/Room";
import { RoomEntity } from "../../../models/RoomEntity";
import { RoomEntityType } from "../../../models/RoomEntityType";
import { AssetsState, RoomType } from "../../../models/RoomType";
import { Side } from "../../../models/Side";
import { IContextMenuOptions } from "../../../ui/components/common/ContextMenu";
import {
  CLADDING_INTERSECTION_ERROR,
  CLADDING_OUTSIDE_LOT_OFFSET_ERROR,
  EPSILON,
  INACTIVE_MODEL_LINE_COLOR,
  MESSAGE_DURATION,
  MODEL_LINE_COLOR,
  NO_FLOORS_ERROR,
  OBSOLETE_ROOMS_REPLACEMENT_ERROR_MESSAGE,
  OUT_OF_LOTLINE_ERROR_MESSAGE,
  OUT_OF_OFFSET_ERROR_MESSAGE,
  SCENE_AMBIENT_LIGHT_COLOR,
  SNAP_WARNING_MESSAGE,
  TOOLTIP_DELAY,
} from "../../consts";
import { soBoundaryLine } from "../../models/SceneObjects/RoomBoundary/soBoundaryLine";
import { soRoomBoundary } from "../../models/SceneObjects/RoomBoundary/soRoomBoundary";
import { soRoomItem2D } from "../../models/SceneObjects/RoomItem/soRoomItem2D";
import FloorUtils from "../../utils/FloorUtils";
import { GraphAnalysisUtils } from "../../utils/GraphAnalysisUtils";
import WallManager from "../../models/graph/WallManager";
import { ChangeWallAlignmentCommand } from "../../models/commands/ChangeWallAlignmentCommand";
import { ChangeWallWidthCommand } from "../../models/commands/ChangeWallWidthCommand";
import { soDataBox } from "../../models/SceneObjects/DataBox/soDataBox";

export default class SceneManager implements IManager {
  private reactions: IReactionDisposer[] = [];
  private selectedRoomReactions: IReactionDisposer[] = [];

  private corePlan: CorePlan = null;

  public intersectionPoint: THREE.Vector3 | null;
  public isPanning: boolean = false;

  private scene: THREE.Scene;
  public camera: THREE.PerspectiveCamera;
  public controls: TrackballControls;

  private originalDistanceCameraToOrigin: number = 0.0;

  public raycaster: THREE.Raycaster;
  private raycasterLinePrecisionUnitFactor = 0.02;
  private raycasterLinePrecisionDistanceFactor = 0.2;

  private soRoot: THREE.Group = null;
  private soFloorsRoot: soFloor2DRoot = null;

  public soGridAndRulersRoot: THREE.Group = null;
  public intersectionsPlane: THREE.Mesh;

  private soCachedRooms: Map<string, soRoom2D> = new Map<string, soRoom2D>();
  private soDraggedRoom: soRoom2D = null;
  private draggedRoom: Room = null;
  private soCopiedRooms: soRoom2D[] = [];
  private copiedRooms: Room[] = [];
  private soReplaceableRooms: { oldRoom: soRoom2D; newRoom: soRoom2D } = null;
  private soIntersectedStretchControl: THREE.Object3D = null; // TODO: change to soStretchControl2D

  private dragMode: DragMode = DragMode.none;
  private copiedRoomsStartPosition: THREE.Vector3 = new THREE.Vector3();
  private previousStretch: { [index: string]: ReferenceLine } = null;
  private moveStartPosition: THREE.Vector3 = null;
  private mouseDragEndPosition: THREE.Vector3;
  private roomTooltipTimer: NodeJS.Timeout = null;
  public blockedDoors: THREE.Object3D[] = []; // TODO: change to soDoor2D

  public commandManager: SoRoomCommandManager = null;

  public gridAndRulersManager: GridAndRulersManager = null;
  public backgroundManager: BackgroundManager = null;
  public lotLineManager: LotLineManager = null;
  public roomOpeningManager: RoomOpeningManager = null;
  public roomWallManager: RoomWallManager = null;
  public wallManager: WallManager = null;

  private windowSelectionTool: WindowSelectionTool = null;
  private roomSnapTool: RoomSnapTool = null;
  public roomDragAndDropTool: RoomDragAndDropTool = null;
  public floorDimensionTool: RoomDimensionTool = null;
  public selectedRoomsDimensionTool: RoomDimensionTool = null;
  public validationTool: ValidationTool = null;
  public tmlTool: TotalMaterialListTool = null;
  public openingTool: OpeningAlignmentTool = null;
  public measureTool: MeasureTool = null;
  public shortWallSegmentsTool: ShortWallSegmentsTool = null;
  public segmentsCache: ISegmentsCache; // TODO create SegmentsCache
  private mouseIsDown: boolean = false;
  public snapEvents: { [key: string]: SnapEvent } = {};
  private debouncedSetPosition: () => void;

  constructor(public baseManager: BaseManager) {
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(75, 1.0, 0.1, 100000);
    this.camera.position.copy(this.scene.position);
    this.camera.translateZ(15.0 * UnitsUtils.getConversionFactor());
    this.camera.lookAt(this.scene.position);
    this.originalDistanceCameraToOrigin = this.camera.position.distanceTo(this.scene.position);

    this.controls = new TrackballControls(this.camera);
    this.controls.noRotate = true;
    this.controls.zoomSpeed = 1.7;
    this.controls.minDistance = 1.0 * UnitsUtils.getConversionFactor();
    this.controls.maxDistance = 200 * UnitsUtils.getConversionFactor();
    this.controls.target.copy(this.scene.position);

    this.raycaster = new THREE.Raycaster();
    this.raycaster.params.Line.threshold = UnitsUtils.getSelectionPrecision();
    this.raycaster.params.Points.threshold = UnitsUtils.getSelectionPrecision();

    // --------------------------

    this.scene.add(new THREE.AmbientLight(SCENE_AMBIENT_LIGHT_COLOR));

    this.intersectionsPlane = SceneUtils.createIntersectionsPlane();
    this.scene.add(this.intersectionsPlane);

    this.soRoot = new THREE.Group();
    this.soRoot.name = "Room Manager Root";
    this.scene.add(this.soRoot);

    this.soGridAndRulersRoot = new THREE.Group();
    this.soGridAndRulersRoot.name = "Room Manager grid and rulers root";
    this.soRoot.add(this.soGridAndRulersRoot);

    this.soFloorsRoot = new soFloor2DRoot("scene_manager_floors_root"); // TODO: add unique id
    this.soRoot.add(this.soFloorsRoot);

    // --------------------------

    this.commandManager = new SoRoomCommandManager(this);
    this.gridAndRulersManager = new GridAndRulersManager(this);
    this.backgroundManager = new BackgroundManager(this);
    this.lotLineManager = new LotLineManager(this);
    this.roomOpeningManager = new RoomOpeningManager(this);
    this.roomWallManager = new RoomWallManager(this);

    this.roomDragAndDropTool = new RoomDragAndDropTool(this);
    this.roomSnapTool = new RoomSnapTool(this);
    this.windowSelectionTool = new WindowSelectionTool(this);
    this.validationTool = new ValidationTool(this);
    this.tmlTool = new TotalMaterialListTool(this);
    this.openingTool = new OpeningAlignmentTool(this);
    this.measureTool = new MeasureTool(this);
    this.shortWallSegmentsTool = new ShortWallSegmentsTool(this);

    this.floorDimensionTool = new RoomDimensionTool(this, UnitsUtils.getFloorDimensionLinesOffset(), false);
    this.floorDimensionTool.addTo(this.soRoot);

    this.selectedRoomsDimensionTool = new RoomDimensionTool(this, UnitsUtils.getRoomDimensionLinesOffset(), true);
    this.selectedRoomsDimensionTool.addTo(this.soRoot);
    this.updateIntersections = this.updateIntersections.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
    this.onRoomTypeDrag = this.onRoomTypeDrag.bind(this);
    this.onRoomTypeDragEnd = this.onRoomTypeDragEnd.bind(this);

    reaction(() => appModel.activeCorePlan, this.onActiveCorePlanChanged.bind(this));
    this.onActiveCorePlanChanged(appModel.activeCorePlan);
  }

  public init(): void {
    this.controls.domElement = this.baseManager.renderer.domElement;
    this.controls.init();
    this.controls.addEventListener("change", this.onMouseWheel);
    window.addEventListener("resize", this.windowResize.bind(this));

    this.floorDimensionTool?.toggleInputs(true);
    this.selectedRoomsDimensionTool?.toggleInputs(true);

    this.debouncedSetPosition = this.debounce(() => {
      RoomEditToolPosition.setPosition(true);
    }, 1000);

    this.gridAndRulersManager.update();
  }

  private windowResize(): void {
    this.floorDimensionTool.updateSize(true);
    this.selectedRoomsDimensionTool.updateSize();
  }

  private debounce(func: (...args: any[]) => void, delay: number): () => void {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  }

  public setSize(width: number, height: number): void {
    this.camera.aspect = MathUtils.areNumbersEqual(height, 0.0) ? 1.0 : width / height;
    this.camera.updateProjectionMatrix();

    this.controls.handleResize();
    this.controls.update();

    this.gridAndRulersManager.update();
  }
  public setActive(val: boolean): void {
    this.controls.enabled = val;
  }
  public render(): void {
    this.controls?.update();
    this.baseManager?.renderer?.render(this.scene, this.camera);
  }
  public uninit(): void {
    this.gridAndRulersManager.reset();
    this.floorDimensionTool?.toggleInputs(false);
    this.selectedRoomsDimensionTool?.toggleInputs(false);
    this.roomWallManager?.clearSelection();
    this.controls.removeEventListener("change", this.onMouseWheel);
    window.removeEventListener("resize", this.windowResize);

    this.controls.dispose();
    this.controls.domElement = document;
  }

  public onMouseDown(e: MouseEvent): void {
    switch (appModel.sceneEditorMode) {
      case SceneEditorMode.Background:
        if (appModel.isViewOnlyMode) {
          return;
        }
        this.backgroundManager.onMouseDown(e);
        break;
      case SceneEditorMode.LotLine:
        if (appModel.isViewOnlyMode) {
          return;
        }
        this.lotLineManager.onMouseDown(e);
        break;
      case SceneEditorMode.Room:
        appModel.setTooltipOptions({ show: false });

        if (this.dragMode !== DragMode.none) {
          break;
        }

        this.soIntersectedStretchControl = this.getIntersectedStretchControl();
        if (this.soIntersectedStretchControl) {
          if (appModel.editToolOptions.show) {
            RoomEditToolPosition.setPosition(false);
            this.mouseIsDown = true;
          }

          this.dragMode = DragMode.stretchingRoom;
          this.controls.noPan = true;

          const soRoom = this.soIntersectedStretchControl.parent;

          this.previousStretch = SceneUtils.collectReferenceLines(soRoom);

          if (this.soIntersectedStretchControl.userData.type === RoomEntityType.ReferenceLine) {
            // #DCP-687: Temporarily disable user interactivity with the stretch plane dots
            // this.moveStartPosition = soRoom.position.clone();
            break;
          } else {
            this.moveStartPosition = GeometryUtils.getGeometryBoundingBox2D(this.soIntersectedStretchControl).getCenter(new THREE.Vector3());
            soRoom.userData.startPosition = soRoom.position.clone();
          }

          this.shortWallSegmentsTool.clearShortSegmentsValidationResult();
          this.unhighlightRoomBlockedDoors([soRoom as soRoom2D]);
          this.validationTool.isActive && this.validationTool.removeValidationVisualization();
        } else if (this.isBasePointIntersected()) {
          this.dragMode = DragMode.movingBasePoint;
          this.controls.noPan = true;
        } else if (e.button === 0) {
          this.windowSelectionTool.start(e);
        }
        break;
    }
  }

  private regenerateRoomsWalls = this.debounce(e => {
    GraphAnalysisUtils.regenerateRoomsWalls(this, this.getActiveSoFloor());
  }, 100);

  public onMouseMove = _.throttle((e: MouseEvent): void => {
    if (appModel.sceneEditorMode === SceneEditorMode.Room && this.dragMode === DragMode.addingRoom) {
      return;
    }

    this.isPanning = this.baseManager.isMouseDown && (this.controls !== null ? !this.controls.noPan : false);
    this.updateIntersections();

    switch (appModel.sceneEditorMode) {
      case SceneEditorMode.Background:
        if (appModel.isViewOnlyMode) {
          return;
        }
        this.backgroundManager.onMouseMove(e);
        break;
      case SceneEditorMode.LotLine:
        if (appModel.isViewOnlyMode) {
          return;
        }
        this.lotLineManager.onMouseMove(e);
        break;
      case SceneEditorMode.RoomOpening:
        this.roomOpeningManager.onMouseMove(e);
        break;
      case SceneEditorMode.RoomWallSelection:
        this.roomWallManager.onMouseMove(e);
        break;
      case SceneEditorMode.Measurement:
        this.measureTool.onMouseMove(e);
        break;
      case SceneEditorMode.Room: {
        const activeSoFloor = this.getActiveSoFloor();

        if (this.roomTooltipTimer) {
          clearTimeout(this.roomTooltipTimer);
        }
        if (this.isPanning) {
          this.gridAndRulersManager.update();
        }

        if (this.dragMode === DragMode.none && !this.baseManager.isMouseDown && !this.baseManager.isMouseHandlersEnabled) {
          this.roomWallManager.onMouseMove(e);

          this.showRoomTooltip(e);

          const soControl = this.getIntersectedStretchControl();
          if (this.soIntersectedStretchControl !== soControl) {
            SceneUtils.updateStretchControlHighlight(this.soIntersectedStretchControl, false);
            SceneUtils.updateStretchControlHighlight(soControl, true);
            this.soIntersectedStretchControl = soControl;
          }
          break;
        }

        if (!this.baseManager.isMouseHandlersEnabled) {
          break;
        }

        if (this.dragMode === DragMode.none && this.baseManager.isMouseDown && e.buttons === 1) {
          // LKM
          if (!appModel.isViewOnlyMode) {
            const intersectedSoRoom = this.getIntersectedRoom();
            const selectedSoRooms = this.getCorePlanSelectedSoRooms();
            if (selectedSoRooms.includes(intersectedSoRoom)) {
              this.showRoomTooltip(e);
              this.dragMode = DragMode.movingRooms;
              this.controls.noPan = true;
              this.moveStartPosition = selectedSoRooms[0].position.clone();
              this.soDraggedRoom = intersectedSoRoom;

              const mousePosition = this.intersectionPoint.clone();
              selectedSoRooms.forEach(soRoom => {
                soRoom.userData.moveOffset = mousePosition.clone().sub(soRoom.position);
                soRoom.userData.startPosition = soRoom.position.clone();
              });

              this.shortWallSegmentsTool.clearShortSegmentsValidationResult();
              this.unhighlightRoomBlockedDoors(selectedSoRooms);
              this.validationTool.isActive && this.validationTool.removeValidationVisualization();
            }
          }
          if (this.dragMode === DragMode.none) {
            if (!e.ctrlKey) {
              this.selectAllRooms(false);
            }
            this.dragMode = DragMode.selecting;
            this.windowSelectionTool.setInitiallySelected(this.getCorePlanSelectedSoRooms().map(soRoom => soRoom.soId));
          }
        }

        if (this.dragMode === DragMode.movingRooms) {
          (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Pointer;
          appModel.setTooltipOptions({ show: false });
          RoomEditToolPosition.setPosition(false);

          const soRooms = this.getCorePlanSelectedSoRooms();
          this.updateDragPosition(soRooms, e.shiftKey);

          const satellites = soRooms.filter(soRoom => soRoom.uuid !== this.soDraggedRoom.uuid);
          this.roomSnapTool.performSnapping(this.soDraggedRoom, satellites);
          if (appModel.featureFlags["dynamicRoomBoundary"]) {
            if (this.roomSnapTool.snapPerformed) {
              this.handleSnapping([this.soDraggedRoom]);
            }
            this.cleanupSnapEvents();
          }
          this.getCorePlanSoRooms();
          this.updateRoomsProperties(soRooms.map(soRoom => soRoom.soId));
          this.floorDimensionTool.updateSize(true);
          this.selectedRoomsDimensionTool.updateSize();
          this.selectedRoomsDimensionTool.setDimensionsDisabled(true);

          this.regenerateRoomsWalls();
          this.updateSegmentsCache();
          this.updateRoofContour();
          this.updateCladding();
          activeSoFloor.checkRoomsOverlapping(false);
        } else if (this.dragMode === DragMode.stretchingRoom) {
          if (appModel.editToolOptions.show) {
            RoomEditToolPosition.setPosition(false);
            this.debouncedSetPosition();
          }

          if (this.soIntersectedStretchControl.userData.type === RoomEntityType.ReferenceLine) {
            // #DCP-687: Temporarily disable user interactivity with the stretch plane dots
            // this.moveStretchPoints();
            break;
          } else {
            this.moveStretchTriangles();
            this.moveStartPosition = GeometryUtils.getGeometryBoundingBox2D(this.soIntersectedStretchControl).getCenter(new THREE.Vector3());
          }

          this.updateRoomsProperties([this.soIntersectedStretchControl.parent.userData.id]);
          this.floorDimensionTool.updateSize(true);
          this.selectedRoomsDimensionTool.updateSize();
          this.selectedRoomsDimensionTool.setDimensionsDisabled(true);
          GraphAnalysisUtils.regenerateRoomsWalls(this, activeSoFloor);
          this.updateSegmentsCache();
          this.updateRoofContour();
          this.updateCladding();
          activeSoFloor.checkRoomsOverlapping(false);
        } else if (this.dragMode === DragMode.movingBasePoint) {
          this.moveBasePoint();
        } else if (this.dragMode === DragMode.selecting) {
          this.windowSelectionTool.update(e, this.getActiveFloorSoRooms());
        }

        break;
      }
    }
  }, 16);

  public onMouseLeave(e: MouseEvent): void {
    if (this.dragMode === DragMode.addingRoom) {
      return;
    }

    switch (appModel.sceneEditorMode) {
      case SceneEditorMode.Background: {
        if (appModel.isViewOnlyMode) {
          return;
        }
        this.backgroundManager.onMouseLeave(e);
        break;
      }
      case SceneEditorMode.LotLine: {
        if (appModel.isViewOnlyMode) {
          return;
        }
        this.lotLineManager.onMouseLeave(e);
        break;
      }
      case SceneEditorMode.RoomWallSelection:
        this.roomWallManager.onMouseLeave(e);
        break;
      case SceneEditorMode.RoomOpening:
        this.roomOpeningManager.onMouseLeave(e);
        break;
      case SceneEditorMode.Measurement:
        this.measureTool.onMouseLeave(e);
        break;
      case SceneEditorMode.Room: {
        if (this.roomTooltipTimer) {
          clearTimeout(this.roomTooltipTimer);
        }
        if (this.baseManager.isMouseHandlersEnabled) {
          this.handleDragFinish();
          if (this.mouseIsDown) {
            this.mouseIsDown = false;
            this.debouncedSetPosition();
          }
          SceneUtils.updateStretchControlHighlight(this.soIntersectedStretchControl, false);
          this.soIntersectedStretchControl = null;
          this.windowSelectionTool.end();
          this.roomSnapTool.end();
        }
      }
    }
  }

  public setRoomWallSelected(timeout: number = 0): void {
    setTimeout(() => {
      appModel.selectedRoomWall.forEach(wall => {
        this.roomWallManager.paintSelectedWall(wall, true);
      });
    }, timeout); // For async selected wall color change
  }

  public clearRoomWallSelected(): void {
    this.roomWallManager?.init();
  }

  public onMouseUp(e: MouseEvent): void {
    if (appModel.sceneEditorMode === SceneEditorMode.Room && this.dragMode === DragMode.addingRoom) {
      return;
    }

    appModel.setContextMenuOptions({ show: false });

    switch (appModel.sceneEditorMode) {
      case SceneEditorMode.Background:
        if (appModel.isViewOnlyMode) {
          return;
        }
        this.backgroundManager.onMouseUp(e);
        break;
      case SceneEditorMode.LotLine:
        if (appModel.isViewOnlyMode) {
          return;
        }
        this.lotLineManager.onMouseUp(e);
        break;
      case SceneEditorMode.RoomOpening:
        this.roomOpeningManager.onMouseUp(e);
        break;
      case SceneEditorMode.RoomWallSelection:
        this.roomWallManager.onMouseUp(e);
        appModel.setTooltipOptions({ show: false });

        break;
      case SceneEditorMode.Measurement:
        this.measureTool.onMouseUp(e);
        break;
      case SceneEditorMode.Room: {
        e.preventDefault();
        if (this.mouseIsDown) {
          this.mouseIsDown = false;
          this.debouncedSetPosition();
        }

        this.windowSelectionTool.end();
        this.roomSnapTool.end();
        const target = e.target as HTMLElement;
        if (!target.closest(".tabs-holder")) {
          // Check if the click was on a left panel
          this.roomWallManager.init();
        }
        if (this.baseManager.isMouseHandlersEnabled) {
          if (this.dragMode === DragMode.none) {
            if (!this.isPanning) {
              const isSingleMode = !e.ctrlKey;

              if (e.button === 0) {
                let intersectedOpening: THREE.Object3D = null; //TODO: change to soOpening2D
                const intersectedWall = this.roomWallManager.getIntersectedWall();

                if (appModel.selectedRoomsIds.length === 0) {
                  intersectedOpening = this.roomOpeningManager.getIntersectedOpening();
                }

                if (intersectedOpening) {
                  appModel.setSceneEditorMode(SceneEditorMode.RoomOpening);
                  this.roomOpeningManager.addSoOpeningToSelected(intersectedOpening);
                } else if (intersectedWall) {
                  appModel.setSceneEditorMode(SceneEditorMode.RoomWallSelection);
                  this.roomWallManager.addSoWallToSelected(intersectedWall);
                  this.selectAllRooms(false);
                  return;
                } else {
                  this.performRoomSelection(isSingleMode);
                }
              } else if (e.button === 2) {
                this.performRoomSelection(isSingleMode, true);

                const options: IContextMenuOptions = { show: true, left: e.clientX, top: e.clientY };
                if (this.soCopiedRooms.length) {
                  options.pasteRooms = this.pasteCopiedRoomsInMousePosition.bind(this);
                  appModel.setContextMenuOptions(options);
                }
                if (appModel.selectedRoomsIds.length) {
                  options.copyRooms = this.copySelectedRooms.bind(this);
                  options.deleteRooms = this.deleteSelectedRooms.bind(this);
                  appModel.setContextMenuOptions(options);
                }
              }
            }
          } else {
            this.handleDragFinish();
          }
        }

        this.dragMode = DragMode.none;
        this.controls.noPan = false;
        this.showRoomTooltip(e);
        (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Default;

        break;
      }
    }

    this.isPanning = false;
  }
  public onMouseWheel(): void {
    this.gridAndRulersManager.update();

    this.selectedRoomsDimensionTool.updateSize();
    this.floorDimensionTool.updateSize(true);
    if (appModel.editToolOptions.show) {
      RoomEditToolPosition.setPosition(false);

      this.debouncedSetPosition();
    }
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (e.ctrlKey && e.code === Keys.A) {
      e.preventDefault();
    }
  }
  public onKeyUp(e: KeyboardEvent): void {
    switch (appModel.sceneEditorMode) {
      case SceneEditorMode.Background:
        this.backgroundManager.onKeyUp(e);
        break;
      case SceneEditorMode.LotLine:
        this.lotLineManager.onKeyUp(e);
        break;
      case SceneEditorMode.RoomOpening:
        this.roomOpeningManager.onKeyUp(e);
        break;
      case SceneEditorMode.RoomWallSelection:
        this.roomWallManager.onKeyUp(e);
        break;
      case SceneEditorMode.Measurement:
        this.measureTool.onKeyUp(e);
        break;
      case SceneEditorMode.Room: {
        if (e.code === Keys.Delete && !appModel.isViewOnlyMode) {
          this.deleteSelectedRooms();
          return;
        }

        if (!e.ctrlKey && !e.metaKey) {
          return;
        }

        if (appModel.isViewOnlyMode && ![Keys.A].includes(e.code as Keys)) {
          return;
        }

        switch (e.code) {
          case Keys.A: {
            this.selectAllRooms(true);
            break;
          }
          case Keys.C: {
            this.copySelectedRooms();
            break;
          }
          case Keys.V: {
            if (!this.soCopiedRooms.length) {
              break;
            }
            const pastedRooms = this.pasteRooms();
            this.commandManager.add(new MultiCommand(pastedRooms.map(room => new PasteRoomCommand(room.pastedRoomId, room.copiedRoomId, room.position))));
            break;
          }
          case Keys.Y: {
            this.redo();
            break;
          }
          case Keys.Z: {
            this.undo();
            break;
          }
        }
        break;
      }
    }
  }
  public onRoomTypeDragStart(e: React.MouseEvent, roomType: RoomType): void {
    this.updateIntersections();

    if (roomType && appModel.activeFloor && this.mouseDragEndPosition === undefined) {
      this.dragMode = DragMode.addingRoom;
      this.selectAllRooms(false);
      this.shortWallSegmentsTool.clearShortSegmentsValidationResult();

      this.draggedRoom = new Room(roomType.id, roomType.name);
      this.loadRoom(this.draggedRoom)
        .then(soRoom => {
          if (this.mouseDragEndPosition === null) {
            // Mouse button was already released, mouse out of the canvas. Do not add room.
            this.mouseDragEndPosition = undefined;
            return;
          } else {
            this.soDraggedRoom = soRoom;
            const activeSoFloor = this.getActiveSoFloor();
            // Add room on mouse up position if button was already released or place off scene.
            soRoom.position.copy(this.mouseDragEndPosition || new THREE.Vector3(-100000, -100000, 0));
            const center = GeometryUtils.getGeometryBoundingBox2D(soRoom).getCenter(new THREE.Vector3());
            soRoom.userData.moveOffset = center.clone().sub(soRoom.position);
            //activeSoFloor.add(this.soDraggedRoom); // TODO - remove
            activeSoFloor.addRoom(this.soDraggedRoom);
            if (this.mouseDragEndPosition) {
              activeSoFloor.checkRoomsOverlapping(true);
              this.finishObjectInsertion();
              this.corePlan.setIsCostOutdated(true);
              this.mouseDragEndPosition = undefined;
              this.soDraggedRoom = null;
              this.draggedRoom = null;
              this.dragMode = DragMode.none;
            }
          }
        })
        .catch(e => {
          log.error(e);
          console.log("Error adding room", e);
          this.dragMode = DragMode.none;
        });
    }
  }
  public onRoomTypeDrag(): void {
    this.updateIntersections();

    if (this.dragMode !== DragMode.addingRoom || !this.soDraggedRoom) {
      return;
    }

    if (this.soDraggedRoom.visible !== this.baseManager.isMouseWithinScene) {
      this.soDraggedRoom.visible = this.baseManager.isMouseWithinScene;
    }

    if (!this.baseManager.isMouseWithinScene) {
      return;
    }

    this.updateDragPosition([this.soDraggedRoom]);
    this.roomSnapTool.performSnapping(this.soDraggedRoom);
    if (appModel.featureFlags["dynamicRoomBoundary"]) {
      if (this.roomSnapTool.snapPerformed) {
        this.handleSnapping([this.soDraggedRoom]);
      }
      this.cleanupSnapEvents();
    }

    this.getActiveSoFloor().checkRoomsOverlapping(false);
  }
  public onRoomTypeDragEnd(e: MouseEvent): void {
    if (this.dragMode === DragMode.addingRoom && this.mouseDragEndPosition === undefined) {
      if (!this.soDraggedRoom) {
        this.mouseDragEndPosition = this.baseManager.isMouseWithinScene ? this.intersectionPoint.clone() : null;
        return;
      } else if (this.soDraggedRoom && !this.baseManager.isMouseWithinScene) {
        this.getActiveSoFloor().removeRoom(this.soDraggedRoom);
      } else {
        this.finishObjectInsertion();
        this.showRoomTooltip(e);
      }
      this.roomSnapTool.end();

      this.draggedRoom = null;
      this.soDraggedRoom = null;
      this.dragMode = DragMode.none;
    }
  }

  public getRaycasterRecommendedPrecision(): number {
    return this.raycasterLinePrecisionUnitFactor * this.getDistanceFactor() * this.raycasterLinePrecisionDistanceFactor;
  }
  public getDistanceFactor(): number {
    const currentDistanceCameraToOrigin = this.camera.position.distanceTo(this.controls?.target || this.scene.position);
    return currentDistanceCameraToOrigin / (this.originalDistanceCameraToOrigin || 1.0);
  }

  public getSoRoot(): THREE.Group {
    return this.soRoot;
  }
  public getSoBasePoint(): THREE.Object3D {
    // TODO: Change THREE.Object3D to soBasePoint
    return this.soRoot.children.find(x => x.userData.type === SceneEntityType.BasePoint);
  }
  public getSoFloorsRoot(): soFloor2DRoot {
    return this.soFloorsRoot;
  }
  public getSoFloor(floorId?: string): soFloor2D | undefined {
    return this.soFloorsRoot.soFloors.find(x => x.soId === floorId);
    // return this.soFloorsRoot.soFloors.find(x => x.soId === floorId || x.userData.id === floorId);
  }
  public getActiveSoFloor(): soFloor2D {
    return this.getSoFloor(appModel.activeFloor?.id);
  }
  public getActiveFloorSoRoom(roomId: string): soRoom2D {
    return this.getActiveSoFloor().soRooms.find(r => r.soId === roomId || r.userData.id === roomId || r.userData.originalRoomId === roomId);
  }

  public getActiveFloorSoRooms(): soRoom2D[] {
    return this.getActiveSoFloor()?.soRooms ?? [];
  }
  public getCorePlanSoRooms(): soRoom2D[] {
    return this.soFloorsRoot.soFloors.flatMap(soFloor => soFloor.soRooms);
  }
  public getCorePlanSoRoom(roomId: string): soRoom2D {
    return this.getCorePlanSoRooms().find(r => r.soId === roomId || r.userData.id === roomId || r.userData.originalRoomId === roomId);
  }
  public getCorePlanSelectedSoRooms(): soRoom2D[] {
    return this.getCorePlanSoRooms().filter(soRoom => appModel.selectedRoomsIds.includes(soRoom.soId));
  }

  public handleDraggedRoomReplaceability(draggedSoRoom: soRoom2D): void {
    const screenPosition = GeometryUtils.positionToScreenPosition(draggedSoRoom.position, this.camera, this.baseManager.getParentContainerRectangle());
    const replaceableRoom = this.findReplaceableRoom(draggedSoRoom);

    if (replaceableRoom) {
      // Display replace message
      this.soReplaceableRooms = { newRoom: draggedSoRoom, oldRoom: replaceableRoom };
      const options: IContextMenuOptions = { show: true, left: screenPosition.x, top: screenPosition.y };
      options.replaceRooms = this.replaceReplaceableRoom.bind(this);
      appModel.setContextMenuOptions(options);

      const disposer = reaction(
        () => appModel.contextMenuOptions,
        () => {
          if (this.getActiveSoFloor().soRooms.includes(replaceableRoom)) {
            this.commandManager.add(new SoAddRoomCommand(draggedSoRoom));
          }
          disposer();
        }
      );
    } else {
      this.soReplaceableRooms = null;
      this.commandManager.add(new SoAddRoomCommand(draggedSoRoom));
    }
  }

  public findReplaceableRoom(soRoom: soRoom2D): soRoom2D | null {
    const intersected = this.getActiveSoFloor()?.soRooms.filter(
      floorRoom =>
        floorRoom.soId !== soRoom.soId && !appModel.selectedRoomsIds.includes(floorRoom.soId) && SceneUtils.areSoRoomsOverlapping(soRoom, floorRoom as soRoom2D)
    );
    const mainOverlapped = SceneUtils.findClosestSoRoomFromRoomList(soRoom, intersected as soRoom2D[]);

    if (mainOverlapped && SceneUtils.isRoomReplaceable(soRoom, mainOverlapped)) {
      return mainOverlapped;
    }

    return null;
  }

  public replaceRoom(oldRoom: soRoom2D, newRoom: soRoom2D): void {
    //get room sizes

    const oldSize = oldRoom.getSoRoomBoundingBoxByModelLines().getSize(new THREE.Vector3());
    const newSize = newRoom.getSoRoomBoundingBoxByModelLines().getSize(new THREE.Vector3());

    const commands: RoomCommand[] = [];

    //delete old room
    commands.push(new SoDeleteRoomCommand(oldRoom));

    //adjust new room position
    commands.push(new TranslateRoomCommand(newRoom.soId, newRoom.position.clone().negate().add(oldRoom.position)));

    //adjust new room size
    if (!MathUtils.areNumbersEqual(oldSize.x, newSize.x)) {
      const diff = oldSize.x - newSize.x;
      const referenceLines = SceneUtils.collectStretchedReferenceLines(newRoom, diff, Direction.Horizontal);
      if (referenceLines.some(ref => ref.stretchedDistance !== 0)) {
        commands.push(...referenceLines.map(referenceLine => new StretchRoomCommand(newRoom.soId, referenceLine, referenceLine.stretchedDistance)));
      }
    }

    if (!MathUtils.areNumbersEqual(oldSize.y, newSize.y)) {
      const diff = oldSize.y - newSize.y;
      const referenceLines = SceneUtils.collectStretchedReferenceLines(newRoom, diff, Direction.Vertical);
      if (referenceLines.some(ref => ref.stretchedDistance !== 0)) {
        commands.push(...referenceLines.map(referenceLine => new StretchRoomCommand(newRoom.soId, referenceLine, referenceLine.stretchedDistance)));
      }
    }

    commands.forEach(cmd => cmd.apply(this));
    commands.splice(0, 0, new SoAddRoomCommand(newRoom));
    this.commandManager.add(new MultiCommand(commands));
    this.commandManager.validate();
  }

  public async replaceObsoleteRooms(): Promise<void> {
    appModel.setIsBusy(true);
    this.selectAllRooms(false);
    this.soCopiedRooms.length = 0;
    this.copiedRooms.length = 0;

    let hasErrors = false;
    const commands: RoomCommand[] = [];
    const obsoleteRooms = appModel.activeFloor.rooms.filter(room => room.isObsolete);

    const roomTypesMap = new Map<Room, RoomType>();
    const unreadyRoomTypes = new Set<RoomType>();

    obsoleteRooms.forEach(obsoleteRoom => {
      const obsoleteRoomType = appModel.getRoomType(obsoleteRoom.roomTypeId);
      const roomCategory = appModel.getRoomCategory(obsoleteRoomType.roomCategoryId);
      const roomType = roomCategory.roomTypes.find(rt => rt.name === obsoleteRoomType.name && !rt.isMarkHidden);

      if (!roomType) {
        return;
      }

      roomTypesMap.set(obsoleteRoom, roomType);

      if (roomType.assetsState !== AssetsState.LOADED) {
        unreadyRoomTypes.add(roomType);
      }
    });

    const replaceObsoleteRoom = async (obsoleteRoom: Room) => {
      try {
        const roomType = roomTypesMap.get(obsoleteRoom);

        if (!roomType) {
          throw new Error(`Obsolete roomType: ${obsoleteRoom.name} has no new roomType with the same name`);
        }

        const newRoom = new Room(roomType.id, roomType.name);
        // Copy same material
        newRoom.colorIndex = obsoleteRoom.colorIndex;
        newRoom.customColorIndex = obsoleteRoom.customColorIndex;

        // Copy the same ID from the previous room for backwards compatibility with variations.
        newRoom.id = obsoleteRoom.id;

        const newSoRoom = await this.loadRoom(newRoom);
        const obsoleteSoRoom = this.getActiveFloorSoRoom(obsoleteRoom.id);
        newSoRoom.uuid = obsoleteSoRoom.uuid;

        newSoRoom.position.copy(obsoleteSoRoom.position);
        newSoRoom.rotation.copy(obsoleteSoRoom.rotation);
        newSoRoom.scale.copy(obsoleteSoRoom.scale);
        newSoRoom.updateMatrixWorld();

        // Update the default slopes and finishes
        newRoom.roofSlopes = obsoleteRoom.roofSlopes.slice(0, 4);
        newRoom.dutchGableDepths = obsoleteRoom.dutchGableDepths.slice(0, 4);
        newRoom.exteriorFinishes = obsoleteRoom.exteriorFinishes.slice(0, 4);
        newRoom.gableExteriorFinishes = obsoleteRoom.gableExteriorFinishes.slice(0, 4);

        if (!SceneUtils.isRoomReplaceable(newSoRoom, obsoleteSoRoom)) {
          throw new Error(`New room does not match the size of obsolete room. RoomType: ${roomType.name}`);
        }
        // Get room sizes
        const oldSize = obsoleteSoRoom.getSoRoomBoundingBoxByModelLines().getSize(new THREE.Vector3());
        const newSize = newSoRoom.getSoRoomBoundingBoxByModelLines().getSize(new THREE.Vector3());

        // Adjust new room size
        if (!MathUtils.areNumbersEqual(oldSize.x, newSize.x)) {
          const diff = oldSize.x - newSize.x;
          SceneUtils.stretchRoom(newSoRoom, diff, Direction.Horizontal);
        }

        if (!MathUtils.areNumbersEqual(oldSize.y, newSize.y)) {
          const diff = oldSize.y - newSize.y;
          SceneUtils.stretchRoom(newSoRoom, diff, Direction.Vertical);
        }
        // to keep the same position of the openings we match the openings by their position and type
        obsoleteRoom.openings.forEach((opening: RoomOpeningData) => {
          if (!MathUtils.areNumbersEqual(opening.shiftDistance, 0)) {
            const soOpeningOld = obsoleteSoRoom.children.find(child => child.userData.id === opening.id);
            const soOpeningNew = newSoRoom.children.find(
              child =>
                [RoomEntityType.Window, RoomEntityType.Door].includes(child.userData.type) &&
                soOpeningOld.userData.wallPointDistances.toString() === child.userData.wallPointDistances.toString()
            );

            if (soOpeningNew) {
              SceneUtils.moveOpening(soOpeningNew, opening.shiftDistance);
            }
          }
        });

        commands.push(new SoDeleteRoomCommand(obsoleteSoRoom));
        commands.push(new SoAddRoomCommand(newSoRoom, newRoom));
      } catch (e) {
        log.error(e);
        hasErrors = true;
      }
    };

    await appModel.batchLoadRoomTypeAssets(Array.from(unreadyRoomTypes));
    await Promise.all(obsoleteRooms.map(replaceObsoleteRoom));

    if (hasErrors) {
      showToastMessage(MessageKindsEnum.Error, OBSOLETE_ROOMS_REPLACEMENT_ERROR_MESSAGE, { autoClose: MESSAGE_DURATION });
    }

    if (commands.length) {
      this.commandManager.apply(new MultiCommand(commands));
    }
    appModel.setIsBusy(false);
  }

  public checkRoomsOverlapping(showErrorMessage = true, floor?: soFloor2D): void {
    const soFloor = floor || this.getActiveSoFloor();
    soFloor.checkRoomsOverlapping(showErrorMessage);
  }

  public checkIntersectedWindows(): void {
    const soRooms = this.getActiveFloorSoRooms();
    soRooms.forEach(soRoom => {
      delete soRoom.userData.isBlockingWindow;
      soRoom.children
        .filter(child => child.userData.type === RoomEntityType.Window && !soRoom.userData.isIntersected)
        .forEach(window => {
          SceneUtils.unhighlightBlockedOpening(window);
        });
    });

    const windowsMap = new Map<string, THREE.Object3D[]>(); //TODO: THREE.Object3D needs to be changed to soWindow2D

    // Collect windows for each room
    soRooms.forEach(soRoom => {
      const roomWindows = soRoom.children.filter(child => child.userData.type === RoomEntityType.Window);
      windowsMap.set(soRoom.soId, roomWindows);
    });

    const intersectedWindows: { soWindow: THREE.Object3D; intersectingWindowId: string }[] = []; //TODO: THREE.Object3D needs to be changed to soWindow2D

    // Check for intersections between windows within each room
    windowsMap.forEach(windows => {
      windows.forEach((soWindow, index) => {
        const windowBbox = GeometryUtils.getGeometryBoundingBox3D(soWindow);

        for (let i = index + 1; i < windows.length; i++) {
          const otherWindow = windows[i];
          const otherWindowBbox = GeometryUtils.getGeometryBoundingBox3D(otherWindow);

          // Check for intersection between the two windows
          if (windowBbox.intersectsBox(otherWindowBbox)) {
            intersectedWindows.push({ soWindow, intersectingWindowId: otherWindow.userData.id });
            intersectedWindows.push({ soWindow: otherWindow, intersectingWindowId: soWindow.userData.id });
          }
        }
      });
    });

    // Highlight intersected windows and rooms
    if (intersectedWindows.length) {
      showToastMessage("Error", "Some windows are intersecting with each other in the same room");
    }

    intersectedWindows.forEach(iw => {
      const soRoom = this.getCorePlanSoRoom(iw.soWindow.parent.userData.id);
      soRoom.userData.isBlockingWindow = { roomId: soRoom.soId, windowId: iw.soWindow.userData.id };
      SceneUtils.highlightBlockedOpening(iw.soWindow, iw.intersectingWindowId);
    });
  }

  public checkBlockedDoors(soRooms?: soRoom2D[]): void {
    const soActiveFloorRooms = soRooms || this.getActiveFloorSoRooms();

    soActiveFloorRooms.forEach(soRoom => {
      if (soRoom.userData.isBlockingDoor && !soRoom.userData.isIntersected) {
        SceneUtils.unhighlightIntersectedRoom(soRoom, true);
      }
      delete soRoom.userData.isBlockingDoor;
      soRoom.children
        .filter(child => child.userData.type === RoomEntityType.Door && child.userData.isBlockedBy)
        .forEach(door => {
          delete door.userData.isBlockedBy;
          if (!soRoom.userData.isIntersected) {
            SceneUtils.unhighlightBlockedOpening(door);
          }
        });
    });
    const roomsBboxes: { roomId: string; bbox: THREE.Box3 }[] = [];
    const roomsMap = new Map<string, soRoom2D>();
    const roomWallLinesMap = new Map<string, { wallId: string; revitId: string; line: THREE.Line3; isHorizontal: boolean }[]>();
    const roomPlumbingWalls = new Map<string, { line: THREE.Line3; isHorizontal: boolean }[]>();
    const blockedDoors: { soDoor: THREE.Object3D; blockingRoomId: string }[] = []; // TODO: THREE.Object3D needs to be changed to soDoor2D

    soActiveFloorRooms.forEach(soRoom => {
      const bbox = soRoom.getSoRoomBoundingBoxByModelLines();

      roomsBboxes.push({ roomId: soRoom.soId, bbox });
      roomsMap.set(soRoom.soId, soRoom);
      const roomWalls = soRoom.children
        .filter(child => child.userData.type === RoomEntityType.Wall || child.userData.type === SceneEntityType.SyntheticWall)
        .map(rw => {
          const wallLine = GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox3D(rw));
          return {
            wallId: rw.userData.id,
            revitId: rw.userData.revitId,
            line: wallLine,
            isHorizontal: GeometryUtils.isLineHorizontal(wallLine),
          };
        });
      roomWallLinesMap.set(soRoom.soId, roomWalls);

      const plumbingRoomWalls = soRoom.children
        .filter(child => child.userData.type === RoomEntityType.PlumbingWall)
        .map(rw => {
          const wallLine = GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox3D(rw));
          return {
            wallId: rw.userData.id,
            revitId: rw.userData.revitId,
            line: wallLine,
            isHorizontal: GeometryUtils.isLineHorizontal(wallLine),
          };
        });
      if (plumbingRoomWalls.length) {
        roomPlumbingWalls.set(soRoom.soId, plumbingRoomWalls);
      }
    });

    const wallWidth = UnitsUtils.getSyntheticWallHalfSize() * 2;
    soActiveFloorRooms.forEach(soRoom => {
      const soRoomBbox = roomsBboxes.find(rb => rb.roomId === soRoom.soId).bbox;
      soRoom.children
        .filter(child => child.userData.type === RoomEntityType.Door)
        .forEach(soDoor => {
          const doorBbox = GeometryUtils.getGeometryBoundingBox3D(soDoor);
          const doorWall = soRoom.children.find(child => child.userData.revitId === soDoor.userData.wallBindingRevitId);
          const doorWallLine = GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox3D(doorWall));
          const doorLine = SceneUtils.getOpeningAndWallIntersection(doorWallLine, doorBbox); // Is always aligned.
          const isDoorWallHorizontal = GeometryUtils.isLineHorizontal(doorLine);
          const doorAxis = isDoorWallHorizontal ? "x" : "y";
          const doorPerpendicularAxis = isDoorWallHorizontal ? "y" : "x";
          const isDoorBottomLeft = MathUtils.areNumbersEqual(doorLine.start[doorPerpendicularAxis], soRoomBbox.min[doorPerpendicularAxis]);

          let intersectedRoomBox: { roomId: string; bbox: THREE.Box3 } = null;
          for (const roomBox of roomsBboxes) {
            const isOverlapped =
              roomBox.roomId !== soRoom.soId && // Do not compare to the same room.
              (MathUtils.areNumbersEqual(doorLine.end[doorPerpendicularAxis], roomBox.bbox.max[doorPerpendicularAxis]) ||
                MathUtils.areNumbersEqual(doorLine.end[doorPerpendicularAxis], roomBox.bbox.min[doorPerpendicularAxis])) && // Check that the door is on one of the model lines.
              doorLine.start[doorAxis] < roomBox.bbox.max[doorAxis] &&
              roomBox.bbox.min[doorAxis] < doorLine.end[doorAxis]; // Check that lines overlap.

            if (!isOverlapped) {
              continue;
            }
            intersectedRoomBox = roomBox;
            const wallLineDoorPoint = isDoorBottomLeft ? "end" : "start";
            let wallBlockingPoint: THREE.Vector3;
            if (doorLine.start[doorAxis] < roomBox.bbox.min[doorAxis]) {
              wallBlockingPoint = roomBox.bbox.min;
            } else if (doorLine.end[doorAxis] > roomBox.bbox.max[doorAxis]) {
              wallBlockingPoint = roomBox.bbox.max;
            }

            if (wallBlockingPoint) {
              const isDoorBlockedByWall = [...roomWallLinesMap.values()].some(roomWalls =>
                roomWalls.some(
                  wall =>
                    isDoorWallHorizontal !== wall.isHorizontal &&
                    MathUtils.areNumbersEqual(wallBlockingPoint[doorAxis], wall.line.start[doorAxis]) &&
                    MathUtils.areNumbersEqual(
                      doorLine.start[doorPerpendicularAxis],
                      wall.line[wallLineDoorPoint][doorPerpendicularAxis],
                      wallWidth + EPSILON // Take into account wall clipping
                    )
                )
              );
              if (isDoorBlockedByWall) {
                blockedDoors.push({ soDoor, blockingRoomId: intersectedRoomBox.roomId });
                return;
              }
            }

            if (roomPlumbingWalls.has(intersectedRoomBox.roomId)) {
              const isDoorBlockedByWall = roomPlumbingWalls.get(intersectedRoomBox.roomId).some(
                plmWall =>
                  isDoorWallHorizontal === plmWall.isHorizontal &&
                  MathUtils.areNumbersEqual(plmWall.line.start[doorPerpendicularAxis], doorLine.start[doorPerpendicularAxis], wallWidth * 2 + EPSILON) && // Check that plm wall is close to the door
                  doorLine.start[doorAxis] < plmWall.line.end[doorAxis] &&
                  plmWall.line.start[doorAxis] < doorLine.end[doorAxis] // Check that lines overlap.
              );
              if (isDoorBlockedByWall) {
                blockedDoors.push({ soDoor, blockingRoomId: intersectedRoomBox.roomId });
                return;
              }
            }
          }

          if (!intersectedRoomBox) {
            return;
          }
          const intersectedWall = roomWallLinesMap
            .get(intersectedRoomBox.roomId)
            .find(
              r =>
                r.isHorizontal === isDoorWallHorizontal && MathUtils.areNumbersEqual(r.line.start[doorPerpendicularAxis], doorLine.start[doorPerpendicularAxis])
            );

          if (!intersectedWall?.revitId) {
            // Intersected synthetic wall that is guarantied to not have any furniture bind.
            return;
          }

          const wallFurnitureBoxes = roomsMap
            .get(intersectedRoomBox.roomId)
            .children.filter(child => child.userData.type === RoomEntityType.Furniture && child.userData.wallBindingRevitId === intersectedWall.revitId)
            .map(child => GeometryUtils.getGeometryBoundingBox3D(child));

          const isFurnitureBlocking = wallFurnitureBoxes.some(f => f.min[doorAxis] < doorLine.end[doorAxis] && f.max[doorAxis] > doorLine.start[doorAxis]);
          if (isFurnitureBlocking) {
            blockedDoors.push({ soDoor, blockingRoomId: intersectedRoomBox.roomId });
          }
        });
    });

    if (blockedDoors.length) {
      showToastMessage("Error", "Some elements are blocking door openings");
    }

    blockedDoors.forEach(bd => {
      const soRoom = this.getCorePlanSoRoom(bd.blockingRoomId);
      if (!soRoom) {
        return;
      }
      soRoom.userData.isBlockingDoor = { roomId: bd.soDoor.parent.userData.id, doorId: bd.soDoor.userData.id };
      if (!soRoom.userData.isIntersected) {
        SceneUtils.highlightIntersectedRoom(soRoom, false, true);
      }
      SceneUtils.highlightBlockedOpening(bd.soDoor, bd.blockingRoomId);
    });
  }
  public checkRoomsSharedObjects(roomIds: string[]): void {
    const soFloor = this.getActiveSoFloor();
    if (!soFloor) {
      return;
    }

    const soRooms = FloorUtils.getFloorSoRooms(soFloor).filter(soRoom => roomIds.includes(RoomUtils.getRoomId(soRoom)));
    const soOthers = FloorUtils.getFloorSoRooms(soFloor).filter(soRoom => !roomIds.includes(RoomUtils.getRoomId(soRoom)));

    for (const soRoom of soRooms) {
      for (const soOther of soOthers) {
        const result = SceneUtils.hasRoomsSharedObjects(soRoom, soOther);
        if (result && result.hasSharedObjects && !result.hasIntersectedSharedObjects) {
          showToastMessage(MessageKindsEnum.Warning, SNAP_WARNING_MESSAGE, { autoClose: MESSAGE_DURATION });
          return;
        }
      }
    }
  }
  public rotateSelectedRooms(isClockwise: boolean): void {
    const angle = isClockwise ? -Math.PI / 2 : Math.PI / 2;
    const soRooms = appModel.selectedRoomsIds.map(id => this.getActiveFloorSoRoom(id));

    const bb = new THREE.Box3();
    soRooms.forEach(soRoom => {
      bb.union(soRoom.getSoRoomBoundingBoxByModelLines());
    });

    const position = bb.getCenter(new THREE.Vector3());

    this.commandManager.apply(new MultiCommand(appModel.selectedRoomsIds.map(id => new RotateRoomCommand(id, angle, position))));
    // clean all segment offsets - still recognizing the segments of the roated room is not implemented so for now reset all
    appModel.segmentOffsetManager.clearSegmentOffsets();
  }

  public setWidthToSelectedRooms(functionCode: string): void {
    const soRooms = appModel.selectedRoomsIds.map(id => this.getActiveFloorSoRoom(id));

    const bb = new THREE.Box3();
    soRooms.forEach(soRoom => {
      bb.union(soRoom.getSoRoomBoundingBoxByModelLines());
    });

    this.commandManager.apply(new MultiCommand([...appModel.selectedRoomWall].map(wall => new ChangeWallWidthCommand(wall, functionCode))));
  }

  public runChangeWallWidthAlignmentCommand(oldOffsets) {
    this.commandManager.apply(new MultiCommand([...appModel.selectedRoomWall].map(wall => new ChangeWallAlignmentCommand(wall, oldOffsets))));
  }

  public rotateSelectedRoomsContent(isClockwise: boolean): void {
    const angle = isClockwise ? -Math.PI / 2 : Math.PI / 2;
    this.commandManager.apply(
      new MultiCommand(
        appModel.selectedRoomsIds.map(id => {
          const soRoom = this.getActiveFloorSoRoom(id);
          const validAngle = SceneUtils.calculateRotateRoomContentValidAngle(soRoom, angle);

          if (!MathUtils.areNumbersEqual(angle, validAngle)) {
            showToastMessage(MessageKindsEnum.Message, "90 degrees rotation is invalid (min/max dimensions requirements not met). Rotated 180 degrees.", {
              autoClose: MESSAGE_DURATION,
            });
          }

          return new RotateRoomContentCommand(soRoom.soId, validAngle);
        })
      )
    );
    // clean all segment offsets - still recognizing the segments of the roated room is not implemented so for now reset all
    appModel.segmentOffsetManager.clearSegmentOffsets();
  }
  public mirrorSelectedRooms(isHorizontally: boolean): void {
    const scale = isHorizontally ? new THREE.Vector3(-1, 1, 1) : new THREE.Vector3(1, -1, 1);
    const soRooms = appModel.selectedRoomsIds.map(id => this.getActiveFloorSoRoom(id));

    const bb = new THREE.Box3();

    soRooms.forEach(soRoom => bb.union(soRoom.getSoRoomBoundingBoxByModelLines()));

    const position = bb.getCenter(new THREE.Vector3());

    this.commandManager.apply(new MultiCommand(appModel.selectedRoomsIds.map(id => new MirrorRoomCommand(id, scale, position))));
  }

  public updateRoomsProperties(ids: string[]): void {
    const rooms = this.corePlan.getRooms(ids);

    runInAction(() => {
      for (const room of rooms) {
        const soRoom = this.getCorePlanSoRoom(room.id);

        this.populateRoomProperties(room, soRoom);
      }
    });
  }

  public selectRoom(soRoom: soRoom2D, isSelected: boolean): void {
    if (isSelected) {
      appModel.addSelectedRoomId(soRoom.soId);
    } else {
      appModel.deleteSelectedRoomsId(soRoom.soId);
    }
  }
  public selectAllRooms(isSelected: boolean): void {
    if (isSelected) {
      appModel.setSelectedRoomsIds(this.getActiveSoFloor().soRooms.map(soRoom => soRoom.soId));
    } else {
      appModel.clearSelectedRoomsIds();
    }
  }
  public updateFloorsVisibility(soFloor?: soFloor2D, floor?: Floor): void {
    const activeSoFloor = soFloor || this.getActiveSoFloor();
    const activeFloor = floor || appModel.activeFloor;
    if (!activeSoFloor) {
      return;
    }

    const contourFloorsIds = [];
    if (appModel.showAboveFloor) {
      const floorModel = this.corePlan.floors.find(f => f.index === activeFloor.index + 1);
      if (floorModel) {
        contourFloorsIds.push(floorModel.id);
      }
    }

    if (appModel.showBelowFloor) {
      const floorModel = this.corePlan.floors.find(f => f.index === activeFloor.index - 1);
      if (floorModel) {
        contourFloorsIds.push(floorModel.id);
      }
    }

    this.soFloorsRoot.soFloors.forEach(f => {
      if (f.soId === activeSoFloor.soId) {
        f.visible = true;
        SceneUtils.setItemVisibility(f, true);
        SceneUtils.setModelLinesColorForFloor(f, MODEL_LINE_COLOR);
        SceneUtils.displayFloorContour(this.soRoot, f, false);
      } else if (contourFloorsIds.includes(f.soId)) {
        f.visible = true;
        SceneUtils.setItemVisibility(f, false);
        SceneUtils.setModelLinesColorForFloor(f, INACTIVE_MODEL_LINE_COLOR);
        SceneUtils.displayFloorContour(this.soRoot, f, true);
      } else {
        f.visible = false;
        SceneUtils.displayFloorContour(this.soRoot, f, false);
      }
    });
  }
  public updateObsoleteRoomsHighlighting(isHighlighted?: boolean): void {
    isHighlighted ??= appModel.showObsoleteRooms;

    this.soFloorsRoot.soFloors.forEach(soFloor => {
      if (soFloor.soId === appModel.activeFloor.id && isHighlighted) {
        soFloor.soRooms.forEach(soRoom => {
          let indicator = soRoom.children.find(child => child.userData.type === SceneEntityType.ObsoleteRoomIndicator);
          if (!indicator) {
            if (!appModel.getRoomType(soRoom.userData.roomTypeId).isMarkHidden) {
              return;
            }

            const size = soRoom.getSoRoomBoundingBoxByModelLines().getSize(new THREE.Vector3());
            indicator = SceneUtils.createObsoleteRoomIndicator(size);
            indicator.applyMatrix4(soRoom.matrix.clone().setPosition(new THREE.Vector3()).invert());
            soRoom.add(indicator);
          }
        });
        return;
      }

      soFloor.soRooms.forEach(soRoom => {
        const indicator = soRoom.children.find(child => child.userData.type === SceneEntityType.ObsoleteRoomIndicator);
        if (indicator) {
          soRoom.remove(indicator);
          GeometryUtils.disposeObject(indicator);
        }
      });
    });
  }
  public updateRoofContour(floor?: Floor): void {
    let soRoof = this.soRoot.children.find(child => child.userData.type === SceneEntityType.RoofContour);
    if (soRoof) {
      if (floor && soRoof.userData.floorId === floor.id) {
        soRoof.visible = floor.index === appModel.activeCorePlan.floors.length - 1;
      } else {
        this.soRoot.remove(soRoof);
        GeometryUtils.disposeObject(soRoof);
        soRoof = null;
      }
    }

    if (!soRoof && appModel.showRoof && appModel.activeFloor?.index === appModel.activeCorePlan.floors.length - 1) {
      const soRooms = this.getActiveFloorSoRooms();
      const indoorBoxes: THREE.Box3[] = [];
      const outdoorBoxes: THREE.Box3[] = [];
      soRooms.forEach(soRoom => {
        const bb = soRoom.getSoRoomBoundingBoxByModelLines();
        if (appModel.getRoomType(soRoom.userData.roomTypeId).attributes.indoor) {
          indoorBoxes.push(bb);
        } else {
          outdoorBoxes.push(bb);
        }
      });

      const newSoRoof = SceneUtils.createRoofContour(indoorBoxes, outdoorBoxes, appModel.activeFloor.id);
      this.soRoot.add(newSoRoof);
    }
  }
  public updateCladding(): void {
    // Remove old
    const soCladding = this.soRoot.children.filter(ch => ch.userData.type === SceneEntityType.Cladding);
    soCladding.forEach(so => {
      this.soRoot.remove(so);
      GeometryUtils.disposeObject(so);
    });
    const floor = appModel.activeFloor;
    if (!floor || !appModel.showCladding) {
      return;
    }

    // Create new
    const thickness = UnitsUtils.getCladdingLineThickness();
    const soFloor = this.getSoFloor(floor.id);
    const spaces = SceneUtils.getSoCladdingSpacesWithOffset(soFloor);
    spaces.forEach(spaceOffset => {
      const cladding = SceneUtils.createCladdingLine(spaceOffset.space.contour, spaceOffset.offset, thickness);
      this.soRoot.add(cladding);
    });
  }
  public validateCladding() {
    const floor = appModel.activeFloor;
    if (!floor) {
      return;
    }

    const soFloor = this.getSoFloor(floor.id);
    const spaces = SceneUtils.getSoCladdingSpacesWithOffset(soFloor);

    // Check lotline
    if (!appModel.activeFloor.lotLine) {
      return;
    }
    const hasOutside = spaces.some(
      sp =>
        !GeometryUtils.isPolygonInsidePolygon(
          sp.space.contour.map(c => VectorUtils.Vector2ToVector3(c.start)),
          appModel.activeFloor.lotLine.offsetVertices.map(p => VectorUtils.Vector3VToVector3(p.point))
        )
    );
    // FREEZED: #DCP-1153
    /*if (hasOutside) {
      showToastMessage(MessageKindsEnum.Error, CLADDING_OUTSIDE_LOT_OFFSET_ERROR, { autoClose: MESSAGE_DURATION });
    }*/
  }
  public updateRoomDrawings(allFloors: boolean): void {
    if (!appModel.activeCorePlan || !appModel.activeFloor) {
      return;
    }

    const floors = allFloors ? this.corePlan.floors : [appModel.activeFloor];

    floors.forEach(floor => {
      floor.rooms.forEach(room => {
        const soRoom = this.getCorePlanSoRoom(room.id);

        const refLines = soRoom.children
          .filter(child => child.userData.type === RoomEntityType.ReferenceLine && child.userData.currentStretch)
          .reduce((map, ref) => map.set(ref.userData.id, { id: ref.userData.id, stretch: ref.userData.currentStretch }), new Map<string, RoomStretchData>());

        const openings: RoomOpeningData[] = soRoom.children
          .filter(it => it.userData.type === RoomEntityType.Window || it.userData.type === RoomEntityType.Door)
          .map(soOpening => {
            return {
              id: soOpening.userData.id,
              shiftDistance: soOpening.userData.shiftDistance ?? 0,
              isLocked: soOpening.userData.isLocked ?? false,
              type: soOpening.userData.type,
            };
          });

        const offsets = Array.from(appModel.segmentOffsetManager.getAllOffsets().entries()).reduce((acc, [key, item]) => {
          if (item.userData.segment.roomId.some(id => id === room.id)) {
            acc[key] = item.userData.offset;
          }
          return acc;
        }, {});

        const thicknesses = Array.from(appModel.modifiedSegmentManager.getAllModifiedSegments().values()).map(item => ({
          x: item.start.x,
          y: item.start.y,
          functionCode: item.functionCode,
          classification: item.classification,
        }));

        const drawing: RoomDrawing = {
          transformMatrix: soRoom.matrix.clone(),
          stretch: [...refLines.values()],
          openings,
          offsets,
          thicknesses,
        };
        room.setDrawing(drawing);
      });
    });
  }

  public undo(): void {
    switch (appModel.sceneEditorMode) {
      case SceneEditorMode.Room:
        if (this.dragMode === DragMode.none) {
          this.commandManager.undo();
        }
        break;
      case SceneEditorMode.Background:
        this.backgroundManager.undo();
        break;
      case SceneEditorMode.LotLine:
        this.lotLineManager.undo();
        break;
      case SceneEditorMode.RoomOpening:
        this.roomOpeningManager.undo();
        break;
    }
  }
  public redo(): void {
    switch (appModel.sceneEditorMode) {
      case SceneEditorMode.Room:
        if (this.dragMode === DragMode.none) {
          this.commandManager.redo();
        }
        break;
      case SceneEditorMode.Background:
        this.backgroundManager.redo();
        break;
      case SceneEditorMode.LotLine:
        this.lotLineManager.redo();
        break;
      case SceneEditorMode.RoomOpening:
        this.roomOpeningManager.redo();
        break;
    }
  }

  public performValidation(visualize = true): void {
    if (this.validationTool.isActive) {
      try {
        this.validationTool.performValidation();
        if (visualize) {
          this.validationTool.visualizeValidationResult();
          appModel.setValidationResult(this.validationTool.getFloorValidationResult());
        }
      } catch (error) {
        log.error("Validation error", error);
        // FREEZED: #DCP-1153
        // showToastMessage("Error", "An error occurred while performing validation.");
        appModel.setValidationResult(null);
      }
    }
  }

  public zoomToFit(): void {
    GeometryUtils.zoomToFit2D(this.getAllRoomsBoundingSphere(), this.camera, this.controls);
    this.gridAndRulersManager.update();
  }

  public updateCostStamp() {
    this.commandManager.updateCostStamp();
  }
  public isCostStampOutdated(): boolean {
    return this.commandManager.isMarkerOutdated();
  }

  public updateSegmentsCache(): void {
    // TODO: Optimize cache updating calculation.
    const floorsSegments = this.validationTool.getSpatialWallsExtendedResult();

    this.segmentsCache = {
      external: floorsSegments.externalSegmentsMap,
      internal: floorsSegments.internalSegmentsMap,
      ddl: floorsSegments.ddlSegmentsMap,
      grg: floorsSegments.garageSegmentsMap,
    };
  }
  public getFloorSegments(floorId: string): ISegments {
    try {
      return {
        external: this.segmentsCache.external.get(floorId),
        internal: this.segmentsCache.internal.get(floorId),
        ddl: this.segmentsCache.ddl.get(floorId),
        grg: this.segmentsCache.grg.get(floorId),
      };
    } catch (error) {
      console.error(`Error retrieving floor segments for floor ID: ${floorId}`, error);
      return {
        external: null,
        internal: null,
        ddl: null,
        grg: null,
      }; // Return null values for each segment if retrieval fails
    }
  }

  // --------------------------------------

  private updateIntersections(): void {
    this.raycaster.setFromCamera(this.baseManager.mousePointer, this.camera);
    const precision = Math.max(UnitsUtils.getSelectionPrecision(), this.getRaycasterRecommendedPrecision() * 80.0 * UnitsUtils.getConversionFactor());
    this.raycaster.params.Line.threshold = precision;
    this.raycaster.params.Points.threshold = precision;

    const intersections = this.raycaster.intersectObject(this.intersectionsPlane, false);
    this.intersectionPoint =
      intersections && intersections.length
        ? intersections[0].point
        : GeometryUtils.getSpaceIntersectionPoint(this.baseManager.mousePointer, this.camera, this.controls.target);
    this.controls.zoomPoint.copy(this.intersectionPoint);
  }

  /**
   * Perform necessary operations based on the current drag mode and reset the drag mode to none.
   */
  private handleDragFinish(): void {
    if (this.dragMode === DragMode.none) {
      return;
    }

    if (this.dragMode === DragMode.stretchingRoom) {
      const soRoom = this.soIntersectedStretchControl.parent as soRoom2D;
      const commands = [];

      this.toggleDimensionListeners(false);
      this.selectedRoomsDimensionTool.updateSize();

      Object.values(SceneUtils.collectReferenceLines(soRoom)).forEach(referenceLine => {
        if (!MathUtils.areNumbersEqual(this.previousStretch[referenceLine.id].currentStretch, referenceLine.currentStretch)) {
          commands.push(
            new StretchRoomCommand(soRoom.soId, referenceLine, referenceLine.currentStretch - this.previousStretch[referenceLine.id].currentStretch)
          );
        }
      });

      if (soRoom.userData.startPosition && !VectorUtils.areVectorsEqual(soRoom.position, soRoom.userData.startPosition)) {
        commands.push(new TranslateRoomCommand(soRoom.soId, soRoom.position.clone().sub(soRoom.userData.startPosition)));
        delete soRoom.userData.startPosition;
      }

      if (commands.length) {
        this.commandManager.add(new MultiCommand(commands));
      }

      // FREEZED: #DCP-1153
      // this.checkLotline([soRoom]);
      this.getActiveSoFloor().checkRoomsOverlapping(true);

      this.corePlan.setIsCostOutdated(true);
      this.openingTool.alignRoomsOpenings(appModel.activeCorePlan.floors, this.getSoFloorsRoot());

      this.checkBlockedDoors(); //todo reinstate
      this.checkIntersectedWindows();
      this.updateRoomsProperties([soRoom.soId]);
      this.roomSnapTool.showSnappingMessages();
      this.updateCladding();
      this.validateCladding();
      this.performValidation();
      this.shortWallSegmentsTool.validateShortSegments();
    } else if (this.dragMode === DragMode.movingRooms) {
      this.debouncedSetPosition();
      this.previousStretch = SceneUtils.collectReferenceLines(this.soDraggedRoom);
      // this.stretchToFit(this.soDraggedRoom);
      this.soDraggedRoom.stretchToFit(this.soFloorsRoot, this.roomSnapTool);

      const selectedSoRooms = this.getCorePlanSelectedSoRooms();
      const delta = selectedSoRooms[0].position.clone().sub(selectedSoRooms[0].userData.startPosition);
      const commands: RoomCommand[] = [];

      selectedSoRooms.forEach(soRoom => {
        soRoom.position.copy(soRoom.userData.startPosition.add(delta));
        soRoom.updateMatrixWorld();
        commands.push(new TranslateRoomCommand(soRoom.soId, delta));
      });

      Object.values(SceneUtils.collectReferenceLines(this.soDraggedRoom)).forEach(referenceLine => {
        if (!MathUtils.areNumbersEqual(this.previousStretch[referenceLine.id].currentStretch, referenceLine.currentStretch)) {
          commands.push(
            new StretchRoomCommand(this.soDraggedRoom.soId, referenceLine, referenceLine.currentStretch - this.previousStretch[referenceLine.id].currentStretch)
          );
        }
      });

      this.commandManager.add(new MultiCommand(commands));

      GraphAnalysisUtils.regenerateRoomsWalls(this, this.getActiveSoFloor());
      this.updateRoofContour();
      this.floorDimensionTool.updateSize(true);
      this.selectedRoomsDimensionTool.updateSize();
      // FREEZED: #DCP-1153
      // this.checkLotline(selectedSoRooms);
      this.getActiveSoFloor().checkRoomsOverlapping(true);
      this.openingTool.alignRoomsOpenings(appModel.activeCorePlan.floors, this.getSoFloorsRoot());

      this.checkBlockedDoors();
      this.updateRoomsProperties(selectedSoRooms.map(soRoom => soRoom.soId));
      this.roomSnapTool.end();
      this.roomSnapTool.showSnappingMessages();
      this.corePlan.setIsCostOutdated(true);
      this.updateCladding();
      this.validateCladding();
      this.performValidation();
      this.shortWallSegmentsTool.validateShortSegments();

      this.soDraggedRoom = null;
    }

    this.dragMode = DragMode.none;
    this.controls.noPan = false;
    this.baseManager.setCursorStyle(CursorStyle.Default);
  }

  public async onActiveCorePlanChanged(corePlan?: CorePlan): Promise<void> {
    appModel.setIsSceneLoaded(false);
    this.corePlan = corePlan;

    this.commandManager.clearScopes();
    this.unsubscribe(this.reactions);
    this.unsubscribe(this.selectedRoomReactions);
    this.selectAllRooms(false);
    this.clearBasePoint();
    this.clearFloors();
    this.clearFloorContours();
    this.clearRoofContour();
    this.selectedRoomsDimensionTool.setRooms([], false);
    this.floorDimensionTool.setRooms([], true);
    this.validationTool.reset(true);
    this.validationTool.removeValidationVisualization();
    this.shortWallSegmentsTool.clearShortSegmentsValidationResult();
    // ... clear other local content;

    if (settings.settingsUpdated) {
      settings.isSettingsUpdated = false;
      this.gridAndRulersManager.setColors();
      this.soCachedRooms.clear();
      // this.soCachedRooms = new Map<string, soRoom2D>();
      appModel.setGridUnitSizeInches(settings.values.validationSettings.gridCellSizeForNewCorePlans);
    }

    if (this.corePlan) {
      this.loadBasePoint();

      await this.loadFloors();

      this.subscribe();
      this.onActiveFloorChanged(appModel.activeFloor);
      this.validationTool.performPlmValidation(this.getActiveSoFloor());
      if (!this.corePlan.isCostOutdated) {
        this.updateCostStamp();
      }
      if (appModel.activeFloor) {
        this.zoomToFit();
      }
    }

    appModel.setIsBusy(false);
  }
  private onActiveFloorChanged(floor?: Floor): void {
    this.dragMode = DragMode.none;

    this.windowSelectionTool.end();
    this.roomSnapTool.end();

    this.soDraggedRoom = null;
    this.soIntersectedStretchControl = null;
    this.previousStretch = null;
    this.moveStartPosition = null;

    this.commandManager.setScope(floor?.id);
    this.updateFloorsVisibility();
    this.updateObsoleteRoomsHighlighting();

    this.checkBlockedDoors();
    this.checkIntersectedWindows();
    this.updateRoofContour(floor);
    this.updateCladding();
    this.validateCladding();

    if (floor) {
      this.floorDimensionTool.setRooms(this.getActiveSoFloor()?.soRooms ?? [], true);
      this.shortWallSegmentsTool.validateShortSegments();
      if (this.validationTool.isActive) {
        this.validationTool.visualizeValidationResult();
        appModel.setValidationResult(this.validationTool.getFloorValidationResult());
      }
    }
  }
  private onSelectedRoomsChanged(): void {
    this.getCorePlanSoRooms().forEach(soRoom => {
      if (appModel.selectedRoomsIds.includes(soRoom.soId)) {
        if (!soRoom.userData.isSelected) {
          SceneUtils.highlightSelectedRoom(soRoom);
          SceneUtils.displayRoomStretchControls(soRoom, true, appModel.isViewOnlyMode);
        }
      } else {
        if (soRoom.userData.isSelected) {
          SceneUtils.unhighlightSelectedRoom(soRoom);
          SceneUtils.displayRoomStretchControls(soRoom, false);
        }
      }
    });

    this.unsubscribe(this.selectedRoomReactions);

    if (appModel.selectedRoomsIds.length === 1) {
      this.subscribeSelectedRoom();
    }

    this.selectedRoomsDimensionTool.setRooms(this.getCorePlanSelectedSoRooms(), false);
  }
  private onShowBelowFloorChanged(show: boolean): void {
    this.updateSingleFloorVisibility(false, show);
    this.shortWallSegmentsTool.validateShortSegments();
  }
  private onShowAboveFloorChanged(show: boolean): void {
    this.updateSingleFloorVisibility(true, show);
    this.shortWallSegmentsTool.validateShortSegments();
  }
  private onDimensionTypeChanged(): void {
    this.selectedRoomsDimensionTool.updateSize();
    this.updateRoomsProperties(appModel.selectedRoomsIds);
    GraphAnalysisUtils.regenerateRoomsWalls(this, this.getActiveSoFloor());

    if (appModel.selectedRoomOpenings.length > 0) {
      const opening = appModel.selectedRoomOpenings[0];
      const soOpening = this.roomOpeningManager.getActiveFloorSoOpening(opening);

      const soRoom = soOpening.parent;
      const room = this.corePlan.getRoom(opening.roomId);

      this.populateRoomProperties(room, soRoom as soRoom2D);
    }
  }
  private onShowRoofChanged(): void {
    this.updateRoofContour();
  }
  private onShowCladdingChanged(): void {
    this.updateCladding();
    this.floorDimensionTool.updateSize(true);
  }
  private onCladdingThicknessChanged(): void {
    this.updateCladding();
    this.performValidation();
  }
  private onShowObsoleteRoomsChanged(): void {
    this.updateObsoleteRoomsHighlighting();
  }
  private onValidationModeChanged() {
    if (this.validationTool.isActive) {
      this.performValidation();
    } else {
      this.validationTool.removeValidationVisualization();
      this.validationTool.reset();
      appModel.setValidationResult(null);
    }
  }
  private async onCorePlanFloorsNumberChanged(newFloorCount: number, oldFloorCount: number) {
    if (newFloorCount > oldFloorCount) {
      const minLoadFloorIndex = oldFloorCount;

      await this.loadFloors(minLoadFloorIndex);
      this.updateRoofContour();
      this.performValidation();
    } else {
      const minDeleteFloorIndex = newFloorCount;
      this.clearFloors(minDeleteFloorIndex);
      if (appModel.activeFloor.index >= newFloorCount) {
        const newLastFloor = appModel.activeCorePlan.floors.find(f => f.index === newFloorCount - 1);
        if (this.validationTool.isActive) {
          this.performValidation(false);
        }
        appModel.setActiveFloor(newLastFloor.id);
      } else {
        this.performValidation();
      }
    }
  }
  private onSelectedRoomNetWidthChanged(netWidth: number): void {
    if (this.dragMode !== DragMode.none) {
      return;
    }

    const room = this.corePlan.getRooms(appModel.selectedRoomsIds)[0];
    const soRoom = this.getActiveFloorSoRoom(room.id);

    const size = soRoom.getSoRoomBoundingBoxByModelLines().getSize(new THREE.Vector3());
    const netSize = RoomUtils.getSoRoomNetBoundingBox(this, soRoom).getSize(new THREE.Vector3());

    if (MathUtils.areNumbersEqual(netWidth, netSize.x)) {
      return;
    }

    const referenceLines = SceneUtils.collectStretchedReferenceLines(soRoom, netWidth - netSize.x, Direction.Horizontal);
    if (referenceLines.some(ref => ref.stretchedDistance !== 0)) {
      this.commandManager.apply(
        new MultiCommand(referenceLines.map(referenceLine => new StretchRoomCommand(soRoom.soId, referenceLine, referenceLine.stretchedDistance)))
      );
    } else {
      room.setWidth(size.x);
      room.setNetWidth(netSize.x);
    }
  }
  private onSelectedRoomNetHeightChanged(netHeight: number): void {
    if (this.dragMode !== DragMode.none) {
      return;
    }

    const room = this.corePlan.getRooms(appModel.selectedRoomsIds)[0];
    const soRoom = this.getActiveFloorSoRoom(room.id);

    const size = soRoom.getSoRoomBoundingBoxByModelLines().getSize(new THREE.Vector3());
    const netSize = RoomUtils.getSoRoomNetBoundingBox(this, soRoom).getSize(new THREE.Vector3());

    if (MathUtils.areNumbersEqual(netHeight, netSize.y)) {
      return;
    }

    const referenceLines = SceneUtils.collectStretchedReferenceLines(soRoom, netHeight - netSize.y, Direction.Vertical);
    if (referenceLines.some(ref => ref.stretchedDistance !== 0)) {
      this.commandManager.apply(
        new MultiCommand(referenceLines.map(referenceLine => new StretchRoomCommand(soRoom.soId, referenceLine, referenceLine.stretchedDistance)))
      );
    } else {
      room.setHeight(size.y);
      room.setNetHeight(netSize.y);
    }
  }
  private onSelectedRoomXChanged(x: number): void {
    if (this.dragMode !== DragMode.none) {
      return;
    }

    const soRoom = this.getActiveFloorSoRoom(appModel.selectedRoomsIds[0]);

    if (MathUtils.areNumbersEqual(x, soRoom.position.x)) {
      return;
    }

    const offset = new THREE.Vector3(-soRoom.position.x + x, 0, 0);
    this.commandManager.apply(new TranslateRoomCommand(soRoom.soId, offset));
  }
  private onSelectedRoomYChanged(y: number): void {
    if (this.dragMode !== DragMode.none) {
      return;
    }

    const soRoom = this.getActiveFloorSoRoom(appModel.selectedRoomsIds[0]);

    if (MathUtils.areNumbersEqual(y, soRoom.position.y)) {
      return;
    }

    const offset = new THREE.Vector3(0, -soRoom.position.y + y, 0);
    this.commandManager.apply(new TranslateRoomCommand(soRoom.soId, offset));
  }

  private subscribe(): void {
    this.reactions.push(reaction(() => appModel.activeFloor, this.onActiveFloorChanged.bind(this)));
    this.reactions.push(reaction(() => this.corePlan.floors.length, this.onCorePlanFloorsNumberChanged.bind(this)));

    this.reactions.push(reaction(() => appModel.showBelowFloor, this.onShowBelowFloorChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.showAboveFloor, this.onShowAboveFloorChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.showRoof, this.onShowRoofChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.showCladding, this.onShowCladdingChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.showObsoleteRooms, this.onShowObsoleteRoomsChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.activeValidationMode, this.onValidationModeChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.includeCladdingThickness, this.onCladdingThicknessChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.showFinishFaceDimension, this.onDimensionTypeChanged.bind(this)));

    this.reactions.push(reaction(() => appModel.selectedRoomsIds.length, this.onSelectedRoomsChanged.bind(this)));
  }
  private subscribeSelectedRoom(): void {
    const room = this.corePlan.getRooms(appModel.selectedRoomsIds)[0];

    this.selectedRoomReactions.push(
      reaction(() => room.netWidth, this.onSelectedRoomNetWidthChanged.bind(this)),
      reaction(() => room.netHeight, this.onSelectedRoomNetHeightChanged.bind(this)),
      reaction(() => room.x, this.onSelectedRoomXChanged.bind(this)),
      reaction(() => room.y, this.onSelectedRoomYChanged.bind(this))
    );
  }
  private unsubscribe(reactions: IReactionDisposer[]): void {
    reactions.forEach(r => r());
    reactions.length = 0;
  }

  private loadBasePoint(): void {
    this.soRoot.add(SceneUtils.createBasePoint(VectorUtils.Vector3VToVector3(this.corePlan.basePoint)));
  }

  private async loadFloors(minIndex = 0): Promise<void> {
    try {
      appModel.setIsSceneLoaded(false);

      const floorsRequests = this.corePlan.floors.filter(floor => floor.index >= minIndex).map((floor: Floor) => this.loadFloor(floor).catch(() => null));

      const soFloors = await Promise.all(floorsRequests);

      if (soFloors.length === 0) {
        showToastMessage(MessageKindsEnum.Error, NO_FLOORS_ERROR, { autoClose: 700 });
        return;
      }

      this.soFloorsRoot.addFloors(soFloors);
      //this.soFloorsRoot.add(...soFloors); // TODO - // replace with this.soFloorsRoot.soFloors.push. need to be removed

      this.updateSegmentsCache();

      runInAction(() => {
        if (appModel.featureFlags && appModel.featureFlags["dynamicRoomBoundary"])
          this.corePlan.floors.forEach(floor => {
            floor.rooms.forEach(room => {
              this.updateSoRoomWallTypesFromRoom(room);
              this.updateSoRoomWallOffsetsFromRoom(room);
              this.addSnapEventsToRoomManager(room);
            });
          });

        this.regenerateWallAnalysis(soFloors);
      });

      this.getActiveSoFloor().checkRoomsOverlapping(false);
      appModel.setIsSceneLoaded(true);
    } catch (error) {
      console.error("Error loading floors", error);
    }
  }

  /**
   * Updates the wall types for a given room.
   *
   * @param room - The room object containing the wall types to update.
   *
   * This function compares the wall types in the room object with the corresponding wall types in the
   * SO room object. If a difference is found, it adjusts the room side by the specified wall type.
   */
  private updateSoRoomWallTypesFromRoom(room: Room) {
    const soRoom = this.getCorePlanSoRoom(room.id);
    if (!soRoom) {
      return;
    }
    //if (!room.wallTypes)
    Object.entries(soRoom.userData.RoomSidesWallTypes).forEach(([side, wallType]) => {
      if (!room.wallTypes[side]) room.wallTypes[side] = FuncCode.EXT_2X4;
      if (wallType !== room.wallTypes[side]) {
        soRoom.adjustRoomSideByWallType(side as Side, room.wallTypes[side]);
      }
    });
  }

  /**
   * Updates the wall offsets for a given room.
   *
   * @param room - The room object containing the wall offsets to update.
   *
   * This function compares the wall offsets in the room object with the corresponding wall offsets in the
   * SO room object. If a difference is found, it moves the room side by the specified offset distance
   * and updates the SO room data.
   */
  private updateSoRoomWallOffsetsFromRoom(room: Room) {
    const soRoom = this.getCorePlanSoRoom(room.id);
    if (!soRoom) return;
    Object.entries(soRoom.userData.RoomSidesWallOffset).forEach(([side, wallOffset]) => {
      if (wallOffset !== room.wallOffset[side]) {
        soRoom.moveRoomSideByDistance(side as Side, room.wallOffset[side]);
        soRoom.userData.RoomSidesWallOffset[side] = room.wallOffset[side];
      }
    });
  }

  /**
   * Adds snap events to the room manager.
   *
   * @param room - The room object containing the snap events to add.
   *
   * This function creates snap events from the event keys in the room object and adds them to the
   * snap events manager.
   */
  private addSnapEventsToRoomManager(room: Room) {
    room.snapEvents
      .map(eventKey => this.createFromEventKey(eventKey))
      .forEach(event => {
        this.snapEvents[event.EventKey] = event;
      });
  }

  /**
   * Regenerates wall analysis for the active and all SO floors.
   *
   * This function regenerates the room walls for the active SO floor if it exists.
   * It then iterates through all SO floors, regenerating the room walls and populating room properties
   * for each room in the floor.
   */
  private regenerateWallAnalysis(soFloors: soFloor2D[]) {
    const activeSoFloor = this.getActiveSoFloor();
    if (activeSoFloor) {
      GraphAnalysisUtils.regenerateRoomsWalls(this, activeSoFloor);
    }

    soFloors.forEach(soFloor => {
      GraphAnalysisUtils.regenerateRoomsWalls(this, soFloor);
      soFloor.soRooms.forEach(soRoom => {
        const corePlanRoom = appModel.activeCorePlan.getRoom(soRoom.soId);

        this.populateRoomProperties(corePlanRoom, soRoom);
      });
    });
  }

  private async loadFloor(floor: Floor): Promise<soFloor2D> {
    try {
      const soFloor = new soFloor2D(floor.id, floor.name, floor.index);

      soFloor.name = floor.name;
      soFloor.userData.id = floor.id;
      soFloor.soId = floor.id;
      soFloor.userData.type = SceneEntityType.FloorPlan;
      soFloor.userData.index = floor.index;

      const roomRequests = floor.rooms.map((room: Room) => this.loadRoom(room).catch(() => null)) as Promise<soRoom2D>[];
      const soRooms = (await Promise.all(roomRequests)).filter(room => room);

      if (soRooms.length) {
        //soFloor.add(...soRooms); // TODO - adding soRooms to children. need to be removed
        soFloor.addRooms(soRooms);
      }

      soFloor.checkRoomsOverlapping(false);

      this.checkBlockedDoors(soRooms);

      return soFloor;
    } catch (error) {
      console.error("Error loading floor", error);
    }
  }

  private async loadRoom(room: Room): Promise<soRoom2D> {
    let soRoom: soRoom2D = this.soCachedRooms.get(room.roomTypeId);
    if (!soRoom) {
      let hasErrors = false;

      const roomType = appModel.getRoomType(room.roomTypeId);
      const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
      const entities = roomType.roomEntities;

      const requests = entities.flatMap(async (roomEntity: RoomEntity) => {
        const fileId = isWooMode() ? roomEntity.fileId : appModel.dxfStore.find(dxfItem => dxfItem.id === roomEntity.fileId)?.data;
        try {
          const soRoomEntity = await SceneUtils.loadDxf(fileId);

          return SceneUtils.processSoRoomEntity(roomEntity, soRoomEntity, !!roomType.attributes.indoor);
        } catch (e) {
          hasErrors = true;
          // freezed: makes a lot of noise logging in console when room is not loaded  (also rooms that doesnt exist anymore)
          //log.error(`Dxf is not loaded: ${roomEntity.fileId}`, e);
        }
      });

      const roomItems = (await Promise.all(requests)).flat().filter(it => it);

      const roomBoundaryLines = roomItems.filter(x => x instanceof soBoundaryLine && x.lineType === RoomEntityType.RoomBoundaryLines) as soBoundaryLine[];
      const roomModelLines = roomItems.filter(x => x instanceof soBoundaryLine && x.lineType === RoomEntityType.ModelLine) as soBoundaryLine[];
      const soRoomItems = roomItems.filter(x => x instanceof soRoomItem2D) as soRoomItem2D[];
      const soRoomDataBox = roomItems.filter(x => x instanceof soDataBox) as soDataBox[];

      // Determine the offset distance based on the existence of model lines or boundary lines
      const OffsetDistance = UnitsUtils.convertInchesToUnits(2); // Adjust the offset distance as needed

      //If model lines exist, offset them negatively (inward), and creation type is RoomBoundaryLines
      if (roomModelLines.length > 0 && roomBoundaryLines.length == 0) {
        const offsetModelLines = GeometryUtils.offsetSoLines(
          GeometryUtils.orderRectangleSoLinesClockwise(roomModelLines),
          OffsetDistance,
          RoomEntityType.RoomBoundaryLines
        );

        roomItems.push(...offsetModelLines);
      }

      // If no model lines exist but boundary lines do, offset boundary lines positively (outward),
      // and creation type is ModelLine
      else if (roomBoundaryLines.length > 0) {
        const offsetBoundaryLines = GeometryUtils.offsetLines(
          GeometryUtils.orderRectangleLinesClockwise(roomBoundaryLines),
          -OffsetDistance,
          RoomEntityType.ModelLine
        );
        roomItems.push(...offsetBoundaryLines);
      }

      if (roomItems.length === 0) {
        throw new Error(`Room has no entities: ${room.id}`);
      }

      // remove original Revit room floor entity:
      const soOriginalFloor = roomItems.find(x => x.userData.type === RoomEntityType.Floor);
      if (soOriginalFloor) {
        roomItems.splice(roomItems.indexOf(soOriginalFloor), 1);
      }

      soRoom = new soRoom2D();
      soRoom.userData.type = SceneEntityType.Room; // TODO - need to be removed
      soRoom.roomType = SceneEntityType.Room;
      // soRoom.soId = room.id;
      const newRoomItems = roomItems.map(x => {
        return x instanceof soBoundaryLine ? x.line : x;
      });

      soRoom.add(...newRoomItems);

      const soModelLines = new soRoomBoundary(roomModelLines, RoomEntityType.ModelLine);
      const soRoomBoundaryLines = new soRoomBoundary(roomBoundaryLines, RoomEntityType.RoomBoundaryLines);

      soRoom.setRoomBoundary(soModelLines);
      soRoom.setNetRoomBoundary(soRoomBoundaryLines);
      soRoom.addItems(soRoomItems);
      soRoom.setRoomDataBox(soRoomDataBox);

      this.hideContourWalls(soRoom);
      const soFloor = SceneUtils.createFloorByModelLines(
        soRoom,
        entities.find(e => e.type === RoomEntityType.Floor)
      );

      soRoom.add(soFloor);

      SceneUtils.addStretchSoTriangles(soRoom);
      SceneUtils.addOpeningZonePoints(soRoom);
      SceneUtils.addOpeningClippingBox(soRoom);

      const offset = soRoom.getSoRoomBoundingBoxByModelLines().getCenter(new THREE.Vector3());

      soRoom.children.forEach(roomItem => {
        if (roomItem.userData.type === RoomEntityType.ReferenceLine) {
          const stretchPointsPair = roomItem as THREE.Points;
          const points = GeometryUtils.getPointsPositions(stretchPointsPair.geometry as THREE.BufferGeometry);
          points[0].sub(offset);
          points[1].sub(offset);
          stretchPointsPair.geometry.dispose();
          stretchPointsPair.geometry = new THREE.BufferGeometry().setFromPoints(points);
          return;
        }
        roomItem.position.sub(offset);
      });

      soRoom.initRoomWallTypes();
      soRoom.initRoomWallOffset();
      if (appModel.featureFlags["dynamicRoomBoundary"]) {
        RoomUtils.applyRoomWallTypes(soRoom);
      }
      soRoom.updateMatrixWorld();
      if (!hasErrors) {
        // this.soCachedRooms[room.roomTypeId] = soRoom;

        this.soCachedRooms.set(room.roomTypeId, soRoom);
      }
    }

    const result: soRoom2D = GeometryUtils.soRoomDeepClone(soRoom);

    result.RoomFloorSlab = soRoom.RoomFloorSlab;
    result.roomType = soRoom.roomType;

    result.name = room.name;
    result.userData.id = room.id;
    result.soId = room.id;
    result.userData.roomTypeId = room.roomTypeId;

    if (room.drawing?.stretch) {
      const refLines = SceneUtils.collectReferenceLines(result);
      room.drawing.stretch.forEach((ref: RoomStretchData) => {
        result.updateMatrixWorld();
        SceneUtils.stretchRoomByReferenceLine(result, refLines[ref.id], ref.stretch);
      });
    }

    const transformMatrix = room.drawing?.transformMatrix;
    if (transformMatrix) {
      result.applyMatrix4(transformMatrix);
      result.updateMatrixWorld();
    }

    room.drawing?.openings?.forEach((opening: RoomOpeningData) => {
      const soOpening = result.children.find(child => child.userData.id === opening.id);
      if (!MathUtils.areNumbersEqual(opening.shiftDistance, 0)) {
        SceneUtils.moveOpening(soOpening, opening.shiftDistance * Math.sign(result.scale.x));
      }

      soOpening.userData.isLocked = opening.isLocked;
    });

    if (room.drawing?.offsets && Object.entries(room.drawing?.offsets).length > 0) {
      result.offsets = room.drawing?.offsets;
    }

    if (room.drawing?.thicknesses && room.drawing?.thicknesses.length > 0) {
      result.thicknesses = room.drawing?.thicknesses;
    }
    return result;
  }

  private hideContourWalls(soRoom: soRoom2D): void {
    const modelLines: soBoundaryLine[] = [];

    soRoom.roomBoundary.boundaryLines.forEach(child => {
      if (child instanceof soBoundaryLine) {
        modelLines.push(child);
      }
    });
    const soModelLines = modelLines.map(so => so.line);

    soRoom.children
      .filter(child => child.userData.type === RoomEntityType.Wall)
      .forEach(soWall => {
        const wallBb = GeometryUtils.getGeometryBoundingBox2D(soWall);
        if (!wallBb) {
          return;
        }

        const direction = GeometryUtils.getLineDirection(GeometryUtils.getBoundingBoxCenterLine(wallBb));
        const wallLine = soModelLines
          .map(so => SceneUtils.getLine3(so))
          .find(l => GeometryUtils.getLineDirection(l) === direction && GeometryUtils.lineIntersectsBoundingBox(l, wallBb));

        // Skip this wall if it is not located on the the contour of the room
        if (!wallLine) {
          return;
        } else {
          GeometryUtils.setStencilMask(soWall, THREE.ZeroStencilOp);
        }
      });
  }

  /**
   * Creates an instance of SnapEvent from an event key string.
   * @param eventKey - The event key representing the primary and secondary rooms and their sides.
   * @param tolerance - The snapping tolerance value. Defaults to EPSILON.
   * @returns A new instance of SnapEvent.
   * @throws Error if the event key format is invalid.
   */
  public createFromEventKey(eventKey: string, tolerance: number = EPSILON): SnapEvent {
    // Split the event key by comma to extract its components
    const parts = eventKey.split(",");
    if (parts.length !== 4) {
      throw new Error("Invalid event key format. Expected format: 'primaryRoomSide,secondaryRoomSide,primaryRoomId,secondaryRoomId'.");
    }

    const [primaryRoomSide, secondaryRoomSide, primaryRoomId, secondaryRoomId] = parts;
    const soRooms = this.getCorePlanSoRooms();
    // Find or get the primary and secondary rooms by their user IDs.
    const primaryRoom = soRooms.find(soRoom => soRoom.soId === primaryRoomId);
    const secondaryRoom = soRooms.find(soRoom => soRoom.soId === secondaryRoomId);

    if (!primaryRoom || !secondaryRoom) {
      throw new Error("Unable to find rooms with the provided IDs.");
    }

    // Create the SnapEvent instance
    const snapEvent = new SnapEvent(primaryRoom, secondaryRoom, tolerance);

    return snapEvent;
  }

  private populateRoomProperties(room: Room, soRoom: soRoom2D): void {
    runInAction(() => {
      const size = soRoom.getSoRoomBoundingBoxByModelLines().getSize(new THREE.Vector3());
      const netSize = RoomUtils.getSoRoomNetBoundingBox(this, soRoom).getSize(new THREE.Vector3());
      room.setX(soRoom.position.x);
      room.setY(soRoom.position.y);
      room.setWidth(size.x);
      room.setHeight(size.y);
      room.setNetWidth(netSize.x);
      room.setNetHeight(netSize.y);
      room.wallTypes = soRoom.userData.RoomSidesWallTypes;
      room.wallOffset = soRoom.userData.RoomSidesWallOffset;
      const currentSnapEvents = Object.values(this.snapEvents).filter(snap => snap.primaryRoom.id == soRoom.id);
      room.setSnapEvents(currentSnapEvents.map(event => event.EventKey));

      let angle = MathUtils.round(THREE.MathUtils.radToDeg(soRoom.rotation.z), 1);
      if (angle % 180 === 0) {
        angle = Math.abs(angle);
      }
      room.setRotation(angle);

      room.setMirroring(soRoom.scale.x === -1);

      // set finishes and roofSlopes by faces
      const SoPreviewRoom = soRoom as unknown as SoPreviewRoom; // TODO - need to be changed

      room.exteriorFinishes = room.exteriorFinishes ?? [-1, -1, -1, -1];
      room.exteriorFinishes = [...room.exteriorFinishes];

      room.roofSlopes = room.roofSlopes ?? [-1, -1, -1, -1];
      room.roofSlopes = [...room.roofSlopes];

      room.dutchGableDepths = room.dutchGableDepths ?? [0, 0, 0, 0];
      room.dutchGableDepths = [...room.dutchGableDepths];

      room.gableExteriorFinishes = room.gableExteriorFinishes ?? [-1, -1, -1, -1];
      room.gableExteriorFinishes = [...room.gableExteriorFinishes];

      if (SoPreviewRoom.walls !== undefined) {
        for (let i = 0; i < 4; i++) {
          room.roofSlopes[i] = SoPreviewRoom.walls[i].roofSlope !== undefined ? SoPreviewRoom.walls[i].roofSlope : -1;
          room.exteriorFinishes[i] = SoPreviewRoom.walls[i].exteriorFinishIndex !== undefined ? SoPreviewRoom.walls[i].exteriorFinishIndex : -1;
        }
      }
      if (SoPreviewRoom.gables !== undefined) {
        for (let i = 0; i < 4; i++) {
          room.gableExteriorFinishes[i] = SoPreviewRoom.gables[i].exteriorFinishIndex !== undefined ? SoPreviewRoom.gables[i].exteriorFinishIndex : -1;
        }
      }

      soRoom.children.forEach(so => {
        if (so.userData.type === RoomEntityType.Window || so.userData.type === RoomEntityType.Door) {
          let opening = room.openings.find(opening => opening.id === so.userData.id);
          if (!opening) {
            opening = new RoomOpening(so.userData.id, so.userData.type);
            room.addOpening(opening);
          }

          const openingData = SceneUtils.getOpeningZoneAndLine(so);

          // Ensure openingData and openingData.zone are not null/undefined
          if (openingData && openingData.zone) {
            const minFinishDistance = this.roomOpeningManager.calculateOpeningShiftDistance(this, so, openingData.zone.start, false);
            const maxFinishDistance = this.roomOpeningManager.calculateOpeningShiftDistance(this, so, openingData.zone.end, false);
            const shiftDistance = Math.sign(soRoom.scale.x) * (so.userData.shiftDistance ?? 0);

            let referenceShiftDistance = this.calculateReferenceShiftDistance(openingData, shiftDistance, minFinishDistance, maxFinishDistance);
            referenceShiftDistance = this.roomOpeningManager.adjustDistance(
              referenceShiftDistance,
              minFinishDistance,
              maxFinishDistance,
              openingData,
              so,
              true
            );

            opening.setShiftDistance(shiftDistance);
            opening.setReferenceShiftDistance(referenceShiftDistance);
            opening.setIsLocked(so.userData.isLocked ?? false);
          } else {
            console.log(`Missing zone data for opening ${so.userData.id}`);
          }
        }
      });
    });
  }

  /**
   * Calculates the reference shift distance for a room opening based on the orientation
   * of the opening line and its position (start vs. end).
   *
   * @param openingData - The data containing the opening zone and line, including start and end points.
   * @param shiftDistance - The current shift distance of the opening.
   * @param minDistance - The minimum shift distance that the opening can move to.
   * @param maxDistance - The maximum shift distance that the opening can move to.
   * @returns The calculated reference shift distance.
   */
  private calculateReferenceShiftDistance(
    openingData: { zone: THREE.Line3; line: THREE.Line3; center: THREE.Vector3 },
    shiftDistance: number,
    minDistance: number,
    maxDistance: number
  ): number {
    let relativeShiftDistance = 0;

    if (GeometryUtils.isLineHorizontal(openingData.line)) {
      relativeShiftDistance = openingData.zone.start.x < openingData.zone.end.x ? shiftDistance + minDistance * -1 : (shiftDistance + maxDistance * -1) * -1;
    } else if (GeometryUtils.isLineVertical(openingData.line)) {
      relativeShiftDistance = openingData.zone.start.y < openingData.zone.end.y ? shiftDistance + minDistance * -1 : (shiftDistance + maxDistance * -1) * -1;
    }

    return relativeShiftDistance;
  }

  private clearBasePoint(): void {
    const soBasePoint = this.getSoBasePoint();
    if (soBasePoint) {
      GeometryUtils.disposeObject(soBasePoint);
      this.soRoot.remove(soBasePoint);
    }
  }

  private clearFloors(minIndex: number = null): void {
    if (minIndex === null) {
      this.soFloorsRoot.soFloors = [];
      GeometryUtils.disposeObject(this.soFloorsRoot);
    } else {
      this.soFloorsRoot.soFloors.forEach(soFloor => {
        if (soFloor.userData.index < minIndex) {
          return;
        }
        GeometryUtils.disposeObject(soFloor);
      });
      this.soFloorsRoot.children.forEach(soFloor => {
        // TODO - need to be removed
        if (soFloor.userData.index < minIndex) {
          return;
        }
        GeometryUtils.disposeObject(soFloor);
      });
    }
  }

  private clearFloorContours(): void {
    this.soRoot.children.filter(item => item.userData.type === SceneEntityType.FloorContour).forEach(item => GeometryUtils.disposeObject(item));
  }

  private clearRoofContour(): void {
    this.soRoot.children
      .filter(it => it.userData.type === SceneEntityType.RoofContour)
      .forEach(it => {
        this.soRoot.remove(it);
        GeometryUtils.disposeObject(it);
      });
  }

  public getVisibleSoFloors(): soFloor2D[] {
    return this.soFloorsRoot.getVisibleSoFloors();
  }

  private getAllRoomsBoundingSphere(): THREE.Sphere {
    const bb = new THREE.Box3();
    bb.setFromObject(this.soFloorsRoot);

    if (!bb.isEmpty()) {
      const result = new THREE.Sphere();
      bb.getBoundingSphere(result);
      return result;
    } else {
      return new THREE.Sphere(new THREE.Vector3(), 120);
    }
  }

  private updateDragPosition(draggedObjects: THREE.Object3D[], isShiftPressed: boolean = false): void {
    // TODO - THREE.Object3D need to be changed to soRoom2D
    // TODO - THREE.Object3D need to be changed to soRoom2D
    if (appModel.editToolOptions.show) {
      RoomEditToolPosition.setPosition(false);
      this.debouncedSetPosition();
    }
    const newPosition = this.intersectionPoint.clone().sub(draggedObjects[0].userData.moveOffset || new THREE.Vector3());
    // Lock specific axis.
    if (isShiftPressed) {
      const startOffset = newPosition.clone().sub(draggedObjects[0].userData.startPosition);
      if (Math.abs(startOffset.x) < Math.abs(startOffset.y)) {
        newPosition.x = draggedObjects[0].userData.startPosition.x;
      } else {
        newPosition.y = draggedObjects[0].userData.startPosition.y;
      }
    }
    const delta = newPosition.clone().sub(draggedObjects[0].position);

    draggedObjects.forEach(soObject => {
      soObject.position.add(delta);
      soObject.updateMatrixWorld();
    });
  }

  private isBasePointIntersected(): boolean {
    const res = this.getSoBasePoint() ? this.raycaster.intersectObject(this.getSoBasePoint()) : [];
    return res.length !== 0;
  }

  private moveBasePoint(): void {
    const soBasePoint = this.getSoBasePoint();
    this.updateDragPosition([soBasePoint]);

    appModel.activeCorePlan.basePoint = VectorUtils.Vector3ToVector3V(soBasePoint.position.clone());
  }

  private getIntersectedStretchControl(): THREE.Object3D {
    // TODO - need to be changed to soIntersectedStretchControl
    if (appModel.isViewOnlyMode) {
      return;
    }

    const soRooms = this.getCorePlanSelectedSoRooms();

    if (soRooms.length) {
      for (const soRoom of soRooms) {
        // First search for triangles without threshold as they have highest render order.
        const prevThreshold = this.raycaster.params.Line.threshold;
        this.raycaster.params.Line.threshold = 0;
        this.raycaster.params.Points.threshold = 0;
        const stretchTriangles = soRoom.children.filter(c => c.userData.type === SceneEntityType.StretchTriangle);

        let intersections = this.raycaster.intersectObjects(stretchTriangles, true);
        if (intersections.length) {
          return intersections[0].object.type !== SceneEntityType.StretchTriangle ? intersections[0].object.parent : intersections[0].object;
        }

        // Search for points with set threshold.
        this.raycaster.params.Line.threshold = prevThreshold;
        this.raycaster.params.Points.threshold = prevThreshold;
        const stretchPoints = soRoom.children.filter(c => c.userData.type === RoomEntityType.ReferenceLine);
        intersections = this.raycaster.intersectObjects(stretchPoints, true);
        if (intersections.length) {
          return intersections[0].object;
        }

        // Search again for triangles but with set threshold.
        intersections = this.raycaster.intersectObjects(stretchTriangles, true);
        if (intersections.length) {
          return intersections[0].object.userData.type !== SceneEntityType.StretchTriangle ? intersections[0].object.parent : intersections[0].object;
        }
      }
    }

    return null;
  }

  private toggleDimensionListeners(isDisable: boolean) {
    this.selectedRoomsDimensionTool.toggleListeners(isDisable);
    this.floorDimensionTool.toggleListeners(isDisable);
  }

  private moveStretchTriangles(): void {
    if (!this.soIntersectedStretchControl) {
      return;
    }
    this.toggleDimensionListeners(true);

    const soRoom = this.soIntersectedStretchControl.parent as soRoom2D;

    const center = soRoom.getSoRoomBoundingBoxByModelLines().getCenter(new THREE.Vector3());
    const directionPoint = GeometryUtils.getPointOnObject(this.soIntersectedStretchControl);
    const delta = this.intersectionPoint.clone().sub(this.moveStartPosition);
    const roomId = appModel.activeCorePlan?.getRooms(appModel.selectedRoomsIds)[0].id;
    let copyLockedRoomDimensions = { ...appModel.activeCorePlan.lockedRoomDimensions };

    let isHorizontal = this.soIntersectedStretchControl.userData.horizontal;
    let isVertical = this.soIntersectedStretchControl.userData.vertical;

    if (isHorizontal && roomId) {
      (copyLockedRoomDimensions = {
        ...copyLockedRoomDimensions,
        [roomId]: {
          x: false,
          y: copyLockedRoomDimensions && copyLockedRoomDimensions[roomId] ? copyLockedRoomDimensions[roomId].y : false,
        },
      }),
        this.corePlan.setLockedRoomDimensions(copyLockedRoomDimensions);
    }
    if (isVertical && roomId) {
      copyLockedRoomDimensions = {
        ...copyLockedRoomDimensions,
        [roomId]: {
          x: copyLockedRoomDimensions && copyLockedRoomDimensions[roomId] ? copyLockedRoomDimensions[roomId].x : false,
          y: false,
        },
      };
      this.corePlan.setLockedRoomDimensions(copyLockedRoomDimensions);
    }
    if (MathUtils.areNumbersEqual(Math.abs(soRoom.rotation.z), Math.PI / 2)) {
      isHorizontal = this.soIntersectedStretchControl.userData.vertical;
      isVertical = this.soIntersectedStretchControl.userData.horizontal;
    }

    if (isHorizontal) {
      const sign = directionPoint.x > center.x ? 1 : -1;
      const limitDistance = SceneUtils.collectStretchedReferenceLines(soRoom, sign * Number.MAX_VALUE, Direction.Horizontal).reduce(
        (sum, ref) => sum + ref.stretchedDistance,
        0
      );
      const distance = SceneUtils.collectStretchedReferenceLines(soRoom, sign * delta.x, Direction.Horizontal).reduce(
        (sum, ref) => sum + ref.stretchedDistance,
        0
      );

      soRoom.position.x += sign * distance;
      soRoom.updateMatrixWorld();

      const snapData = this.roomSnapTool.checkSnappingWhileMovingStretchTriangles(soRoom, Direction.Horizontal, sign);

      soRoom.position.x -= sign * distance;
      soRoom.updateMatrixWorld();

      const summaryDistance = distance + sign * snapData.distance;

      let stretch: number;
      if (sign * summaryDistance < sign * limitDistance) {
        stretch = summaryDistance;
      } else {
        stretch = sign * delta.x;
      }

      if (!MathUtils.areNumbersEqual(stretch, 0)) {
        const stretchedDistance = SceneUtils.stretchRoom(soRoom, stretch, Direction.Horizontal);
        soRoom.position.x += (sign * stretchedDistance) / 2;
      }
    }

    if (isVertical) {
      const sign = directionPoint.y > center.y ? 1 : -1;
      const limitDistance = SceneUtils.collectStretchedReferenceLines(soRoom, sign * Number.MAX_VALUE, Direction.Vertical).reduce(
        (sum, ref) => sum + ref.stretchedDistance,
        0
      );
      const distance = SceneUtils.collectStretchedReferenceLines(soRoom, sign * delta.y, Direction.Vertical).reduce(
        (sum, ref) => sum + ref.stretchedDistance,
        0
      );

      soRoom.position.y += sign * distance;
      soRoom.updateMatrixWorld();

      const snapData = this.roomSnapTool.checkSnappingWhileMovingStretchTriangles(soRoom, Direction.Vertical, sign);

      soRoom.position.y -= sign * distance;
      soRoom.updateMatrixWorld();

      const summaryDistance = distance + sign * snapData.distance;

      let stretch: number;
      if (sign * summaryDistance < sign * limitDistance) {
        stretch = summaryDistance;
      } else {
        stretch = sign * delta.y;
      }

      if (!MathUtils.areNumbersEqual(stretch, 0)) {
        const stretchedDistance = SceneUtils.stretchRoom(soRoom, stretch, Direction.Vertical);
        soRoom.position.y += (sign * stretchedDistance) / 2;
      }
    }

    soRoom.updateMatrixWorld();

    const primaryBB = soRoom.getSoRoomBoundingBoxByModelLines();
    this.roomSnapTool.addColinearSnapLines(soRoom, primaryBB);
  }

  // private moveStretchPoints(): void {
  //   if (!this.soIntersectedStretchControl) {
  //     return;
  //   }

  //   const points = this.soIntersectedStretchControl as THREE.Points;
  //   const pointGeometry = points.geometry as THREE.BufferGeometry;
  //   const pointsPositions = GeometryUtils.getPointsPositions(pointGeometry);
  //   const originalDragPoint = GeometryUtils.getLineMidPoint2(pointsPositions[0], pointsPositions[1]);

  //   function checkLineType(vector1, vector2) {
  //     const { x: x1, y: y1 } = vector1;
  //     const { x: x2, y: y2 } = vector2;
  //     const tolerance = 0.00001;

  //     if (Math.abs(y1 - y2) > tolerance) {
  //       return "horizontal";
  //     } else if (Math.abs(x1 - x2) > tolerance) {
  //       return "vertical";
  //     }
  //   }
  //   let copyLockedRoomDimensions = { ...this.corePlan.lockedRoomDimensions };
  //   const orientation = checkLineType(pointsPositions[0], pointsPositions[1]);
  //   const roomId = appModel.activeCorePlan?.getRooms(appModel.selectedRoomsIds)[0].id;

  //   if (orientation === "horizontal" && roomId) {
  //     (copyLockedRoomDimensions = {
  //       ...copyLockedRoomDimensions,
  //       [roomId]: {
  //         x: false,
  //         y: copyLockedRoomDimensions && copyLockedRoomDimensions[roomId] ? copyLockedRoomDimensions[roomId].y : false,
  //       },
  //     }),
  //       this.corePlan.setLockedRoomDimensions(copyLockedRoomDimensions);
  //   }
  //   if (orientation === "vertical" && roomId) {
  //     copyLockedRoomDimensions = {
  //       ...copyLockedRoomDimensions,
  //       [roomId]: {
  //         x: copyLockedRoomDimensions && copyLockedRoomDimensions[roomId] ? copyLockedRoomDimensions[roomId].x : false,
  //         y: false,
  //       },
  //     };
  //     this.corePlan.setLockedRoomDimensions(copyLockedRoomDimensions);
  //   }

  //   let dragLine: THREE.Line3;
  //   for (const child of points.parent.children) {
  //     if (child.userData.type === RoomEntityType.ModelLine) {
  //       dragLine = GeometryUtils.getLocalLine3(child);
  //       const p = new THREE.Vector3();
  //       const distanceToLine = dragLine.closestPointToPoint(originalDragPoint, false, p).distanceTo(originalDragPoint);
  //       if (MathUtils.areNumbersEqual(distanceToLine, 0, 0.01)) {
  //         break;
  //       }
  //     }
  //   }
  //   const pointer = points.worldToLocal(this.intersectionPoint.clone());

  //   const closestPoint = new THREE.Vector3();
  //   dragLine.closestPointToPoint(pointer, false, closestPoint);

  //   let distance = closestPoint.distanceTo(originalDragPoint);
  //   const change = closestPoint.clone().sub(originalDragPoint);

  //   const max = (points.userData.max || 0) / 2;
  //   if (distance > max) {
  //     const diff = distance - max;
  //     distance = max;
  //     const changeCorrection = change.clone().normalize().multiplyScalar(diff);
  //     change.sub(changeCorrection);
  //     closestPoint.sub(changeCorrection);
  //   }
  //   const collapseDistance = this.getRaycasterRecommendedPrecision() * 30.0 * UnitsUtils.getConversionFactor();
  //   if (distance < collapseDistance) {
  //     const diff = distance - 0;
  //     distance = 0;
  //     const changeCorrection = change.clone().normalize().multiplyScalar(diff);
  //     change.sub(changeCorrection);
  //     closestPoint.sub(changeCorrection);
  //   }

  //   pointsPositions[0] = closestPoint;
  //   pointsPositions[1] = originalDragPoint.clone().sub(change);
  //   pointGeometry.setFromPoints(pointsPositions);
  //   pointGeometry.computeBoundingSphere();

  //   this.updateSecondRefPair(points, change);

  //   const referenceLine = SceneUtils.collectReferenceLines(points.parent)[points.userData.id];

  //   SceneUtils.stretchRoomByReferenceLine(points.parent, referenceLine, 2 * distance - referenceLine.currentStretch, true);
  // }

  // private updateSecondRefPair(points: THREE.Points, change: THREE.Vector3): void {
  //   const secondPair = points.parent.children.find(child => child.uuid !== points.uuid && child.userData.id === points.userData.id) as THREE.Points;
  //   const secondPairGeometry = secondPair.geometry as THREE.BufferGeometry;
  //   const pointsPositions = GeometryUtils.getPointsPositions(secondPairGeometry);
  //   const originalDragPoint = GeometryUtils.getLineMidPoint2(pointsPositions[0], pointsPositions[1]);

  //   let dragLine: THREE.Line3;
  //   for (const child of points.parent.children) {
  //     if (child instanceof soBoundaryLine) {
  //       dragLine = GeometryUtils.getLocalLine3(child.line);
  //       // dragLine = GeometryUtils.getLocalLine3(child);
  //       const distanceToLine = dragLine.closestPointToPoint(originalDragPoint, false, new THREE.Vector3()).distanceTo(originalDragPoint);
  //       if (MathUtils.areNumbersEqual(distanceToLine, 0, 0.01)) {
  //         break;
  //       }
  //     }
  //   }

  //   pointsPositions[0] = originalDragPoint.clone().add(change);
  //   pointsPositions[1] = originalDragPoint.clone().sub(change);
  //   secondPairGeometry.setFromPoints(pointsPositions);
  //   secondPairGeometry.computeBoundingSphere();
  // }

  private finishObjectInsertion() {
    appModel.activeFloor.addRoom(this.draggedRoom);

    this.soDraggedRoom.updateMatrixWorld();

    const rooms = this.getActiveSoFloor().soRooms;
    if (rooms.length == 1) {
      this.zoomToFit();
    }
    this.roomSnapTool.showSnappingMessages();
    // if (this.soDraggedRoom.userData.isIntersected) {
    //   showToastMessage(MessageKindsEnum.Error, ROOM_OVERLAP_MESSAGE, { autoClose: 700 });
    // }

    this.corePlan.setIsCostOutdated(true);
    this.floorDimensionTool.setRooms(this.getActiveSoFloor().soRooms, true);
    GraphAnalysisUtils.regenerateRoomsWalls(this, this.getActiveSoFloor());
    this.updateSegmentsCache();
    this.updateRoofContour();
    this.soDraggedRoom.stretchToFit(this.soFloorsRoot, this.roomSnapTool);
    this.getActiveSoFloor().checkRoomsOverlapping(true);
    this.handleDraggedRoomReplaceability(this.soDraggedRoom);
    this.openingTool.alignRoomsOpenings(appModel.activeCorePlan.floors, this.getSoFloorsRoot());
    this.checkBlockedDoors();
    this.validationTool.performPlmValidation(this.getActiveSoFloor());
    this.updateRoomsProperties([this.soDraggedRoom.soId]);
    this.updateCladding();
    this.validateCladding();
    this.shortWallSegmentsTool.validateShortSegments();
  }

  private performRoomSelection(isSingleMode: boolean, isContextMenuVersion: boolean = false): void {
    const soRoom = this.getIntersectedRoom();
    const alreadySelected = soRoom && appModel.selectedRoomsIds.includes(soRoom.soId);

    if (isSingleMode) {
      if (!soRoom) {
        this.selectAllRooms(false);
      } else if (!alreadySelected || (!isContextMenuVersion && appModel.selectedRoomsIds.length > 1)) {
        this.selectAllRooms(false);
        this.selectRoom(soRoom, true);
      }
    } else if (soRoom) {
      this.selectRoom(soRoom, !alreadySelected || isContextMenuVersion);
    }
  }

  private getIntersectedRoom(): soRoom2D {
    let intersectedRoom = null;

    const intersectedSelectedRoom = this.getActiveFloorSoRooms().find(soRoom => {
      const roomBbox = RoomUtils.getSoRoomBoundingBox(soRoom);
      const isIntersected = this.raycaster.ray.intersectsBox(roomBbox);
      if (isIntersected) {
        if (!appModel.selectedRoomsIds.includes(soRoom.soId)) {
          intersectedRoom = intersectedRoom || soRoom;
        } else {
          return true;
        }
      }

      return false;
    });

    return intersectedSelectedRoom || intersectedRoom;
  }

  private replaceReplaceableRoom(): void {
    this.replaceRoom(this.soReplaceableRooms.oldRoom, this.soReplaceableRooms.newRoom);
    this.soReplaceableRooms = null;
  }

  private copySelectedRooms(): void {
    this.soCopiedRooms.length = 0;
    this.copiedRooms.length = 0;

    const bb = new THREE.Box3();
    this.getCorePlanSelectedSoRooms().forEach(room => bb.expandByObject(room));
    this.copiedRoomsStartPosition = new THREE.Vector3(bb.min.x, bb.max.y, 0);

    this.getCorePlanSelectedSoRooms().forEach(room => {
      const copiedRoom = GeometryUtils.soRoomDeepClone(room);
      copiedRoom.soId = room.soId;
      copiedRoom.userData.originalRoomId = room.soId;
      this.soCopiedRooms.push(copiedRoom);
      this.copiedRooms.push(appModel.activeCorePlan.getRoom(room.soId));
    });
  }

  private pasteRooms(position?: THREE.Vector3): { pastedRoomId: string; copiedRoomId: string; position: THREE.Vector3 }[] {
    if (!this.soCopiedRooms.length) {
      return;
    }
    const pastedRoomsIds = [];
    this.selectAllRooms(false);
    const offsetUnit = 5 * UnitsUtils.getConversionFactor();
    const offset = position ? position.clone().sub(this.copiedRoomsStartPosition) : new THREE.Vector3(offsetUnit, offsetUnit, 0);

    this.soCopiedRooms.forEach((copiedRoom, index) => {
      const soRoom = GeometryUtils.soRoomDeepClone(copiedRoom);

      const room = this.copiedRooms[index].clone();
      appModel.activeFloor.addRoom(room);

      soRoom.userData.id = room.id;
      soRoom.soId = room.id;
      soRoom.name = room.name;
      if (offset) {
        soRoom.position.add(offset);
        soRoom.updateMatrixWorld();
      }

      this.getActiveSoFloor().add(soRoom);
      this.getActiveSoFloor().addRoom(soRoom);
      this.selectRoom(soRoom, true);

      this.populateRoomProperties(room, soRoom);

      pastedRoomsIds.push({
        pastedRoomId: soRoom.soId,
        copiedRoomId: copiedRoom.userData.originalRoomId,
        position: soRoom.position.clone(),
      });
    });

    this.floorDimensionTool.updateSize(true);
    this.selectedRoomsDimensionTool.updateSize();
    GraphAnalysisUtils.regenerateRoomsWalls(this, this.getActiveSoFloor());
    this.updateSegmentsCache();
    this.updateRoofContour();
    this.getActiveSoFloor().checkRoomsOverlapping(true);
    this.corePlan.setIsCostOutdated(true);
    this.openingTool.alignRoomsOpenings(appModel.activeCorePlan.floors, this.getSoFloorsRoot());
    this.checkBlockedDoors();
    this.updateCladding();
    this.validateCladding();
    this.performValidation();
    return pastedRoomsIds;
  }

  private pasteCopiedRoomsInMousePosition(): void {
    const pastedRooms = this.pasteRooms(this.intersectionPoint);
    this.commandManager.add(new MultiCommand(pastedRooms.map(room => new PasteRoomCommand(room.pastedRoomId, room.copiedRoomId, room.position))));
  }

  private deleteSelectedRooms(): void {
    if (appModel.selectedRoomsIds.length) {
      this.commandManager.apply(new MultiCommand(this.getCorePlanSelectedSoRooms().map(soRoom => new SoDeleteRoomCommand(soRoom))));
      this.selectedRoomsDimensionTool.deleteInput();

      const roomId = appModel.activeCorePlan?.getRooms(appModel.selectedRoomsIds)[0]?.id;
      if (roomId) {
        const copyLockedRoomDimensions = { ...this.corePlan.lockedRoomDimensions };

        delete copyLockedRoomDimensions[roomId];
        this.corePlan.setLockedRoomDimensions(copyLockedRoomDimensions);
      }
    }
  }

  public deleteRoomByRoomId(roomId: string): void {
    this.commandManager.apply(new SoDeleteRoomCommand(this.getCorePlanSoRoom(roomId)));
  }

  private updateSingleFloorVisibility(above: boolean, visible: boolean) {
    const floorModel = this.corePlan.floors.find(f => f.index === appModel.activeFloor.index + (above ? 1 : -1));

    if (!floorModel) {
      return;
    }

    const floorToChange = this.soFloorsRoot.soFloors.find(x => x.soId == floorModel.id);
    floorToChange.visible = visible;

    SceneUtils.setItemVisibility(floorToChange, false);
    SceneUtils.setModelLinesColorForFloor(floorToChange, visible ? INACTIVE_MODEL_LINE_COLOR : MODEL_LINE_COLOR);
    SceneUtils.displayFloorContour(this.soRoot, floorToChange, visible);
  }

  private checkLotline(soRooms: soRoom2D[]): void {
    if (!appModel.activeFloor.lotLine || appModel.activeFloor.lotLine.offsetVertices.length === 0) {
      return;
    }
    const points = appModel.activeFloor.lotLine.offsetVertices.map(v => [v.point.x, v.point.y]);
    points.push(points[0]);
    const lotlinePoints = appModel.activeFloor.lotLine.vertices.map(v => [v.point.x, v.point.y]);
    soRooms.forEach(soRoom => {
      const roomObj = appModel.activeFloor.rooms.find(room => room.id === soRoom.soId);
      if (roomObj) {
        const bb = RoomUtils.getSoRoomBoundingBox(soRoom);
        //check outdoor room
        if (appModel.getRoomCategory(appModel.getRoomType(roomObj.roomTypeId).roomCategoryId).isOutdoor) {
          if (lineclip(lotlinePoints, [bb.min.x, bb.min.y, bb.max.x, bb.max.y]).length > 0) {
            return showToastMessage(MessageKindsEnum.Error, OUT_OF_LOTLINE_ERROR_MESSAGE, { autoClose: MESSAGE_DURATION });
          }
        }
      }
    });
  }

  private showRoomTooltip(e: MouseEvent) {
    if (appModel.tooltipOptions.show) {
      appModel.setTooltipOptions({ show: false });
    }

    if (this.roomTooltipTimer) {
      clearTimeout(this.roomTooltipTimer);
    }

    if (appModel.sceneEditorMode !== SceneEditorMode.Room) {
      return;
    }

    this.roomTooltipTimer = setTimeout(() => {
      const intersectedSoRoom = this.getIntersectedRoom();
      if (intersectedSoRoom) {
        const room = appModel.activeFloor.rooms.find(room => room.id === intersectedSoRoom.soId);
        if (room) {
          const roomType = appModel.getRoomType(room.roomTypeId);
          appModel.setTooltipOptions({
            show: true,
            top: e.clientY,
            left: e.clientX,
            text: roomType.name + (roomType.isMarkHidden ? "(Obsolete)" : ""),
          });
        }
      }
      this.roomTooltipTimer = null;
    }, TOOLTIP_DELAY);
  }

  private unhighlightRoomBlockedDoors(soRooms: soRoom2D[]): void {
    const soFloorRooms = this.getActiveFloorSoRooms();

    soRooms.forEach(soRoom => {
      if (soRoom.userData.isBlockingDoor) {
        const blockedRoom = soFloorRooms.find(r => r.soId === soRoom.userData.isBlockingDoor.roomId);
        const blockedDoor = blockedRoom.children.find(child => child.userData.id === soRoom.userData.isBlockingDoor.doorId);
        if (!blockedRoom.userData.isIntersected) {
          SceneUtils.unhighlightBlockedOpening(blockedDoor);
        }
        SceneUtils.unhighlightIntersectedRoom(soRoom, true);
        delete soRoom.userData.isBlockingDoor;
      }
      soRoom.children
        .filter(child => child.userData.type === RoomEntityType.Door && child.userData.isBlockedBy)
        .forEach(door => {
          if (!door.parent.userData.isIntersected) {
            SceneUtils.unhighlightBlockedOpening(door);
          }
          const blockingRoom = soFloorRooms.find(r => r.soId === door.userData.isBlockedBy);
          SceneUtils.unhighlightIntersectedRoom(blockingRoom, true);
          delete blockingRoom.userData.isBlockingDoor;
        });
    });
  }

  /**
   * Handles snapping of rooms by finding intersecting rooms and performing the necessary snap events.
   *
   * @param {soRoom2D[]} rooms - The rooms that were snapped.
   */
  handleSnapping(rooms) {
    const snappedRooms = new Set(rooms);
    const otherRooms = this.getActiveFloorSoRooms().filter(soRoom => !snappedRooms.has(soRoom));

    snappedRooms.forEach(snappedRoom => {
      const intersectingRooms = RoomUtils.getIntersectingSoRooms(snappedRoom, otherRooms);

      if (intersectingRooms.length > 0) {
        this.createAndPerformSnapEvents(snappedRoom, intersectingRooms);
      }
    });
  }

  /**
   * Creates and performs snap events for intersecting rooms.
   *
   * @param {soRoom2D} snappedRoom - The room that was snapped.
   * @param {soRoom2D[]} intersectingRooms - The intersecting rooms.
   */
  createAndPerformSnapEvents(snappedRoom, intersectingRooms) {
    intersectingRooms
      .map(room => new SnapEvent(snappedRoom, room))
      .filter(event => !(event.EventKey in this.snapEvents))
      .forEach(snap => {
        if (snap.primaryRoomSide) {
          RoomUtils.performSnap(snap);
          this.snapEvents[snap.EventKey] = snap;
        }
      });
  }

  /**
   * Cleans up snap events by removing those that are no longer valid.
   */
  cleanupSnapEvents() {
    Object.values(this.snapEvents).forEach(snap => {
      if (!snap.StillSnappedCheck) {
        if (snap?.primaryRoom) {
          (snap.primaryRoom as soRoom2D).moveRoomAwayFromSideByWallType(snap.primaryRoomSide, FuncCode.EXT_2X4);
        }
        delete this.snapEvents[snap.EventKey];
      }
    });
  }
}
