import * as THREE from "three";
import { IReactionDisposer, reaction } from "mobx";
import { appModel } from "../../../models/AppModel";
import { DDL_WALL_SEGMENT_COLOR, EXT_WALL_SEGMENT_COLOR, GRG_WALL_SEGMENT_COLOR, INT_WALL_SEGMENT_COLOR, PLM_WALL_SEGMENT_COLOR } from "../../consts";
import SceneManager from "../../managers/SceneManager/SceneManager";
import { MergeSegmentsMode } from "../../models/SegmentsMergeMode";
import { IWallTypesValidationResult } from "../../models/ValidationResult";
import { Segment } from "../../models/segments/Segment";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import SceneUtils from "../../utils/SceneUtils";
import SegmentsUtils from "../../utils/SegmentsUtils";
import UnitsUtils from "../../utils/UnitsUtils";
import { GraphAnalysisUtils } from "../../utils/GraphAnalysisUtils";
import GravityLoadsValidationTool from "./GravityLoadsValidationTool";
import { IValidationTool } from "./IValidationTool";
import { RoomEntityType } from "../../../models/RoomEntityType";
import { settings } from "../../../entities/settings";
import MathUtils from "../../utils/MathUtils";
import RoomUtils from "../../utils/RoomUtils";
import VectorUtils from "../../utils/GeometryUtils/VectorUtils";
import { WallType } from "../../../entities/catalogSettings/types";
import { IManager } from "../../managers/IManager";
import { GraphUtils } from "../../utils/GraphUtils";
import { soRoom2D } from "../../models/SceneObjects/Room/soRoom2D";
import { Graph } from "../../models/graph/Graph";
import { Edge } from "../../models/graph/Edge";
import { Vertex } from "../../models/graph/Vertex";

export interface PlmWall {
  segment: Segment;
  hostingWall: Segment;
}

export default class SoWallTypesValidationTool implements IValidationTool {
  private validationResult: Map<string, IWallTypesValidationResult> = new Map<string, IWallTypesValidationResult>();
  private ddlWalls: Map<string, Segment[]> = new Map();
  private grgWalls: Map<string, Segment[]> = new Map();
  private intWalls: Map<string, Segment[]> = new Map();
  private plmWalls: Map<string, PlmWall[]> = new Map();
  private extWalls: Map<string, Segment[]> = new Map();

  private graph: Graph<Segment>;
  private keyToEdges: Map<string, Edge<Segment>[]> = new Map();
  private garageBoxes: Map<string, THREE.Box3> = new Map();
  private shaftBoxes: Map<string, THREE.Box3> = new Map();
  private stairsBoxes: Map<string, THREE.Box3> = new Map();
  private stairsWalls: Map<string, { wall: THREE.Object3D; line: THREE.Line3; isHorizontal: boolean }[]> = new Map();
  private stairsSegments: Segment[] = [];
  private possibleStairsDdl: Map<string, { vertex: Vertex; ddl: Segment[] }[]> = new Map();
  private roomManager: SceneManager;
  constructor(
    roomManager: SceneManager,
    private gravityLoadsValidationTool: GravityLoadsValidationTool
  ) {
    this.roomManager = roomManager;
  }
  public performValidation(): void {
    this.resetResult();
    const { garageSegmentsMap, ddlSegmentsMap, externalSegmentsMap, internalSegmentsMap, plmWallsMap } = this.performExtendedValidation(false);
    this.ddlWalls = ddlSegmentsMap;
    this.grgWalls = garageSegmentsMap;
    this.extWalls = externalSegmentsMap;
    this.plmWalls = plmWallsMap;

    appModel.activeCorePlan.floors.forEach(floor => {
      const specialSegments = [...this.ddlWalls.get(floor.id), ...this.grgWalls.get(floor.id)];
      const intSegments = internalSegmentsMap
        .get(floor.id)
        .filter(s => s.hasWall)
        .flatMap(wall => SegmentsUtils.getNotOverlappedSegmentParts(wall, specialSegments));
      this.intWalls.set(floor.id, intSegments);
    });
    this.setValidationResult();
  }

  public visualizeValidationResult(container: THREE.Group, floorId: string): void {
    const thickness = UnitsUtils.getSyntheticWallHalfSize();
    this.ddlWalls.get(floorId)?.forEach(r => {
      container.add(SceneUtils.createSegmentPlane(r, thickness, DDL_WALL_SEGMENT_COLOR));
    });
    this.grgWalls.get(floorId)?.forEach(r => {
      container.add(SceneUtils.createSegmentPlane(r, thickness, GRG_WALL_SEGMENT_COLOR));
    });
    this.intWalls.get(floorId)?.forEach(r => {
      container.add(SceneUtils.createSegmentPlane(r, thickness, INT_WALL_SEGMENT_COLOR));
    });
    this.extWalls.get(floorId)?.forEach(r => {
      container.add(SceneUtils.createSegmentPlane(r, thickness, EXT_WALL_SEGMENT_COLOR));
    });
    this.plmWalls.get(floorId)?.forEach(r => {
      container.add(SceneUtils.createSegmentPlane(r.segment, thickness, PLM_WALL_SEGMENT_COLOR));
    });
  }

  public getFloorValidationResult(floorId: string): IWallTypesValidationResult {
    return this.validationResult.get(floorId);
  }

  public resetResult() {
    this.ddlWalls.clear();
    this.grgWalls.clear();
    this.intWalls.clear();
    this.extWalls.clear();
    this.plmWalls.clear();
    this.validationResult.clear();

    this.graph?.clear();
    this.keyToEdges.clear();
    this.garageBoxes.clear();
    this.shaftBoxes.clear();
    this.stairsBoxes.clear();
    this.stairsWalls.clear();
    this.stairsSegments.length = 0;
    this.possibleStairsDdl.clear();
  }

  public getExtendedValidationResult() {
    const { garageSegmentsMap, ddlSegmentsMap, shaftSegmentsMap } = this.performExtendedValidation();
    const noWallSegments = new Map<string, Segment[]>();
    const wallSegments = new Map<string, Segment[]>();
    appModel.activeCorePlan.floors.forEach(f => {
      const soRooms = this.roomManager.getSoFloor(f.id).soRooms;
      //   const soRooms = this.roomManager.getSoFloor(f.id).children;
      const { externalSegments, internalSegments } = GraphAnalysisUtils.collectSegments(soRooms, MergeSegmentsMode.SameRoom);
      const { wall, noWall } = internalSegments.reduce(
        (acc, seg) => {
          seg.hasWall ? acc.wall.push(seg) : acc.noWall.push(seg);
          return acc;
        },
        { wall: [], noWall: [] }
      );
      const outdoorSoRooms = f.rooms
        .filter(r => !appModel.getRoomType(r.roomTypeId).attributes.indoor)
        .map(room => soRooms.find(r => r.userData.id === room.id));
      const outdoorSegments = GraphAnalysisUtils.collectSegments(outdoorSoRooms, MergeSegmentsMode.SameRoom, false);
      const outdoorExternalNoWall = [];
      outdoorSegments.internalSegments.forEach(ois => {
        (ois.hasWall ? wall : noWall).push(ois);
      });
      outdoorSegments.externalSegments.forEach(oes => {
        (oes.hasWall ? wall : outdoorExternalNoWall).push(oes);
      });
      const notOverlappedInternalNoWall = [];
      outdoorExternalNoWall.forEach(noWall => {
        const notOverlapped = SegmentsUtils.getNotOverlappedSegmentParts(noWall, externalSegments);
        notOverlappedInternalNoWall.push(...notOverlapped);
      });
      noWallSegments.set(f.id, noWall.concat(notOverlappedInternalNoWall));
      wallSegments.set(f.id, wall.concat(externalSegments));

      // Now loop through wallSegments and check if they exist in garageSegmentsMap, ddlSegmentsMap, or shaftSegmentsMap
      wallSegments.get(f.id)?.forEach(wallSeg => {
        // Check against only segments in the same f.id in each map
        this.checkAndAddClassification(wallSeg, garageSegmentsMap.get(f.id), WallType.GRG); // Add classification if in garageSegmentsMap
        this.checkAndAddClassification(wallSeg, shaftSegmentsMap.get(f.id), WallType.SFT); // Add classification if in shaftSegmentsMap
      });
    });
    return {
      noWalls: noWallSegments,
      walls: wallSegments,
      ddlSegments: ddlSegmentsMap,
      garageSegments: garageSegmentsMap,
    };
  }

  private checkAndAddClassification(wallSeg: Segment, segmentArray: Segment[] | undefined, classification: WallType): void {
    if (!segmentArray) return; // Skip if there's no matching segmentArray for f.id

    segmentArray.forEach(seg => {
      if (wallSeg.overlapsSegment(seg)) {
        // Add classification to wallSeg if it overlaps a segment in the map
        wallSeg.addClassification(classification);
      }
    });
  }

  public performExtendedValidation(revertSegments = true) {
    const ddlSegmentsMap = new Map<string, Segment[]>();
    const garageSegmentsMap = new Map<string, Segment[]>();
    const shaftSegmentsMap = new Map<string, Segment[]>();
    const plmWallsMap = new Map<string, PlmWall[]>();
    const stairsSegmentsMap = new Map<string, Segment[]>();
    const externalSegmentsMap = new Map<string, Segment[]>();
    const internalSegmentsMap = new Map<string, Segment[]>();
    const allSegments = new Map<string, Segment[]>();
    const soFloors = [...appModel.activeCorePlan.floors].sort((a, b) => a.index - b.index).map(f => this.roomManager.getSoFloor(f.id));

    soFloors.forEach(soFloor => {
      const floorId = soFloor.soId;

      const plmWalls = this.collectPlmWalls(soFloor.soRooms);
      const plmHostingWalls = plmWalls.map(plm => plm.hostingWall);
      const { externalSegments, internalSegments, ddlSegments, grgSegments, stairsSegments, sftSegments } = this.getStrDdlSegments(soFloor.soRooms);

      // Ddl wall is redundant if plm wall is present.
      ddlSegmentsMap.set(
        floorId,
        ddlSegments.flatMap(s => SegmentsUtils.getNotOverlappedSegmentParts(s, plmHostingWalls))
      );

      garageSegmentsMap.set(floorId, grgSegments);
      shaftSegmentsMap.set(floorId, sftSegments);
      plmWallsMap.set(floorId, plmWalls);
      stairsSegmentsMap.set(floorId, stairsSegments);
      externalSegmentsMap.set(floorId, externalSegments);
      internalSegmentsMap.set(floorId, internalSegments);
      allSegments.set(
        floorId,
        [...externalSegments, ...internalSegments].map(s => s.clone())
      );
    });

    this.gravityLoadsValidationTool.stackedWallsCheck(externalSegmentsMap, internalSegmentsMap, true);

    let prevFloorStairsDdl: Segment[] = garageSegmentsMap.get(soFloors[0].userData.id); // Special case for the ground floor.
    for (const floor of soFloors) {
      const floorPossibleStairsDdl = this.possibleStairsDdl.get(floor.userData.id);
      const floorId = floor.userData.id;
      // Get stairs segment that lies on the ddl staircase segments from the previous floor.
      const stairsDdl = stairsSegmentsMap.get(floorId).filter(stairWall => prevFloorStairsDdl.some(prevStairDdl => prevStairDdl.overlapsSegment(stairWall)));
      // Stairs segments that became ddl.
      const stairsDdl2 = stairsSegmentsMap
        .get(floorId)
        .filter(stairWall =>
          ddlSegmentsMap
            .get(floorId)
            .some(prevStairDdl => prevStairDdl.overlapsSegment(stairWall) && prevStairDdl.extras.revertOnExport === stairWall.extras.revertOnExport)
        );
      prevFloorStairsDdl = [...stairsDdl, ...stairsDdl2];
      const extendedStairsDdl = [];
      prevFloorStairsDdl.forEach(ddl => {
        const isDdlHorizontal = ddl.isHorizontal();
        const v1DdlExtension = floorPossibleStairsDdl.find(
          p =>
            isDdlHorizontal === p.ddl[0].isHorizontal() &&
            MathUtils.areNumbersEqual(p.vertex.point.x, ddl.start.x) &&
            MathUtils.areNumbersEqual(p.vertex.point.y, ddl.start.y)
        );
        const v2DdlExtension = floorPossibleStairsDdl.find(
          p =>
            isDdlHorizontal === p.ddl[0].isHorizontal() &&
            MathUtils.areNumbersEqual(p.vertex.point.x, ddl.end.x) &&
            MathUtils.areNumbersEqual(p.vertex.point.y, ddl.end.y)
        );
        if (v1DdlExtension) {
          extendedStairsDdl.push(...v1DdlExtension.ddl);
        }
        if (v2DdlExtension) {
          extendedStairsDdl.push(...v2DdlExtension.ddl);
        }
      });
      const notOverlapped = stairsDdl.flatMap(wall => SegmentsUtils.getNotOverlappedSegmentParts(wall, ddlSegmentsMap.get(floorId))); // Get only part that is not already ddl.
      ddlSegmentsMap.get(floorId).push(...notOverlapped);
      const notOverlapped2 = extendedStairsDdl.flatMap(wall => SegmentsUtils.getNotOverlappedSegmentParts(wall, ddlSegmentsMap.get(floorId))); // Get only part that is not already ddl.
      ddlSegmentsMap.get(floorId).push(...notOverlapped2);
    }

    for (const floor of soFloors) {
      const floorId = floor.userData.id;
      const floorSegments = allSegments.get(floorId);
      // Get the part of the ddl that does not belong to the garage segments. Eliminate any overlap between the ddl segments and the garage segments.
      const notOverlappedDdl = ddlSegmentsMap.get(floorId).flatMap(wall => SegmentsUtils.getNotOverlappedSegmentParts(wall, garageSegmentsMap.get(floorId)));
      ddlSegmentsMap.set(floorId, this.mergeDdlSegments(notOverlappedDdl, floorSegments));
      garageSegmentsMap.set(floorId, this.mergeDdlSegments(garageSegmentsMap.get(floorId), floorSegments));
      if (revertSegments) {
        // Revert ddl and garage segments that were created starting from external segment and have to be oriented.
        [...ddlSegmentsMap.get(floorId), ...garageSegmentsMap.get(floorId)].forEach(s => {
          if (s.extras.revertOnExport) {
            s.revert();
          }
        });
      }
    }

    return {
      garageSegmentsMap,
      ddlSegmentsMap,
      shaftSegmentsMap,
      externalSegmentsMap,
      internalSegmentsMap,
      plmWallsMap,
    };
  }

  private collectPlmWalls(soRooms: soRoom2D[]): PlmWall[] {
    const plmWalls: PlmWall[] = [];

    soRooms.forEach(soRoom => {
      const modelLines = Object.values(RoomUtils.getSoRoomLinesByType(soRoom, RoomEntityType.ModelLine));

      const plmSegments = SceneUtils.getRoomPLMWalls(soRoom).map(wall =>
        Segment.fromLine3(GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox2D(wall)))
      );

      plmSegments.forEach(plmSegment => {
        const plmDelta = plmSegment.delta();
        const pointOnPlmSegment = VectorUtils.Vector2ToVector3(plmSegment.start);
        let hostingWall: THREE.Line3 = null;
        let minDistance: number = Number.POSITIVE_INFINITY;

        modelLines.forEach(modelLineSegment => {
          const modelLineDelta = modelLineSegment.delta(new THREE.Vector3());
          if (!MathUtils.areNumbersEqual(plmDelta.cross(new THREE.Vector2(modelLineDelta.x, modelLineDelta.y)), 0)) {
            return;
          }

          const projectedPoint = modelLineSegment.closestPointToPoint(pointOnPlmSegment, false, new THREE.Vector3());
          const distance = projectedPoint.distanceTo(pointOnPlmSegment);

          if (minDistance > distance) {
            hostingWall = modelLineSegment;
            minDistance = distance;
          }
        });

        plmWalls.push({ segment: plmSegment, hostingWall: Segment.fromLine3(hostingWall) });
      });
    });

    return plmWalls;
  }

  private setValidationResult() {
    appModel.activeCorePlan.floors.forEach(floor => {
      this.validationResult.set(floor.id, {
        presentSegmentTypes: [
          ...(this.ddlWalls.get(floor.id)?.length > 0 ? ["Ddl"] : []),
          ...(this.grgWalls.get(floor.id)?.length > 0 ? ["Grg"] : []),
          ...(this.intWalls.get(floor.id)?.length > 0 ? ["Int"] : []),
          ...(this.plmWalls.get(floor.id)?.length > 0 ? ["Plm"] : []),
          ...(this.extWalls.get(floor.id)?.length > 0 ? ["Ext"] : []),
        ],
      });
    });
  }

  public mergeDdlSegments(ddlSegments: Segment[], walls: Segment[]): Segment[] {
    let segments: Segment[] = [];
    let segmentsToRevert: Segment[] = [];
    ddlSegments.forEach(segment => {
      if (segment.extras.revertOnExport) {
        segmentsToRevert.push(segment);
      } else {
        segments.push(segment);
      }
    });

    segments = GraphAnalysisUtils.mergeSegments(segments, MergeSegmentsMode.All);
    segmentsToRevert = GraphAnalysisUtils.mergeSegments(segmentsToRevert, MergeSegmentsMode.All);

    return SegmentsUtils.splitSegments([...segments, ...segmentsToRevert], walls);
  }

  private getStrDdlSegments(soRooms: soRoom2D[]): {
    externalSegments: Segment[];
    internalSegments: Segment[];
    ddlSegments: Segment[];
    grgSegments: Segment[];
    stairsSegments: Segment[];
    sftSegments: Segment[];
  } {
    soRooms = soRooms.filter(soRoom => appModel.getRoomType(soRoom.userData.roomTypeId).attributes.indoor);
    this.setGarageStairsBoxes(soRooms);
    this.setShaftBoxes(soRooms);

    this.graph = GraphAnalysisUtils.prepareGraphForAnalysis(soRooms);
    this.keyToEdges = GraphUtils.getKeyToEdgeFromGraph(this.graph);

    const externalSegments: Segment[] = [];
    const internalSegments: Segment[] = [];
    const ddlSegmentGroups: Segment[][] = [];
    const grgSegments: Segment[] = [];
    const sftSegments: Segment[] = [];
    this.stairsSegments = [];
    const floorPossibleStairsDdl = [];
    this.possibleStairsDdl.set(soRooms[0]?.ParentFloorId, floorPossibleStairsDdl);

    // Add internal/external, stairs and garage segments.
    this.keyToEdges.forEach(edges => {
      const segment = edges[0].data;
      if (edges.length === 1) {
        externalSegments.push(segment);
        segment.addClassification(WallType.EXT);

        if (this.garageBoxes.has(segment.roomId[0])) {
          segment.extras.isGarage = true;
          segment.addClassification(WallType.GRG);
          const garageBbox = this.garageBoxes.get(segment.roomId[0]);
          this.addRoomSegment(edges[0], garageBbox, grgSegments);
        }
        if (this.shaftBoxes.has(segment.roomId[0])) {
          segment.addClassification(WallType.SFT);
          const shaftBbox = this.shaftBoxes.get(segment.roomId[0]);
          this.addRoomSegment(edges[0], shaftBbox, sftSegments);
        }
      } else {
        segment.hasWall = edges.some(e => e.data.hasWall);
        internalSegments.push(segment);
        segment.addClassification(WallType.INT);

        if (!segment.hasWall) {
          return;
        }

        const garageEdge = edges.find(edge => this.garageBoxes.has(edge.data.roomId[0]));
        if (garageEdge) {
          edges.forEach(e => (e.data.extras.isGarage = true));
          edges.forEach(e => e.data.addClassification(WallType.GRG));
          const garageBbox = this.garageBoxes.get(garageEdge.data.roomId[0]);
          this.addRoomSegment(garageEdge, garageBbox, grgSegments);
        }

        const shaftEdge = edges.find(edge => this.shaftBoxes.has(edge.data.roomId[0]));
        if (shaftEdge) {
          edges.forEach(e => e.data.addClassification(WallType.SFT));
          const shaftBbox = this.shaftBoxes.get(shaftEdge.data.roomId[0]);
          this.addRoomSegment(shaftEdge, shaftBbox, sftSegments);
        }

        const stairsEdge = edges.find(edge => this.stairsBoxes.has(edge.data.roomId[0]));
        if (stairsEdge) {
          edges.forEach(e => (e.data.extras.stairsRoomId = stairsEdge.data.roomId));
          const stairsBox = this.stairsBoxes.get(stairsEdge.data.roomId[0]);
          this.addRoomSegment(stairsEdge, stairsBox, this.stairsSegments);
        }
      }
    });

    this.keyToEdges.forEach(edges => {
      const segment = edges[0].data;
      if (edges.length > 1) {
        if (!segment.hasWall) {
          return;
        }
        // Extend ddl for internal garage segments.
        const garageEdge = edges.find(edge => this.garageBoxes.has(edge.data.roomId[0]));
        if (garageEdge) {
          const garageBbox = this.garageBoxes.get(garageEdge.data.roomId[0]);
          const obstructionDirection = this.getBoxObstructionDirection(segment, garageBbox, true);
          const v1Ddl = this.updateLinkedDdlSegments(edges[0], edges[0].v1, null, obstructionDirection);
          const v2Ddl = this.updateLinkedDdlSegments(edges[0], edges[0].v2, null, obstructionDirection);
          this.pushOrientedDdl(ddlSegmentGroups, v1Ddl, edges[0].v2); // Revert check compare to the vertex that is further away.
          this.pushOrientedDdl(ddlSegmentGroups, v2Ddl, edges[0].v1); // Revert check compare to the vertex that is further away.
        }

        const stairsEdge = edges.find(edge => this.stairsBoxes.has(edge.data.roomId[0]));
        if (stairsEdge) {
          const stairsBox = this.stairsBoxes.get(stairsEdge.data.roomId[0]);
          const edgeAxis = segment.isHorizontal() ? "x" : "y";

          const obstructionDirection = this.getBoxObstructionDirection(segment, stairsBox, false);
          const isVertexOnEdge = (v: Vertex) =>
            MathUtils.areNumbersEqual(v[edgeAxis], stairsBox.min[edgeAxis]) || MathUtils.areNumbersEqual(v[edgeAxis], stairsBox.max[edgeAxis]);

          if (isVertexOnEdge(edges[0].v1)) {
            const v1Ddl = this.updateLinkedDdlSegments(edges[0], edges[0].v1, null, obstructionDirection);
            if (v1Ddl.length) {
              const newStairsDdl = [];
              this.pushOrientedDdl(newStairsDdl, v1Ddl, edges[0].v1);
              floorPossibleStairsDdl.push({ vertex: edges[0].v1, ddl: newStairsDdl[0] });
            }
          }
          if (isVertexOnEdge(edges[0].v2)) {
            const v2Ddl = this.updateLinkedDdlSegments(edges[0], edges[0].v2, null, obstructionDirection);
            if (v2Ddl.length) {
              const newStairsDdl = [];
              this.pushOrientedDdl(newStairsDdl, v2Ddl, edges[0].v2);
              floorPossibleStairsDdl.push({ vertex: edges[0].v2, ddl: newStairsDdl[0] });
            }
          }
        }
      } else {
        // Extend ddl for external segments.
        const v1Ddl = this.updateLinkedDdlSegments(edges[0], edges[0].v1, this.garageBoxes);
        const v2Ddl = this.updateLinkedDdlSegments(edges[0], edges[0].v2, this.garageBoxes);
        this.pushOrientedDdl(ddlSegmentGroups, v1Ddl, edges[0].v2); // Revert check compare to the vertex that is further away.
        this.pushOrientedDdl(ddlSegmentGroups, v2Ddl, edges[0].v1); // Revert check compare to the vertex that is further away.
      }
    });

    ddlSegmentGroups.forEach(segments => segments[0].extras.revertOnExport && segments.reverse());

    return {
      externalSegments: GraphAnalysisUtils.mergeSegments(externalSegments, MergeSegmentsMode.All),
      internalSegments: GraphAnalysisUtils.mergeSegments(internalSegments, MergeSegmentsMode.All),
      ddlSegments: GraphAnalysisUtils.mergeSegments(
        ddlSegmentGroups.flatMap(s => s),
        MergeSegmentsMode.SameRoom
      ),
      grgSegments: GraphAnalysisUtils.mergeSegments(grgSegments, MergeSegmentsMode.SameRoom),
      stairsSegments: GraphAnalysisUtils.mergeSegments(this.stairsSegments, MergeSegmentsMode.SameRoom),
      sftSegments: GraphAnalysisUtils.mergeSegments(sftSegments, MergeSegmentsMode.SameRoom),
    };
  }

  // For garages and staircases orientation of ddl segments is CCW (with assumption that these rooms always surrounded by 4 walls).
  private addRoomSegment(edge: Edge<Segment>, box: THREE.Box3, segments: Segment[]) {
    let isAligned: boolean;

    if (edge.data.isHorizontal()) {
      isAligned = Math.abs(edge.data.start.y - box.min.y) < Math.abs(edge.data.start.y - box.max.y); // Bottom - aligned, top - reverted.
    } else {
      isAligned = Math.abs(edge.data.start.x - box.min.x) > Math.abs(edge.data.start.x - box.max.x); // Right - aligned, left - reverted.
    }

    const clone = edge.data.clone();
    clone.extras.revertOnExport = !isAligned;
    segments.push(clone);
  }

  private pushOrientedDdl(ddlSegmentGroups: Segment[][], newDdlSegments: Segment[], vertex: Vertex) {
    if (!newDdlSegments.length) {
      return;
    }

    const axis = newDdlSegments[0].isHorizontal() ? "x" : "y";
    // Maintain axis aligned orientation.
    if (newDdlSegments[0].start[axis] < vertex[axis]) {
      newDdlSegments.reverse();
    }

    const uniqueNewDdl = [];
    newDdlSegments.forEach(newDdl => {
      const idx = ddlSegmentGroups.findIndex(segments =>
        segments.some(s => VectorUtils.areVectors2Equal(s.start, newDdl.start) && VectorUtils.areVectors2Equal(s.end, newDdl.end))
      );

      if (idx < 0) {
        uniqueNewDdl.push(newDdl);
      }
    });
    if (uniqueNewDdl.length) {
      ddlSegmentGroups.push(uniqueNewDdl);
    }
  }

  /** For outside obstruction return true if obstruction direction is top for horizontal and right for vertical segments.
   For inside obstruction return true if obstruction direction is bottom for horizontal and left for vertical segments. */
  private getBoxObstructionDirection(segment: Segment, garageBbox: THREE.Box3, isOutsideObstruction: boolean): boolean {
    // Obstruction goes in the direction away from the garage wall.
    const axis = segment.isHorizontal() ? "y" : "x"; // Perpendicular wall axis.
    const isTopRightOutsideObstruction = MathUtils.areNumbersEqual(segment.start[axis], garageBbox.max[axis]);
    return isOutsideObstruction ? isTopRightOutsideObstruction : !isTopRightOutsideObstruction;
  }

  public updateLinkedDdlSegments(
    edge: Edge<Segment>,
    vertex: Vertex,
    garageBoxes: Map<string, THREE.Box3>,
    isObstructingDirectionRightTop: boolean | null = null
  ): Segment[] {
    const vertexDdlSegments = [];
    const linked = this.graph.getLinkedEdges(vertex);
    const isHorizontal = edge.data.isHorizontal();
    const obstructWallAxis = isHorizontal ? "y" : "x"; // Obstructing walls axis.
    const edgeAxis = isHorizontal ? "x" : "y"; // Obstructing walls axis.

    if (isObstructingDirectionRightTop === null) {
      // Getting first connected segments to external segment.
      if (linked.length === 2) {
        // Is either straight line or a corner of external segments.
        return vertexDdlSegments;
      }

      const perpendicularEdges = this.getPerpendicularEdges(linked, isHorizontal);

      if (perpendicularEdges.length !== 1) {
        return vertexDdlSegments;
      }

      const end = perpendicularEdges[0].v1.id === vertex.id ? perpendicularEdges[0].v2 : perpendicularEdges[0].v1;
      isObstructingDirectionRightTop = end[obstructWallAxis] < vertex[obstructWallAxis]; // The obstructing walls go in the opposite direction of a first perpendicular external wall.
    }

    const collinearLines = linked.filter(e => e.data.isHorizontal() === isHorizontal && e.keyStr !== edge.keyStr);
    if (collinearLines.length < 2 || collinearLines.every(cl => !cl.data.hasWall)) {
      // Only internal (edges == 2) that hasWall.
      return vertexDdlSegments;
    }

    let isObstructed = this.isObstructed(linked, isHorizontal, vertex, isObstructingDirectionRightTop);

    // Check stairs walls
    if (isObstructed && (collinearLines[0].data.extras.stairsRoomId || edge.data.extras.stairsRoomId)) {
      const segmentToCheck = collinearLines[0].data.extras.stairsRoomId ? collinearLines[0].data : edge.data;

      // If obstruction direction is not equal to aligned direction of stairs (trying to prolong outside of the stairs) then do not continue.
      const isAligned = this.doesHaveCorrectAlignment(segmentToCheck, isHorizontal, obstructWallAxis, edge, isObstructingDirectionRightTop);
      if (isAligned) {
        const doorWall1 = this.stairsWalls.get(segmentToCheck.extras.stairsRoomId);
        const doorWall = this.stairsWalls
          .get(segmentToCheck.extras.stairsRoomId[0])
          .find(sw => sw.isHorizontal !== isHorizontal && MathUtils.areNumbersEqual(sw.line.start[edgeAxis], vertex[edgeAxis]));
        // Check that wall can go through an opening in the wall
        isObstructed = !doorWall || this.doesIntersectStairsWall(collinearLines[0].data, isObstructingDirectionRightTop, obstructWallAxis, doorWall);
      }
    }

    if (isObstructed) {
      return vertexDdlSegments;
    }

    if (garageBoxes && collinearLines.some(cl => garageBoxes.has(cl.data.roomId[0]))) {
      // If new line is garage, then stop, because it was already added when calculating garage ddls.
      return vertexDdlSegments;
    }
    collinearLines.forEach(e => {
      e.data.extras.isDdl = true;
      e.data.extras.revertOnExport = isHorizontal ? !isObstructingDirectionRightTop : isObstructingDirectionRightTop;
    });
    vertexDdlSegments.push(collinearLines[0].data.clone());
    const nextVertex = collinearLines[0].v1.id === vertex.id ? collinearLines[0].v2 : collinearLines[0].v1;

    const newDdl = this.updateLinkedDdlSegments(collinearLines[0], nextVertex, garageBoxes, isObstructingDirectionRightTop);
    vertexDdlSegments.push(...newDdl);
    return vertexDdlSegments;
  }

  private getPerpendicularEdges = (linked: Edge<Segment>[], isHorizontal: boolean) => {
    return linked.filter(e => {
      if (e.data.isHorizontal() === isHorizontal) {
        return false;
      }

      const edges = this.keyToEdges.get(e.keyStr);
      if (edges.length === 1) {
        // External, always has wall.
        return true;
      }

      return edges.some(e => e.data.hasWall); // Internal with at least one hasWall.
    });
  };

  private doesHaveCorrectAlignment(
    segment: Segment,
    isHorizontal: boolean,
    obstructWallAxis: string,
    edge: Edge<Segment>,
    isObstructingDirectionRightTop: boolean
  ) {
    const addedStairsSegment = this.stairsSegments.find(
      s =>
        s.roomId === segment.extras.stairsRoomId &&
        s.isHorizontal() === isHorizontal &&
        MathUtils.areNumbersEqual(s.start[obstructWallAxis], edge.data.start[obstructWallAxis])
    );

    return (
      (isHorizontal && addedStairsSegment.extras.revertOnExport !== isObstructingDirectionRightTop) ||
      (!isHorizontal && addedStairsSegment.extras.revertOnExport === isObstructingDirectionRightTop)
    );
  }

  private doesIntersectStairsWall(
    segment: Segment,
    isObstructingWallDirectionRightTop: boolean,
    obstructWallAxis: string,
    doorWall: { wall: THREE.Object3D; line: THREE.Line3; isHorizontal: boolean }
  ) {
    const wallWidth = UnitsUtils.getSyntheticWallHalfSize() * 2;
    const shiftDirection = isObstructingWallDirectionRightTop ? 1 : -1;
    const bboxMin = segment.start.clone();
    bboxMin[obstructWallAxis] += shiftDirection * wallWidth;
    const bboxMax = segment.end.clone();
    bboxMax[obstructWallAxis] += shiftDirection * wallWidth * 2;
    const edgeInternalBbox = new THREE.Box2(bboxMin, bboxMax);
    const doesIntersectStairsWall = doorWall.wall.children.some(
      child => child instanceof THREE.Line && GeometryUtils.lineIntersectsBoundingBox(SceneUtils.getLine3(child), edgeInternalBbox)
    );
    return doesIntersectStairsWall;
  }

  private isObstructed(linked: Edge<Segment>[], isHorizontal: boolean, vertex: Vertex, isObstructingWallDirectionRightTop: boolean): boolean {
    const obstructWallAxis = isHorizontal ? "y" : "x"; // Obstructing walls axis.
    return linked.some(e => {
      if (e.data.isHorizontal() === isHorizontal) {
        return false;
      }

      const edges = this.keyToEdges.get(e.keyStr);
      if (edges.length === 2 && edges.every(e => !e.data.hasWall)) {
        return false;
      }

      const end = e.v1.id === vertex.id ? e.v2 : e.v1;
      return end[obstructWallAxis] > vertex[obstructWallAxis] === isObstructingWallDirectionRightTop;
    });
  }

  private setGarageStairsBoxes(soRooms: soRoom2D[]) {
    this.garageBoxes = new Map();
    this.stairsBoxes = new Map();
    this.stairsWalls = new Map();

    soRooms.forEach(soRoom => {
      const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);
      if (!roomType) {
        return;
      }

      const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
      if (!roomCategory) {
        return;
      }

      if (roomCategory.isGarage) {
        this.garageBoxes.set(soRoom.userData.id, soRoom.getSoRoomBoundingBoxByModelLines());
      } else if (roomCategory.isStairs) {
        this.stairsBoxes.set(soRoom.userData.id, soRoom.getSoRoomBoundingBoxByModelLines());
        const doorWalls = soRoom.children
          .filter(re => re.userData.type === RoomEntityType.Wall && re.children.filter(child => child instanceof THREE.Line).length > 4)
          .map(wall => {
            const line = GeometryUtils.getBoundingBoxCenterLine(GeometryUtils.getGeometryBoundingBox2D(wall));
            return { wall, line, isHorizontal: GeometryUtils.isLineHorizontal(line) };
          });
        this.stairsWalls.set(soRoom.userData.id, doorWalls);
      }
    });
  }
  private setShaftBoxes(soRooms: soRoom2D[]) {
    this.shaftBoxes = new Map();

    soRooms.forEach(soRoom => {
      const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);
      if (!roomType) {
        return;
      }

      const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
      if (!roomCategory) {
        return;
      }

      if (roomType.isShaft) {
        this.shaftBoxes.set(soRoom.userData.id, soRoom.getSoRoomBoundingBoxByModelLines());
      }
    });
  }
}
