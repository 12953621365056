import * as THREE from "three";
import { appModel } from "../../models/AppModel";
import { modellingIssuesState } from "../../ui/components/Editor/ModellingIssues/ModellingIssues";
import { SHORT_WALL_SEGMENT_INDICATOR_PRIMARY_COLOR, SHORT_WALL_SEGMENT_INDICATOR_RENDER_ORDER, SHORT_WALL_SEGMENT_INDICATOR_SECONDARY_COLOR } from "../consts";
import RoomManager from "../managers/RoomManager/RoomManager";
import { MergeSegmentsMode } from "../models/SegmentsMergeMode";
import { Segment } from "../models/segments/Segment";
import GeometryUtils from "../utils/GeometryUtils/GeometryUtils";
import MathUtils from "../utils/MathUtils";
import SceneUtils from "../utils/SceneUtils";
import SegmentsUtils from "../utils/SegmentsUtils";
import UnitsUtils from "../utils/UnitsUtils";
import { WallAnalysisUtils } from "../utils/WallAnalysisUtils";
import SceneManager from "../managers/SceneManager/SceneManager";
import { Graph } from "../models/graph/Graph";
import { Vertex } from "../models/graph/Vertex";
import { Edge } from "../models/graph/Edge";
import { GraphAnalysisUtils } from "../utils/GraphAnalysisUtils";
import FloorUtils from "../utils/FloorUtils";

export default class ShortWallSegmentsTool {
  private soRoot = new THREE.Group();
  private analysisUtils = this.roomManager instanceof SceneManager ? GraphAnalysisUtils : WallAnalysisUtils;
  constructor(public roomManager: any) {
    if (!(this.roomManager instanceof RoomManager) && !(this.roomManager instanceof SceneManager)) {
      throw new Error("Manager is not an instance of RoomManager");
    }
    this.soRoot.name = "Short wall segments tool Root";
    this.roomManager.getSoRoot().add(this.soRoot);
  }

  public validateShortSegments(): void {
    this.clearShortSegmentsValidationResult();

    // Validates the floor and returns whether there were any errors or not.
    const validateFloor = (floorIndex: number): boolean => {
      const floor = appModel.activeCorePlan.floors.find(floor => floor.index === floorIndex);
      if (floor) {
        const results = this.validateShortSegmentsForFloor(floor.id, appModel.activeFloor === floor);
        results.forEach(it => this.soRoot.add(it));
        return results.length > 0;
      }

      return false;
    };

    const hasPrimaryErrors = validateFloor(appModel.activeFloor.index);
    const hasSecondaryErrors =
      (appModel.showBelowFloor && validateFloor(appModel.activeFloor.index - 1)) || (appModel.showAboveFloor && validateFloor(appModel.activeFloor.index + 1));

    modellingIssuesState.setValue({ hasPrimaryErrors, hasSecondaryErrors });
  }

  public clearShortSegmentsValidationResult(): void {
    GeometryUtils.disposeObject(this.soRoot);
  }

  private validateShortSegmentsForFloor(floorId: string, useIndicatorPrimaryColor: boolean): THREE.Group[] {
    const soRooms = FloorUtils.getFloorSoRooms(this.roomManager.getSoFloor(floorId));
    const { externalSegments, internalSegments } = this.analysisUtils.collectSegments(soRooms, MergeSegmentsMode.All);
    const segments = SegmentsUtils.splitIntersectedSegments([...externalSegments, ...internalSegments]);
    const graph = new Graph<Segment>();
    const threshold = UnitsUtils.getMinimalWallSegmentLength();

    segments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));

    const shortEdges = graph.getEdges().filter(e => e.data.length() < threshold);

    const getPerpendicularSegments = (segment: Segment, vertex: Vertex) => {
      const delta = segment.delta();
      return graph
        .getLinkedEdges(vertex)
        .filter(edge => MathUtils.areNumbersEqual(edge.data.delta().dot(delta), 0))
        .map(edge => {
          const otherVertex = edge.v1 === vertex ? edge.v2 : edge.v1;
          return new Segment(new THREE.Vector2(vertex.point.x, vertex.point.y), new THREE.Vector2(otherVertex.point.x, otherVertex.point.y));
        });
    };

    const getSegmentsToAdjust = (edge: Edge<Segment>): Segment[] => {
      const startSegments = getPerpendicularSegments(edge.data, edge.v1);
      const endSegments = getPerpendicularSegments(edge.data, edge.v2);

      const segment = startSegments.length === 1 ? startSegments[0] : endSegments[0];
      if (!segment) {
        return [];
      }

      const otherSegment = (startSegments.length === 1 ? endSegments : startSegments).find(s => s.delta().dot(segment.delta()) < 0);

      return otherSegment ? [segment, otherSegment] : [segment];
    };

    const results: THREE.Group[] = [];

    shortEdges.forEach(edge => {
      const segmentsToAdjust = getSegmentsToAdjust(edge);
      results.push(this.createShortSegmentIndicator(edge.data, segmentsToAdjust, useIndicatorPrimaryColor));
    });

    return results;
  }

  private createShortSegmentIndicator(segment: Segment, segmentsToAdjust: Segment[], usePrimaryColor: boolean): THREE.Group {
    const halfSize = UnitsUtils.getSyntheticWallHalfSize();
    const size = segment.length() + 2 * halfSize;
    const color = usePrimaryColor ? SHORT_WALL_SEGMENT_INDICATOR_PRIMARY_COLOR : SHORT_WALL_SEGMENT_INDICATOR_SECONDARY_COLOR;
    const segments = SegmentsUtils.clipSegmentsBySphere(segmentsToAdjust, new THREE.Sphere(segment.getCenter3(), size + halfSize / 2));

    // Limit the maximum length of each segment.
    segments.forEach(segment => {
      const length = Math.min(segment.length(), UnitsUtils.getShortWallSegmentIndicatorMaxLength());
      segment.end = segment.delta().normalize().multiplyScalar(length).add(segment.start);
    });

    const result = new THREE.Group();
    segments.forEach(segment => result.add(SceneUtils.createSegmentMesh(segment, halfSize, color, 0)));

    const ring = SceneUtils.createRing(size, halfSize, color);
    ring.position.copy(segment.getCenter3());
    result.add(ring);

    GeometryUtils.setChildrenRenderOrder(result, SHORT_WALL_SEGMENT_INDICATOR_RENDER_ORDER);

    return result;
  }
}
