import { IReactionDisposer, reaction } from "mobx";
import * as THREE from "three";
import { appModel } from "../../../models/AppModel";
import { CursorStyle } from "../../../models/CursorStyle";
import { CorePlan } from "../../../models/CorePlan";
import { RoomEntityType } from "../../../models/RoomEntityType";
import RoomOpening from "../../../models/RoomOpening";
import { DragMode } from "../../models/DragMode";
import { Keys } from "../../models/Keys";
import { SceneEditorMode } from "../../models/SceneEditorMode";
import { TranslateRoomOpeningCommand } from "../../models/commands/TranslateRoomOpeningCommand";
import { SoOpeningSelectionMarker } from "../../models/scene/SoOpeningSelectionMarker";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import MathUtils from "../../utils/MathUtils";
import SceneUtils from "../../utils/SceneUtils";
import SceneManager from "./SceneManager";
import { settings } from "../../../entities/settings";
import { IManager } from "../IManager";
import { GraphAnalysisUtils } from "../../utils/GraphAnalysisUtils";

export default class RoomOpeningManager implements IManager {
  private reactions: IReactionDisposer[] = [];
  private selectedRoomOpeningsReactions: IReactionDisposer[] = [];
  private soRoot = new THREE.Group();
  private dragMode: DragMode = DragMode.none;
  private soDraggedObject: THREE.Object3D = null;
  public sceneManager: SceneManager;
  constructor(sceneManager: SceneManager) {
    this.sceneManager = sceneManager;
    this.soRoot.name = "Room Opening Manager Root";
    this.sceneManager.getSoRoot().add(this.soRoot);

    reaction(() => appModel.activeCorePlan, this.onActiveCorePlanChanged.bind(this), { fireImmediately: true });
    reaction(() => appModel.sceneEditorMode, this.onEditModeChanged.bind(this));
  }

  public onMouseMove(e: MouseEvent) {
    if (appModel.isViewOnlyMode || !this.sceneManager.baseManager.isMouseHandlersEnabled) {
      return;
    }

    switch (this.dragMode) {
      case DragMode.none: {
        if (this.sceneManager.baseManager.isMouseDown && e.buttons === 1) {
          const intersectedMarker = this.getIntersectedOpeningMarker();
          if (intersectedMarker) {
            this.dragMode = DragMode.movingRoomOpening;
            this.soDraggedObject = this.getActiveFloorSoOpenings().find(soOpening => soOpening.uuid === intersectedMarker.userData.openingUuid);

            const openingData = SceneUtils.getOpeningZoneAndLine(this.soDraggedObject);
            const mouseOnOpeningLine = openingData.line.closestPointToPoint(this.sceneManager.intersectionPoint, false, new THREE.Vector3());
            this.soDraggedObject.userData.moveOffset = openingData.line.getCenter(new THREE.Vector3()).sub(mouseOnOpeningLine); // Current mouse -> Current opening center.
            this.soDraggedObject.userData.startShiftDistance = this.soDraggedObject.userData.shiftDistance ?? 0;
          }
        }
        break;
      }
      case DragMode.movingRoomOpening: {
        (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Pointer;

        this.moveOpening(this.soDraggedObject);

        this.sceneManager.updateRoomsProperties([this.soDraggedObject.parent.userData.id]);
        this.updateOpeningMarker(this.soDraggedObject);
        break;
      }
    }
  }
  public onMouseLeave(e: MouseEvent): void {
    if (appModel.isViewOnlyMode || !this.sceneManager.baseManager.isMouseHandlersEnabled) {
      return;
    }

    this.handleDragFinish();
  }
  public onMouseUp(e: MouseEvent) {
    if (appModel.isViewOnlyMode || !this.sceneManager.baseManager.isMouseHandlersEnabled) {
      return;
    }

    if (this.dragMode === DragMode.none) {
      if (!this.sceneManager.isPanning) {
        if (e.button === 0) {
          const intersectedOpening = this.getIntersectedOpening();
          appModel.clearSelectedRoomOpenings();

          if (intersectedOpening) {
            this.addSoOpeningToSelected(intersectedOpening);
          } else {
            appModel.setSceneEditorMode(SceneEditorMode.Room);
          }
        }
      }

      return;
    }

    this.handleDragFinish();
  }
  public onKeyUp(e: KeyboardEvent) {
    if (appModel.isViewOnlyMode || (!e.ctrlKey && !e.metaKey)) {
      return;
    }

    switch (e.code) {
      case Keys.Y: {
        this.redo();
        break;
      }
      case Keys.Z: {
        this.undo();
        break;
      }
    }
  }

  public getIntersectedOpening(): THREE.Object3D | null {
    const result = this.getActiveFloorSoOpenings().find(soOpening => {
      const bb = SceneUtils.getRoomOpeningBoundingBoxInsideHostingWall(soOpening);
      return this.sceneManager.raycaster.ray.intersectsBox(bb);
    });

    return result ?? null;
  }

  public undo(): void {
    if (this.dragMode === DragMode.none) {
      const command = this.sceneManager.commandManager.undo();
      if (command) {
        appModel.clearSelectedRoomOpenings();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
      }
    }
  }
  public redo(): void {
    if (this.dragMode === DragMode.none) {
      const command = this.sceneManager.commandManager.redo();
      if (command) {
        appModel.clearSelectedRoomOpenings();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
      }
    }
  }

  public unlockSelectedOpening(): void {
    const opening = appModel.selectedRoomOpenings[0];
    if (opening) {
      opening.setIsLocked(false);
      appModel.clearSelectedRoomOpenings();

      this.sceneManager.openingTool.alignRoomsOpenings(appModel.activeCorePlan.floors, this.sceneManager.getSoFloorsRoot());
      this.updateOpeningMarker(this.getActiveFloorSoOpening(opening));
    }
  }

  public addSoOpeningToSelected(soOpening: THREE.Object3D): void {
    const opening = appModel.activeCorePlan.getRoomOpening(soOpening.parent.userData.id, soOpening.userData.id);
    appModel.addSelectedRoomOpening(opening);
  }

  public updateOpeningMarker(soOpening: THREE.Object3D): void {
    const existing = this.soRoot.children.find(child => child.userData.openingUuid === soOpening.uuid);

    if (existing) {
      this.soRoot.remove(existing);
      GeometryUtils.disposeObject(existing);

      this.soRoot.add(new SoOpeningSelectionMarker(this.sceneManager, soOpening));
    }
  }

  /**
   * Perform necessary operations based on the current drag mode and reset the drag mode to none.
   */
  private handleDragFinish(): void {
    if (this.dragMode === DragMode.none) {
      return;
    }

    switch (this.dragMode) {
      case DragMode.movingRoomOpening: {
        if (!MathUtils.areNumbersEqual(this.soDraggedObject.userData.shiftDistance, this.soDraggedObject.userData.startShiftDistance)) {
          // To allow restoring the original 'isLocked' state on undo, first, add the translation command to track the movement.
          const sign = Math.sign(this.soDraggedObject.parent.scale.x);
          this.sceneManager.commandManager.add(
            new TranslateRoomOpeningCommand(
              this.soDraggedObject,
              sign * this.soDraggedObject.userData.startShiftDistance,
              sign * this.soDraggedObject.userData.shiftDistance
            )
          );
          this.soDraggedObject.userData.isLocked = true;
        }

        delete this.soDraggedObject.userData.moveOffset;
        delete this.soDraggedObject.userData.startShiftDistance;

        this.soDraggedObject = null;

        this.sceneManager.openingTool.alignRoomsOpenings(appModel.activeCorePlan.floors, this.sceneManager.getSoFloorsRoot());
        break;
      }
    }

    this.dragMode = DragMode.none;
    this.sceneManager.controls.noPan = false;
    this.sceneManager.baseManager.setCursorStyle(CursorStyle.Default);
  }

  private onActiveCorePlanChanged(corePlan?: CorePlan): void {
    this.unsubscribe(this.reactions);
    GeometryUtils.disposeObject(this.soRoot);

    if (corePlan) {
      this.subscribeActiveCorePlan();
    }
  }
  private onActiveFloorChanged(): void {
    appModel.clearSelectedRoomOpenings();
  }
  private onEditModeChanged(mode: SceneEditorMode, oldMode: SceneEditorMode): void {
    if (oldMode === SceneEditorMode.RoomOpening) {
      appModel.clearSelectedRoomOpenings();
    }
  }

  private onSelectedRoomOpeningsChanged(): void {
    this.getCorePlanSoOpenings().forEach(soOpening => {
      const isVisible = appModel.selectedRoomOpenings.some(opening => opening.id === soOpening.userData.id && opening.roomId === soOpening.parent.userData.id);
      this.updateOpeningMarkerVisibility(soOpening, isVisible);
      this.sceneManager.updateRoomsProperties([soOpening.parent.userData.id]);
    });

    this.unsubscribe(this.selectedRoomOpeningsReactions);
    if (appModel.selectedRoomOpenings.length === 1) {
      this.subscribeSelectedRoomOpening(appModel.selectedRoomOpenings[0]);
    }
  }
  private onSelectedRoomOpeningShiftDistanceChanged(distance: number): void {
    if (this.dragMode !== DragMode.none) {
      return;
    }

    const opening = appModel.selectedRoomOpenings[0];
    const soOpening = this.getActiveFloorSoOpening(opening);
    const openingData = SceneUtils.getOpeningZoneAndLine(soOpening);

    const sign = Math.sign(soOpening.parent.scale.x);
    const currentRelativeShiftDistance = sign * (soOpening.userData.relativeShiftDistance ?? 0);
    const minFinishDistance = this.calculateOpeningShiftDistance(this.sceneManager, soOpening, openingData.zone.start, false);
    const maxFinishDistance = this.calculateOpeningShiftDistance(this.sceneManager, soOpening, openingData.zone.end, false);
    const minCoreDistance = this.calculateOpeningShiftDistance(this.sceneManager, soOpening, openingData.zone.start, true);
    const maxCoreDistance = this.calculateOpeningShiftDistance(this.sceneManager, soOpening, openingData.zone.end, true);

    let shiftDistance = this.calculateShiftDistance(openingData, distance, minFinishDistance, maxFinishDistance);
    shiftDistance = this.adjustDistance(shiftDistance, minFinishDistance, maxFinishDistance, openingData, soOpening, false);

    const clampShiftDistance = MathUtils.clamp(shiftDistance, minCoreDistance, maxCoreDistance);

    if (MathUtils.areNumbersEqual(currentRelativeShiftDistance, clampShiftDistance)) {
      // Check if the MobX value exceeds the constraints and update it to maintain synchronization.
      if (!MathUtils.areNumbersEqual(shiftDistance, clampShiftDistance)) {
        opening.setShiftDistance(clampShiftDistance);
        opening.setReferenceShiftDistance(distance);
      }

      return;
    }

    this.sceneManager.commandManager.apply(new TranslateRoomOpeningCommand(soOpening, currentRelativeShiftDistance, clampShiftDistance));
  }

  /**
   * Adjusts the shift or reference shift distance based on the difference between finish and core distances,
   * if showing core face based dimensions. It handles both shiftDistance and referenceShiftDistance.
   *
   * @param {number} distance - The current shift or reference shift distance to be adjusted.
   * @param {number} minFinishDistance - The minimum finish distance for the opening.
   * @param {number} maxFinishDistance - The maximum finish distance for the opening.
   * @param {Object} openingData - The opening data containing zone and line details.
   * @param {THREE.Object3D} soOpening - The opening object being modified.
   * @param {boolean} isReference - Flag indicating whether this is a reference shift distance or a normal shift distance.
   * @returns {number} - The adjusted shift or reference shift distance.
   */
  public adjustDistance(
    distance: number,
    minFinishDistance: number,
    maxFinishDistance: number,
    openingData: { zone: THREE.Line3; line: THREE.Line3 },
    soOpening: THREE.Object3D,
    isReference: boolean
  ): number {
    // Calculate the distance between the center and the start of the line for both horizontal and vertical cases
    let distanceFromCenterToStart = 0;

    if (GeometryUtils.isLineHorizontal(openingData.line)) {
      const centerX = openingData.line.getCenter(new THREE.Vector3()).x;
      const startX = openingData.line.start.x;
      distanceFromCenterToStart = centerX - startX;
    } else if (GeometryUtils.isLineVertical(openingData.line)) {
      const centerY = openingData.line.getCenter(new THREE.Vector3()).y;
      const startY = openingData.line.start.y;
      distanceFromCenterToStart = centerY - startY;
    }

    // If not showing finish face dimension, adjust based on core distances
    if (!appModel.showFinishFaceDimension) {
      const minCoreDistance = this.calculateOpeningShiftDistance(this.sceneManager, soOpening, openingData.zone.start, true);
      const maxCoreDistance = this.calculateOpeningShiftDistance(this.sceneManager, soOpening, openingData.zone.end, true);

      const diffMin = Math.abs(minFinishDistance - minCoreDistance);
      const diffMax = Math.abs(maxFinishDistance - maxCoreDistance);

      if (GeometryUtils.isLineHorizontal(openingData.line)) {
        distance += openingData.zone.start.x < openingData.zone.end.x ? (isReference ? diffMin : -diffMin) : isReference ? diffMax : diffMax;
      } else if (GeometryUtils.isLineVertical(openingData.line)) {
        distance += openingData.zone.start.y < openingData.zone.end.y ? (isReference ? diffMin : -diffMin) : isReference ? diffMax : diffMax;
      }
    }

    // Determine edge offset based on the type of opening (Window or Door)
    let edgeOffset = 0;
    if (soOpening.userData.type === RoomEntityType.Window) {
      edgeOffset = settings.values.validationSettings.windowEdgeOffset;
    } else if (soOpening.userData.type === RoomEntityType.Door) {
      edgeOffset = settings.values.validationSettings.doorEdgeOffset;
    }

    if (isReference) {
      // For referenceShiftDistance: Apply distance from center-to-start and edgeOffset
      if (GeometryUtils.isLineHorizontal(openingData.line)) {
        distance += openingData.zone.start.x < openingData.zone.end.x ? distanceFromCenterToStart + edgeOffset : -distanceFromCenterToStart + edgeOffset;
      } else if (GeometryUtils.isLineVertical(openingData.line)) {
        distance += openingData.zone.start.y < openingData.zone.end.y ? distanceFromCenterToStart + edgeOffset : -distanceFromCenterToStart + edgeOffset;
      }
    } else {
      // For shiftDistance: Apply negative distance from center-to-start and then apply edgeOffset
      distance -= distanceFromCenterToStart;
      if (GeometryUtils.isLineHorizontal(openingData.line)) {
        distance += openingData.zone.start.x < openingData.zone.end.x ? -edgeOffset : edgeOffset;
      } else if (GeometryUtils.isLineVertical(openingData.line)) {
        distance += openingData.zone.start.y < openingData.zone.end.y ? -edgeOffset : edgeOffset;
      }
    }

    return distance;
  }

  /**
   * Calculates the shift distance for the room opening based on the orientation of the opening line (horizontal/vertical).
   * It adjusts the shift distance either by adding to the minimum or subtracting from the maximum distance,
   * depending on the direction of the line (whether start is less than end).
   *
   * @param openingData - The data containing the opening zone and line, including start and end points.
   * @param distance - The desired shift distance input by the user.
   * @param minDistance - The minimum shift distance that the opening can move to.
   * @param maxDistance - The maximum shift distance that the opening can move to.
   * @returns The calculated shift distance, or 0 if the line is neither horizontal nor vertical.
   */
  private calculateShiftDistance(
    openingData: { zone: THREE.Line3; line: THREE.Line3; center: THREE.Vector3 },
    distance: number,
    minDistance: number,
    maxDistance: number
  ): number {
    // Check if the line is horizontal or vertical
    const isHorizontal = GeometryUtils.isLineHorizontal(openingData.line);
    const isVertical = GeometryUtils.isLineVertical(openingData.line);

    // Determine whether the start point is less than the end point based on line orientation
    const isStartLessThanEnd = isHorizontal ? openingData.zone.start.x < openingData.zone.end.x : openingData.zone.start.y < openingData.zone.end.y;

    // If the line is either horizontal or vertical, adjust the shift distance accordingly
    if (isHorizontal || isVertical) {
      return isStartLessThanEnd ? distance + minDistance : maxDistance - distance;
    }

    // Return 0 for non-horizontal/vertical lines as no shift is applied
    return 0;
  }

  private onSelectedRoomOpeningIsLockedChanged(isLocked: boolean): void {
    const soOpening = this.getActiveFloorSoOpening(appModel.selectedRoomOpenings[0]);
    soOpening.userData.isLocked = isLocked;
  }

  private subscribeActiveCorePlan(): void {
    this.reactions.push(reaction(() => appModel.activeFloor, this.onActiveFloorChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.selectedRoomOpenings.length, this.onSelectedRoomOpeningsChanged.bind(this)));
  }
  private subscribeSelectedRoomOpening(opening: RoomOpening): void {
    this.selectedRoomOpeningsReactions.push(reaction(() => opening.referenceShiftDistance, this.onSelectedRoomOpeningShiftDistanceChanged.bind(this)));
    this.selectedRoomOpeningsReactions.push(reaction(() => opening.isLocked, this.onSelectedRoomOpeningIsLockedChanged.bind(this)));
  }
  private unsubscribe(reactions: IReactionDisposer[]): void {
    reactions.forEach(r => r());
    reactions.length = 0;
  }

  private getCorePlanSoOpenings(): THREE.Object3D[] {
    return this.sceneManager.getCorePlanSoRooms().flatMap(this.extractSoOpenings);
  }
  private getActiveFloorSoOpenings(): THREE.Object3D[] {
    const soFloor = this.sceneManager.getActiveSoFloor();
    return soFloor ? this.sceneManager.getActiveSoFloor().soRooms.flatMap(this.extractSoOpenings) : [];
  }
  public getActiveFloorSoOpening(opening: RoomOpening): THREE.Object3D {
    return this.getActiveFloorSoOpenings().find(soOpening => soOpening.userData.id === opening.id && soOpening.parent.userData.id === opening.roomId);
  }
  private getIntersectedOpeningMarker(): THREE.Object3D | null {
    const markers = this.soRoot.children.filter(child => child instanceof SoOpeningSelectionMarker);
    const intersections = this.sceneManager.raycaster.intersectObjects(markers);
    return intersections.length ? intersections[0].object : null;
  }

  private updateOpeningMarkerVisibility(soOpening: THREE.Object3D, isVisible: boolean): void {
    const existing = this.soRoot.children.find(child => child.userData.openingUuid === soOpening.uuid);

    if (isVisible) {
      if (!existing) {
        this.soRoot.add(new SoOpeningSelectionMarker(this.sceneManager, soOpening));
      }
    } else {
      if (existing) {
        this.soRoot.remove(existing);
        GeometryUtils.disposeObject(existing);
      }
    }
  }
  private moveOpening(soOpening: THREE.Object3D): void {
    const openingData = SceneUtils.getOpeningZoneAndLine(soOpening);
    const mouseOnOpeningLine = openingData.line.closestPointToPoint(this.sceneManager.intersectionPoint, false, new THREE.Vector3());
    const newOpeningPosition = mouseOnOpeningLine.clone().add(this.soDraggedObject.userData.moveOffset);
    const distance = this.calculateOpeningShiftDistance(this.sceneManager, soOpening, newOpeningPosition);

    SceneUtils.moveOpening(soOpening, distance);
    GraphAnalysisUtils.regenerateRoomsWalls(this.sceneManager, this.sceneManager.getActiveSoFloor());
    this.sceneManager.checkBlockedDoors();
    this.sceneManager.checkIntersectedWindows();
    this.sceneManager.checkRoomsOverlapping();
  }
  private extractSoOpenings(soRoom: THREE.Object3D): THREE.Object3D[] {
    return soRoom.children.filter(child => child.userData.type === RoomEntityType.Window || child.userData.type === RoomEntityType.Door);
  }

  /**
   * Calculates the maximum distance that the opening (soOpening) can be moved to reach the desired position (newPosition).
   * @param {THREE.Object3D} soOpening - The opening to move.
   * @param {THREE.Vector3} newPosition - The expected position of the opening in world space.
   * @param {boolean} useCoreThicknessForZoneLimit - Flag indicating whether to use core thickness for limiting the opening zone (default: true).
   * @returns {number} The maximum possible distance that the opening can be moved to reach the desired position.
   */
  public calculateOpeningShiftDistance(sceneManager: any, soOpening: THREE.Object3D, newPosition: THREE.Vector3, useCoreThicknessForZoneLimit: boolean = true) {
    const openingData = SceneUtils.getOpeningZoneAndLine(soOpening);
    const openingDelta = openingData.line.delta(new THREE.Vector3());
    const currentPosition = openingData.line.getCenter(new THREE.Vector3());
    const offset = newPosition.clone().sub(currentPosition);

    const openingWallSegment = SceneUtils.findOpeningSoWall(soOpening, openingData.zone, openingData.line, this.sceneManager);
    const limitedZone = SceneUtils.getLimitedOpeningZoneBySoWall(
      sceneManager,
      soOpening,
      openingData.zone,
      openingData.line,
      openingWallSegment,
      useCoreThicknessForZoneLimit
    );

    // Validate that the opening will remain inside the opening zone range after moving.
    // If it exceeds the limits, decrease the offset according to the max range.
    const pointToValidate = openingDelta.dot(offset) < 0 ? openingData.line.start : openingData.line.end;
    const translatedPoint = pointToValidate.clone().add(offset);

    const closestPointInZone = limitedZone.closestPointToPoint(translatedPoint, true, new THREE.Vector3());
    const exceed = translatedPoint.clone().sub(closestPointInZone);
    const limitedNewPosition = newPosition.clone().sub(exceed);
    const sign = Math.sign(limitedNewPosition.clone().sub(openingData.center).dot(openingDelta));

    return sign * openingData.center.distanceTo(limitedNewPosition);
  }
}
