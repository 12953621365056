import { Object3D } from "three";
import { Floor } from "../../models/Floor";
import ArcAreaValidationTool from "../tools/ValidationTools/ArcAreaValidationTool";
import { soFloor2D } from "../models/SceneObjects/Floor/soFloor2D";
import { soRoom2D } from "../models/SceneObjects/Room/soRoom2D";
import SceneUtils from "./SceneUtils";
import { MessageKindsEnum, showToastMessage } from "../../helpers/messages";
import { MESSAGE_DURATION, ROOM_OVERLAP_MESSAGE } from "../consts";
import { ValidationMode } from "../models/ValidationType";

export default class FloorUtils {
  static calculateNetArea(floor: Object3D, roomManager: any): number {
    const soRooms = floor.children;
    return roomManager.validationTool.getValidator(ValidationMode.ArcAreaValidation).calculateNetArea(soRooms);
  }
  static getFloorSoRooms(floor: any): any {
    return floor instanceof soFloor2D ? floor.soRooms : floor.children;
  }
  static calculateAreaContours(floor: Object3D, roomManager: any): any {
    const soRooms = floor.children;

    return roomManager.validationTool.getValidator(ValidationMode.ArcAreaValidation).calculateAreaContours(soRooms);
  }

  static checkRoomsOverlapping(showErrorMessage = true, soFloor?: soFloor2D): void {
    if (!soFloor) {
      return;
    }

    const intersectedRooms: Set<soRoom2D> = new Set<soRoom2D>();
    const toUnHighLight: Set<soRoom2D> = new Set<soRoom2D>();

    const soRooms = soFloor.soRooms;

    for (let i = 0; i < soRooms.length; i++) {
      // reset helper properties
      delete soRooms[i].userData.isOverlapped;
      delete soRooms[i].userData.isTooCloseToOther;

      for (let j = i + 1; j < soRooms.length; j++) {
        if (intersectedRooms.has(soRooms[i]) && intersectedRooms.has(soRooms[j])) {
          continue;
        }
        if (SceneUtils.areSoRoomsOverlapping(soRooms[i], soRooms[j])) {
          intersectedRooms.add(soRooms[i]);
          intersectedRooms.add(soRooms[j]);
        }
      }

      if (!intersectedRooms.has(soRooms[i])) {
        toUnHighLight.add(soRooms[i]);
      }
    }

    intersectedRooms.forEach(room => SceneUtils.highlightIntersectedRoom(room));
    toUnHighLight.forEach(room => SceneUtils.unhighlightIntersectedRoom(room));

    if (showErrorMessage && soRooms.some(soRoom => soRoom.userData.isOverlapped)) {
      showToastMessage(MessageKindsEnum.Error, ROOM_OVERLAP_MESSAGE, { autoClose: MESSAGE_DURATION });
    }
  }
}
