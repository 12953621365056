import * as THREE from "three";
import { appModel } from "../../../models/AppModel";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import RoomManager from "../../managers/SceneManager/SceneManager";
import { SceneEntityType } from "../../models/SceneEntityType";
import { ValidationResult } from "../../models/ValidationResult";
import { ValidationMode } from "../../models/ValidationType";
import ArcAreaValidationTool from "./ArcAreaValidationTool";
import GravityLoadsValidationTool from "./GravityLoadsValidationTool";
import { IValidationTool } from "./IValidationTool";
import PlumbingValidationTool from "./PlumbingValidationTool";
import ShearCapacityValidationTool from "./ShearCapacityValidationTool";
import WallTypesValidationTool from "./WallTypesValidationTool";
import GapValidationTool from "./GapValidationTool";
import SceneManager from "../../managers/SceneManager/SceneManager";

export default class ValidationTool {
  private validationResultContainer: THREE.Group = null;
  private modeToolMap: Map<ValidationMode, IValidationTool> = new Map<ValidationMode, IValidationTool>();
  private roomManager: RoomManager | SceneManager;
  constructor(roomManager: any) {
    this.roomManager = roomManager;
    this.validationResultContainer = new THREE.Group();
    this.validationResultContainer.userData.type = SceneEntityType.ValidationResultContainer;
    this.validationResultContainer.name = "Validation Result Container";
    this.setContainer();

    const gravityTool = new GravityLoadsValidationTool(roomManager);
    this.modeToolMap.set(ValidationMode.StrGravityLoads, gravityTool);
    this.modeToolMap.set(ValidationMode.StrShearCapacity, new ShearCapacityValidationTool(roomManager, gravityTool));
    this.modeToolMap.set(ValidationMode.MepPlumbing, new PlumbingValidationTool(roomManager));
    this.modeToolMap.set(ValidationMode.ArcAreaValidation, new ArcAreaValidationTool(roomManager));
    this.modeToolMap.set(ValidationMode.ArcWallTypes, new WallTypesValidationTool(roomManager, gravityTool));
    this.modeToolMap.set(ValidationMode.ArcGap, new GapValidationTool(roomManager));
  }

  public get isActive() {
    return appModel.activeValidationMode !== ValidationMode.None;
  }

  public performValidation(mode?: ValidationMode) {
    this.modeToolMap.get(mode || appModel.activeValidationMode).performValidation();
  }

  public removeValidationVisualization() {
    if (this.validationResultContainer.children.length) {
      GeometryUtils.disposeObject(this.validationResultContainer);
    }
    [...this.modeToolMap.values()].forEach(tool => tool.removeValidationVisualization?.());
  }
  public getValidator(mode?: ValidationMode) {
    return this.modeToolMap.get(mode || appModel.activeValidationMode);
  }
  public visualizeValidationResult(mode?: ValidationMode, floorId?: string) {
    const floor = floorId || appModel.activeFloor.id;
    this.removeValidationVisualization();
    this.modeToolMap.get(mode || appModel.activeValidationMode)?.visualizeValidationResult(this.validationResultContainer, floor);
  }

  public getFloorValidationResult(mode?: ValidationMode, floorId?: string): ValidationResult {
    const floor = floorId || appModel.activeFloor.id;
    return this.modeToolMap.get(mode || appModel.activeValidationMode)?.getFloorValidationResult(floor);
  }

  public getResultContainer(): THREE.Group {
    return this.validationResultContainer;
  }

  public setContainer(): void {
    this.roomManager.getSoRoot().add(this.validationResultContainer);
  }

  public reset(full = false) {
    [...this.modeToolMap.values()].forEach(tool => tool.resetResult(full));
  }

  public performPlmValidation(soFloor: THREE.Object3D) {
    if (!soFloor) {
      return;
    }
    (this.modeToolMap.get(ValidationMode.MepPlumbing) as PlumbingValidationTool).validateFloors(soFloor.userData.index);
  }
  public getExtendedWallTypesResult() {
    const result = (this.modeToolMap.get(ValidationMode.ArcWallTypes) as WallTypesValidationTool).getExtendedValidationResult();
    const { nonStacked, stacked, beams } = (this.modeToolMap.get(ValidationMode.StrGravityLoads) as GravityLoadsValidationTool).getSegmentsResult();
    const shear = (this.modeToolMap.get(ValidationMode.StrShearCapacity) as ShearCapacityValidationTool).shearCapacityCheck({
      stacked: stacked,
      nonStacked: nonStacked,
    });
    return { ...result, shear, beams, stacked, nonStacked };
  }

  public getSpatialWallsExtendedResult() {
    return (this.modeToolMap.get(ValidationMode.ArcWallTypes) as WallTypesValidationTool).performExtendedValidation();
  }

  public getGravityLoadsErrors(floorId?: string) {
    return (this.modeToolMap.get(ValidationMode.StrGravityLoads) as GravityLoadsValidationTool).getValidationResultErrors(floorId);
  }
}
