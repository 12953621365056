import RoomManager from "../../managers/SceneManager/SceneManager";
import SceneUtils from "../../utils/SceneUtils";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

export class RotateRoomContentCommand extends RoomCommand {
  constructor(
    roomId: string,
    private angle: number
  ) {
    super(roomId);

    this.type = CommandTypes.RotateRoomContentCommand;
  }

  apply(roomManager: RoomManager): void {
    const room = roomManager.getActiveFloorSoRoom(this.entityId);
    SceneUtils.rotateRoomContent(room, this.angle);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
  undo(roomManager: RoomManager): void {
    const room = roomManager.getActiveFloorSoRoom(this.entityId);
    SceneUtils.rotateRoomContent(room, -this.angle);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}
