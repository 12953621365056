import * as THREE from "three";
import { appModel } from "../../../models/AppModel";
import {
  PLM_INVALID_PLUMBING_POINT_COLOR,
  PLM_PIPE_EXTEND_CIRCLE_COLOR,
  PLM_ROOM_BELOW_PLANE_COLOR,
  PLM_VALID_PLUMBING_POINT_COLOR,
  PLM_BLOCKED_EXTERIOR_WALL_COLOR,
  PLM_OPTIONAL_EXTERIOR_WALL_COLOR,
  ROOM_PLUMBING_SANITY_ERROR,
  ROOM_PLUMBING_SANITY_WARNING,
  STR_VALIDATION_WALL_SEGMENT_RENDER_ORDER,
  PLM_POINT_RENDER_ORDER,
} from "../../consts";
import RoomManager from "../../managers/SceneManager/SceneManager";
import { IPlumbingValidationResult } from "../../models/ValidationResult";
import SceneUtils from "../../utils/SceneUtils";
import { IValidationTool } from "./IValidationTool";
import { WallAnalysisUtils } from "../../utils/WallAnalysisUtils";
import { Segment } from "../../models/segments/Segment";
import { settings } from "../../../entities/settings";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import MathUtils from "../../utils/MathUtils";
import { Direction } from "../../models/Direction";
import { PlumbingSanity } from "../../../models/PlumbingSanity";
import { MessageKindsEnum, showToastMessage } from "../../../helpers/messages";
import ShearCapacityValidationTool from "./ShearCapacityValidationTool";
import UnitsUtils from "../../utils/UnitsUtils";
import RoomUtils from "../../utils/RoomUtils";
import SceneManager from "../../managers/SceneManager/SceneManager";
import { GraphAnalysisUtils } from "../../utils/GraphAnalysisUtils";
import FloorUtils from "../../utils/FloorUtils";

export interface IPlumbingPointSanityResult {
  plumbingPointId: string; // plm point uuid
  isValid: boolean;
  pointPosition: THREE.Vector3;
  closestWallPosition: THREE.Vector3;
  roomId: string;
  optionalExteriorWalls?: Segment[];
  pipeExtent?: number;
  roomBelowId?: string;
  blockedExteriorWalls?: Segment[];
}
export default class PlumbingValidationTool implements IValidationTool {
  private validationResult: Map<string, IPlumbingPointSanityResult[]> = new Map<string, IPlumbingPointSanityResult[]>();
  private blockedSegments: Map<string, Segment[]> = new Map<string, Segment[]>();
  private optionalSegments: Map<string, Segment[]> = new Map<string, Segment[]>();
  private internalSegments: Map<string, Segment[]> = new Map<string, Segment[]>();
  private failedPlumbingPoints: Map<string, string[]> = new Map<string, string[]>();
  private analysisUtils = this.roomManager instanceof SceneManager ? GraphAnalysisUtils : WallAnalysisUtils;
  constructor(private roomManager: RoomManager) {}

  public validateFloors(minIndex: number, clipSegments = false): void {
    this.updateShearWallsWithPlumbingOffsets();
    const failedFloors = [];
    const isPrevResultEmpty = this.failedPlumbingPoints.size === 0;
    this.roomManager.getSoFloorsRoot().children.forEach(soFloor => {
      if (soFloor.userData.index < minIndex) {
        return;
      }
      const floorSoRooms = soFloor.children.filter(soRoom => SceneUtils.hasRoomPLMPoints(soRoom));
      if (!floorSoRooms.length) {
        return;
      }
      this.validateRoomsV2(floorSoRooms);
      if (clipSegments) {
        this.updateSegmentToPipeExtent(soFloor);
      }

      const failedPoints = this.validationResult
        .get(soFloor.userData.id)
        .filter(r => !r.isValid)
        .map(r => r.plumbingPointId);
      const prevFailedPoints = this.failedPlumbingPoints.get(soFloor.userData.id) || [];
      const didNewPointsFail = failedPoints.some(fp => prevFailedPoints.indexOf(fp) < 0);
      this.failedPlumbingPoints.set(soFloor.userData.id, failedPoints);
      if (didNewPointsFail) {
        failedFloors.push(soFloor.name);
      }
    });
  }

  //Validation that happens on switch to the Plumbing tab in Validations panel.
  public performValidation(): void {
    this.resetResult();
    this.validateFloors(0, true);
  }

  // Visualization of performValidation result
  public visualizeValidationResult(container: THREE.Group, floorId: string): void {
    this.roomManager.getActiveSoFloor().children.forEach(soRoom => {
      SceneUtils.getRoomPLMPoints(soRoom).forEach(point => (point.visible = false));
    });

    const renderedRoomBelowIds = [];
    this.validationResult.get(floorId)?.forEach(result => {
      this.renderSegmentsInsideCircle(result, container, floorId);
      this.renderPlumbingPoint(result, container);

      if (result.roomBelowId && !renderedRoomBelowIds.includes(result.roomBelowId)) {
        renderedRoomBelowIds.push(result.roomBelowId);
        const soRoomBelow = this.roomManager.getCorePlanSoRoom(result.roomBelowId);
        const bbox = RoomUtils.getRoomBoundingBoxByModelLines(soRoomBelow);
        container.add(SceneUtils.createRoomBboxPlane(bbox, PLM_ROOM_BELOW_PLANE_COLOR, 0.13));
      }
    });
  }

  private renderSegmentsInsideCircle(result: IPlumbingPointSanityResult, container: THREE.Group, floorId: string) {
    if (!result.pipeExtent) {
      return;
    }

    container.add(SceneUtils.createDashedCircle(result.pointPosition, result.pipeExtent, PLM_PIPE_EXTEND_CIRCLE_COLOR));

    const thickness = UnitsUtils.getSyntheticWallHalfSize();
    result.blockedExteriorWalls?.forEach(segment => {
      container.add(SceneUtils.createSegmentPlane(segment, thickness, PLM_BLOCKED_EXTERIOR_WALL_COLOR));
    });
    result.optionalExteriorWalls?.forEach(segment => {
      container.add(SceneUtils.createSegmentPlane(segment, thickness, PLM_OPTIONAL_EXTERIOR_WALL_COLOR, STR_VALIDATION_WALL_SEGMENT_RENDER_ORDER + 1));
    });
  }

  public getFloorValidationResult(): IPlumbingValidationResult[] {
    return appModel.activeCorePlan.floors.flatMap(
      floor =>
        this.validationResult.get(floor.id)?.map(result => {
          const roomType = appModel.getRoomType(appModel.activeCorePlan.getRoom(result.roomId).roomTypeId);
          const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
          return {
            roomName: `${floor.name} - ${roomCategory.name} - ${roomType.name}`,
            floorId: floor.id,
            ...result,
          };
        }) || []
    );
  }

  public removeValidationVisualization(): void {
    this.roomManager.getActiveSoFloor()?.children.forEach(soRoom => {
      SceneUtils.getRoomPLMPoints(soRoom).forEach(point => (point.visible = true));
    });
  }

  public resetResult(full = false) {
    this.validationResult.clear();
    this.blockedSegments.clear();
    this.optionalSegments.clear();
    if (full) {
      this.failedPlumbingPoints.clear();
    }
  }

  //--------------------------------------------

  private updateShearWallsWithPlumbingOffsets() {
    const stackedWalls = new Map<string, Segment[]>();
    appModel.activeCorePlan.floors.forEach(f => {
      const soFloor = this.roomManager.getSoFloor(f.id);
      const { externalSegments, blockedExternalSegments, internalSegments } = this.analysisUtils.getSegmentsWithContourOffsets(
        FloorUtils.getFloorSoRooms(soFloor)
      );
      this.blockedSegments.set(f.id, blockedExternalSegments);
      this.internalSegments.set(f.id, internalSegments);
      stackedWalls.set(f.id, externalSegments);
    });

    const floors = [...appModel.activeCorePlan.floors].sort((a, b) => a.index - b.index);
    for (let i = 0; i < floors.length; i++) {
      const floorId = floors[i].id;
      const lowerFloorId = floors[i - 1]?.id;
      const walls = stackedWalls.get(floorId);

      if (lowerFloorId) {
        this.optionalSegments.set(floorId, []);
        const lowerShearWalls = this.optionalSegments.get(lowerFloorId).map(sw => new Segment(sw.start.clone(), sw.end.clone()));
        walls.forEach(wall => {
          ShearCapacityValidationTool.extractShearNonShearWalls(
            wall,
            lowerShearWalls,
            0,
            this.optionalSegments.get(floorId),
            this.blockedSegments.get(floorId)
          );
        });
      } else {
        this.optionalSegments.set(floorId, walls);
      }
    }
  }

  private renderPlumbingPoint(result: IPlumbingPointSanityResult, container: THREE.Group) {
    const color = result.isValid ? PLM_VALID_PLUMBING_POINT_COLOR : PLM_INVALID_PLUMBING_POINT_COLOR;
    const renderOrder = PLM_POINT_RENDER_ORDER + (result.isValid ? 1 : 0);
    if (result.closestWallPosition) {
      container.add(SceneUtils.createCircle(result.pointPosition, color, renderOrder));
      container.add(SceneUtils.createPLMRing(result.closestWallPosition, color, renderOrder));
      const line = new THREE.Line3(result.pointPosition.clone(), result.closestWallPosition.clone());
      const direction = new THREE.Vector3().subVectors(line.end, line.start).normalize();

      const lengthToRemove = 5;
      line.start.addScaledVector(direction, lengthToRemove);
      line.end.addScaledVector(direction, -lengthToRemove);
      container.add(SceneUtils.createAngledSegmentPlane(line, color, renderOrder));
    } else {
      container.add(SceneUtils.createPLMRing(result.pointPosition, color, renderOrder));
    }
  }

  private validateRoomsV2(soRooms: THREE.Object3D[]): void {
    const soFloor = soRooms[0].parent;
    if (soFloor.userData.index === 0) {
      this.validateRoomsOfFirstFloor(soRooms);
    } else {
      this.validateRoomsOfNonFirstFloor(soRooms);
    }
  }
  private validateRoomsOfFirstFloor(soRooms: THREE.Object3D[]): void {
    // Plumbing points should be placed within a distance of X feet (should be a system settings parameter) from an exterior wall.
    // An alert should be prompt if a point is outside of that range.
    const maxDistance = settings.values.validationSettings.firstFloorPlumbingPointMaxOffset; // 3 feet by default;

    const soFloor = soRooms[0].parent;
    const soFloorRooms = SceneUtils.getFloorRooms(soFloor);
    const externalSegments = this.analysisUtils.getSegmentsWithContourOffsets(FloorUtils.getFloorSoRooms(soFloor), false).externalSegments;
    const internalSegments = this.internalSegments.get(soFloor.userData.id);

    soRooms.forEach(soRoom => {
      SceneUtils.getRoomPLMPoints(soRoom).forEach(plmPoint => {
        const plmPointCenter = new THREE.Box3().setFromObject(plmPoint).getCenter(new THREE.Vector3());

        let closestDistance = Infinity;
        let closestPoint: THREE.Vector3 = null;
        const optionalExteriorWalls: Segment[] = [];
        const blockedExteriorWalls: Segment[] = [];
        for (const segment of externalSegments) {
          const segmentPoint = segment.toLine3().closestPointToPoint(plmPointCenter, true, new THREE.Vector3());
          const distance = segmentPoint.distanceTo(plmPointCenter);
          if (distance < maxDistance) {
            if (
              segment.roomId === soRoom.userData.id ||
              internalSegments.some(is => GeometryUtils.doLinesIntersect(is.start, is.end, plmPointCenter, segmentPoint))
            ) {
              optionalExteriorWalls.push(segment);
            } else {
              blockedExteriorWalls.push(segment);
            }
          }
          if (distance < closestDistance) {
            closestDistance = distance;
            closestPoint = segmentPoint.clone();
          }
        }

        const result = {
          roomId: soRoom.userData.id,
          pointPosition: plmPointCenter,
          plumbingPointId: plmPoint.uuid,
          closestWallPosition: closestPoint,
          isValid: closestDistance <= maxDistance,
          optionalExteriorWalls,
          blockedExteriorWalls,
          pipeExtent: settings.values.validationSettings.firstFloorPlumbingPointMaxOffset,
        } as IPlumbingPointSanityResult;

        const floorResult = this.validationResult.get(soFloor.userData.id);
        if (floorResult) {
          const prevPointResultIndex = floorResult.findIndex(pr => pr.plumbingPointId === result.plumbingPointId);
          if (prevPointResultIndex >= 0) {
            floorResult.splice(prevPointResultIndex, 1);
          }
          floorResult.push(result);
        } else {
          this.validationResult.set(soFloor.userData.id, [result]);
        }
        GeometryUtils.setChildrenLinesColor(plmPoint, result.isValid ? PLM_VALID_PLUMBING_POINT_COLOR : PLM_INVALID_PLUMBING_POINT_COLOR, true);
      });
    });
    return;
  }
  private validateRoomsOfNonFirstFloor(soRooms: THREE.Object3D[]): void {
    // Plumbing point Max Range parameter on hold for V2.
    // Below rooms without "ceiling depth" parameter cannot allow pipe routing above them.

    // Validate that the PLM point can be routed to an exterior wall on the floor below,
    // that is continuous to the ground without obstructions, and is within a feasible distance.
    // The distance should be measured to the nearest exterior wall, and is representing the pipe length (PL).
    // ● The exported stretch ceiling depth of the room on the floor below (CD) should be used to calculate the pipe fall (PF) by subtracting a fixed tolerance of 4” from it (should be a system settings parameter).
    // ● The pipe slope (PS) should be calculated to validate that it is not below the required minimum of equal or over ¼” drop every foot, PS= PF /PL >= 2%.

    // The pipe route should find shortest line possible to a nearest exterior wall, while avoiding the following:
    // ● Exterior wall containing an opening,
    // ● Pipe should keep an opening structural tolerance of 4.5” (should be a system settings parameter).
    // ● Pipe should keep an Edge of exterior wall tolerance of 4.5” (should be a system settings parameter)
    // ● Pipe route cannot cross between rooms to reach an exterior wall.
    // ● Future feature: The angle of pipe to wall connection (a) should affect the allowable length of the pipe routing, and should add a penalty to the line length if the angle is sharper than 45 degrees.

    const soFloor = soRooms[0].parent;
    const soFloorBelow = soFloor.parent.children.find(x => x.userData.index === soFloor.userData.index - 1);
    const soRoomsBelow = SceneUtils.getFloorRooms(soFloorBelow).filter(soRoom => appModel.getRoomType(soRoom.userData.roomTypeId).attributes.indoor);

    soRooms.forEach(soRoom => {
      SceneUtils.getRoomPLMPoints(soRoom).forEach(plmPoint => {
        const plmPointCenter = new THREE.Box3().setFromObject(plmPoint).getCenter(new THREE.Vector3());

        const validSegmentPoints: THREE.Vector3[] = [];
        const invalidSegmentPoints: THREE.Vector3[] = [];
        const optionalExteriorWalls: Segment[] = [];

        const soValidRoomBelow = soRoomsBelow.find(soRoomBelow => {
          const ceilingDepth = (appModel.getRoomType(soRoomBelow.userData.roomTypeId).attributes.ceilingHeightOffsetFromLevel || 0.0) as number;
          return ceilingDepth > 0.0 && this.isPLMPointAboveRoom(plmPoint, soRoomBelow);
        });
        let pipeExtent = null;
        if (soValidRoomBelow) {
          // Check CD on room below and calculate PF, possible PL range within 2% min PS.
          // Determine point routing to the closest exterior wall below, without obstructions (on all floors below) or crossing rooms.

          const CD = (appModel.getRoomType(soValidRoomBelow.userData.roomTypeId).attributes.ceilingHeightOffsetFromLevel || 0.0) as number;
          const PF = CD - settings.values.validationSettings.pipeFallTolerance; // tolerance is 4 inches by default
          pipeExtent = PF / 0.02;
          const externalRoomSegmentsBelow = this.optionalSegments.get(soFloorBelow.userData.id);
          const externalSegments = externalRoomSegmentsBelow.filter(s => s.roomId === soValidRoomBelow.userData.id);
          for (const segment of externalSegments) {
            const segmentPoint = segment.toLine3().closestPointToPoint(plmPointCenter, true, new THREE.Vector3());
            const PL = segmentPoint.distanceTo(plmPointCenter); // pipe length, PL

            // The pipe slope (PS) should be calculated to validate that it is not below the required minimum of equal or over 1/4 foot drop every foot
            const PS = PF / PL; // should be >= 2%;

            if (PS >= 0.02) {
              const isInvalid = this.isPointWithinAnyOpeningBelow(segmentPoint, soFloor);
              (isInvalid ? invalidSegmentPoints : validSegmentPoints).push(segmentPoint);
              if (!isInvalid) {
                optionalExteriorWalls.push(segment);
              }
            } else {
              invalidSegmentPoints.push(segmentPoint);
            }
          }

          const roomBelowBbox = RoomUtils.getRoomBoundingBoxByModelLines(soValidRoomBelow);
          const maxCornerDistance = Math.max(...getBoundingBoxPoints(roomBelowBbox).map(corner => corner.distanceTo(plmPointCenter)));
          if (pipeExtent && pipeExtent > maxCornerDistance) {
            pipeExtent = maxCornerDistance;
          }
        }

        const floorResult = this.validationResult.get(soFloor.userData.id);
        const points = validSegmentPoints.length ? validSegmentPoints : invalidSegmentPoints;
        const sortedPoints = points.map(point => ({ distance: point.distanceTo(plmPointCenter), point })).sort((a, b) => a.distance - b.distance);

        const result = {
          roomId: soRoom.userData.id,
          pointPosition: plmPointCenter,
          plumbingPointId: plmPoint.uuid,
          closestWallPosition: sortedPoints[0]?.point,
          isValid: validSegmentPoints.length != 0,
          roomBelowId: soValidRoomBelow?.userData.id,
          optionalExteriorWalls,
          pipeExtent,
        } as IPlumbingPointSanityResult;

        if (floorResult) {
          const prevPointResultIndex = floorResult.findIndex(pr => pr.plumbingPointId === result.plumbingPointId);
          if (prevPointResultIndex >= 0) {
            floorResult.splice(prevPointResultIndex, 1);
          }
          floorResult.push(result);
        } else {
          this.validationResult.set(soFloor.userData.id, [result]);
        }
        GeometryUtils.setChildrenLinesColor(plmPoint, result.isValid ? PLM_VALID_PLUMBING_POINT_COLOR : PLM_INVALID_PLUMBING_POINT_COLOR, true);
      });
    });

    function getBoundingBoxPoints(bb: THREE.Box3): THREE.Vector3[] {
      return [bb.min, new THREE.Vector3(bb.min.x, bb.max.y), bb.max, new THREE.Vector3(bb.max.x, bb.min.y)];
    }
  }

  private isPLMPointAboveRoom(point: THREE.Object3D, soRoom: THREE.Object3D): boolean {
    const bb = new THREE.Box3().setFromObject(point);
    bb.min.z = bb.max.z = 0;
    const center = bb.getCenter(new THREE.Vector3());

    const bb1 = RoomUtils.getRoomBoundingBox(soRoom);
    bb1.min.z = bb1.max.z = 0;

    return bb1.containsPoint(center);
  }

  private isPointWithinAnyOpeningBelow(point: THREE.Vector3, soFloor: THREE.Object3D): boolean {
    const p = point.clone();
    p.z = 0;

    const soFloorsBelow = soFloor.parent.children.filter(x => x.userData.index < soFloor.userData.index && x.userData.index >= 0);
    const soRooms = soFloorsBelow.map(soFloor => SceneUtils.getFloorRooms(soFloor)).flat();
    const soOpenings = soRooms.map(soRoom => SceneUtils.getRoomOpenings(soRoom)).flat();

    return soOpenings.some(soOpening => {
      const bb = new THREE.Box3().setFromObject(soOpening);
      bb.min.z = bb.max.z = 0;

      return bb.containsPoint(p);
    });
  }

  private updateSegmentToPipeExtent(soFloor: THREE.Object3D): void {
    const segmentsFloorId =
      soFloor.userData.index > 0
        ? this.roomManager.getSoFloorsRoot().children.find(floor => floor.userData.index === soFloor.userData.index - 1)?.userData.id
        : soFloor.userData.id;
    this.validationResult.get(soFloor.userData.id)?.forEach(result => {
      if (!result.pipeExtent) {
        return;
      }

      const clippedOptionalSegments = [];
      const clippedBlockedSegments = [];
      const sphere = new THREE.Sphere(result.pointPosition, result.pipeExtent);
      result.optionalExteriorWalls?.forEach(segment => {
        extractSphereSegment(segment, sphere, clippedOptionalSegments);
      });
      result.blockedExteriorWalls?.forEach(segment => {
        extractSphereSegment(segment, sphere, clippedBlockedSegments);
      });

      if (result.isValid) {
        this.blockedSegments.get(segmentsFloorId).forEach(segment => {
          extractSphereSegment(segment, sphere, clippedBlockedSegments);
        });
        this.optionalSegments.get(segmentsFloorId).forEach(segment => {
          if (segment.roomId[0] === result.roomId[0]) {
            return;
          }
          extractSphereSegment(segment, sphere, clippedBlockedSegments);
        });
      }

      result.optionalExteriorWalls = clippedOptionalSegments;
      result.blockedExteriorWalls = clippedBlockedSegments;
    });

    function extractSphereSegment(segment: Segment, sphere: THREE.Sphere, segments: Segment[]) {
      const sphereLine = GeometryUtils.getLineSegmentInsideSphere(segment.toLine3(), sphere);
      if (sphereLine) {
        segments.push(Segment.fromLine3(sphereLine));
      }
    }
  }

  //---------------------------------------
  // Currently deprecated but may still be used in the future.
  private validateRoomsV1(soRoomsWithPlumbingWalls: THREE.Object3D[]): void {
    const soFloor = soRoomsWithPlumbingWalls[0].parent;
    const soFloorBelow = soFloor.parent.children.find(x => x.userData.index === soFloor.userData.index - 1);

    const soRoomsBelow = SceneUtils.getFloorRooms(soFloorBelow);

    let sanityResult = PlumbingSanity.OK;
    soRoomsWithPlumbingWalls.forEach(soRoom => {
      soRoomsBelow.forEach(soRoomBelow => {
        sanityResult = Math.max(sanityResult, this.checkPLMSanity(soRoom, soRoomBelow));
      });
    });

    if ((sanityResult as PlumbingSanity) === PlumbingSanity.Warning) {
      showToastMessage(MessageKindsEnum.Error, ROOM_PLUMBING_SANITY_WARNING, { autoClose: 700 });
    }
    if ((sanityResult as PlumbingSanity) === PlumbingSanity.Error) {
      showToastMessage(MessageKindsEnum.Error, ROOM_PLUMBING_SANITY_ERROR, { autoClose: 700 });
    }
  }
  private checkPLMSanity(soRoom: THREE.Object3D, soRoomOther: THREE.Object3D): PlumbingSanity {
    if (SceneUtils.areRoomsIntersectingHorizontally(soRoom, soRoomOther)) {
      const plmWallsOther = SceneUtils.getRoomPLMWalls(soRoomOther);
      const openings = SceneUtils.getRoomOpenings(soRoomOther);
      const plmPoints = SceneUtils.getRoomPLMPoints(soRoom);

      if (plmWallsOther.length > 0) {
        const roomType = appModel.getRoomType(soRoomOther.userData.roomTypeId);
        const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);

        const plmWalls = SceneUtils.getRoomPLMWalls(soRoom);

        let res0 = PlumbingSanity.OK;

        for (const plmWall of plmWalls) {
          for (const plmWallOther of plmWallsOther) {
            let res = PlumbingSanity.OK;
            if (GeometryUtils.doObjectsIntersect3D(plmWall, plmWallOther)) {
              if (roomCategory.isBathroom) {
                res = this.isPLMPointWithinRange(soRoom, plmWall, soRoomOther, plmWallOther) ? PlumbingSanity.OK : PlumbingSanity.Error;
              } else {
                res = PlumbingSanity.OK;
              }
            } else {
              res = this.arePLMPointsBlockedByOpenings(plmPoints, openings) ? PlumbingSanity.Warning : PlumbingSanity.Error;
            }
            res0 = Math.max(res0, res);
          }
        }

        return res0;
      } else {
        return this.arePLMPointsBlockedByOpenings(plmPoints, openings) ? PlumbingSanity.Warning : PlumbingSanity.Error;
      }
    }

    return PlumbingSanity.OK;
  }
  private arePLMPointsBlockedByOpenings(plmPoints: THREE.Object3D[], soOpenings: THREE.Object3D[]): boolean {
    return plmPoints.some(plmPoint => {
      const bb = new THREE.Box3().setFromObject(plmPoint);
      bb.min.z = bb.max.z = 0;

      return soOpenings.some(soOpening => {
        const bbo = new THREE.Box3().setFromObject(soOpening);
        bbo.min.z = bbo.max.z = 0;

        return bb.intersectsBox(bbo);
      });
    });
  }
  private isPLMPointWithinRange(plmSoRoom: THREE.Object3D, plmSoWall: THREE.Object3D, plmSoRoomOther: THREE.Object3D, plmSoWallOther: THREE.Object3D): boolean {
    //check walls status
    const plmWallBb = GeometryUtils.getGeometryBoundingBox3D(plmSoWall);
    const plmWallDirection = plmWallBb.max.x - plmWallBb.min.x > plmWallBb.max.y - plmWallBb.min.y ? Direction.Horizontal : Direction.Vertical;

    const plmWallOtherBb = GeometryUtils.getGeometryBoundingBox3D(plmSoWallOther);
    const plmWallOtherDirection =
      plmWallOtherBb.max.x - plmWallOtherBb.min.x > plmWallOtherBb.max.y - plmWallOtherBb.min.y ? Direction.Horizontal : Direction.Vertical;

    const plmCenterPoints: {
      center: THREE.Vector3;
      bb: THREE.Box3;
      maxCollinear: number;
      maxPerpendicular: number;
    }[] = [];
    SceneUtils.getRoomPLMPoints(plmSoRoom).forEach(point => {
      const bb = new THREE.Box3().setFromObject(point);
      const center = new THREE.Vector3();
      bb.getCenter(center);
      const maxCollinear: number = point.userData.collinearRange ?? 0;
      const maxPerpendicular: number = point.userData.perpendicularRange ?? 0;
      plmCenterPoints.push({ center, bb, maxCollinear, maxPerpendicular });
    });

    const plmOtherCenterPoints: {
      center: THREE.Vector3;
      bb: THREE.Box3;
      maxCollinear: number;
      maxPerpendicular: number;
    }[] = [];
    SceneUtils.getRoomPLMPoints(plmSoRoomOther).forEach(point => {
      const bb = new THREE.Box3().setFromObject(point);
      const center = new THREE.Vector3();
      bb.getCenter(center);
      const maxCollinear = point.userData.collinearRange ?? 0;
      const maxPerpendicular = point.userData.perpendicularRange ?? 0;
      plmOtherCenterPoints.push({ center, bb, maxCollinear, maxPerpendicular });
    });

    return plmCenterPoints.some(plm => {
      if (plmOtherCenterPoints.length > 0) {
        return plmOtherCenterPoints.some(plmOther => {
          let result = false;
          if (plm.bb.intersectsBox(plmWallOtherBb)) {
            if (plmWallDirection === plmWallOtherDirection) {
              if (plmWallDirection === Direction.Horizontal) {
                if (MathUtils.isNumberInRange(plm.center.x, plmOther.center.x - plmOther.maxCollinear / 2, plmOther.center.x + plmOther.maxCollinear / 2)) {
                  result = true;
                }
              } else {
                if (MathUtils.isNumberInRange(plm.center.y, plmOther.center.y - plmOther.maxCollinear / 2, plmOther.center.y + plmOther.maxCollinear / 2)) {
                  result = true;
                }
              }
            } else {
              if (plmWallOtherDirection === Direction.Horizontal) {
                if (MathUtils.isNumberInRange(plm.center.x, plmOther.center.x - plmOther.maxCollinear / 2, plmOther.center.x + plmOther.maxCollinear / 2)) {
                  result = true;
                }
              } else {
                if (MathUtils.isNumberInRange(plm.center.y, plmOther.center.y - plmOther.maxCollinear / 2, plmOther.center.y + plmOther.maxCollinear / 2)) {
                  result = true;
                }
              }
            }
          }
          return result;
        });
      } else {
        let result = false;
        if (plm.bb.intersectsBox(plmWallOtherBb)) {
          if (plmWallDirection !== plmWallOtherDirection) {
            if (plmWallDirection === Direction.Vertical) {
              const belowWallCenter = (plmWallOtherBb.max.y - plmWallOtherBb.max.y) / 2;
              if (MathUtils.isNumberInRange(belowWallCenter, plm.center.y - plm.maxPerpendicular / 2, plm.center.y + plm.maxPerpendicular / 2)) {
                result = true;
              }
            } else {
              const belowWallCenter = (plmWallOtherBb.max.x - plmWallOtherBb.max.x) / 2;
              if (MathUtils.isNumberInRange(belowWallCenter, plm.center.x - plm.maxPerpendicular / 2, plm.center.x + plm.maxPerpendicular / 2)) {
                result = true;
              }
            }
          }
        }
        return result;
      }
    });
  }
}
