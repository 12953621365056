import { soGroup } from "../soGroup";
import { soDataBoxLine } from "./soDataBoxLine";

/**
 * Class representing the boundary of a room.
 * Inherits from soGroup to allow handling multiple soDataBoxLines.
 */
export class soDataBox extends soGroup {
  dataBoxLines: soDataBoxLine[];
  dataBoxRoomId: string;
  isVisible: boolean = true;
  /**
   * Constructs an instance of RoomBoundary.
   * @param dataBoxLines - Array of soDataBoxLine objects representing the databox.
   */
  constructor(dataBoxRoomId: string, dataBoxLines: soDataBoxLine[], isVisible: boolean) {
    super();
    this.dataBoxRoomId = dataBoxRoomId;
    this.dataBoxLines = dataBoxLines;
    this.isVisible = isVisible;

    if (dataBoxLines?.length > 0) {
      this.add(...dataBoxLines);
    }
  }
}
