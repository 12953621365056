import * as THREE from "three";

import { areaToEnUsLocaleString } from "../../../helpers/measures";
import { appModel } from "../../../models/AppModel";
import {
  ARC_AREA_VALIDATION_BORDER_RENDER_ORDER,
  ARC_AREA_VALIDATION_ERROR_BORDER_RENDER_ORDER,
  ARC_AREA_VALIDATION_FILL_RENDER_ORDER,
  ARC_AREA_VALIDATION_OUTDOOR_BORDER_RENDER_ORDER,
  ARC_LIVEABLE_AREA_BORDER_COLOR,
  ARC_LIVEABLE_AREA_FILL_COLOR,
  ARC_OUTDOOR_AREA_BORDER_COLOR,
  ARC_OUTDOOR_AREA_FILL_COLOR,
  ARC_GARAGE_AREA_FILL_COLOR,
  EPSILON,
  INTERSECTED_COLOR,
} from "../../consts";
import RoomManager from "../../managers/SceneManager/SceneManager";
import { FloorSpace, Space } from "../../models/FloorSpaceTree";
import { Edge, GraphManager, Vertex } from "../../models/GraphManager";
import { MergeSegmentsMode } from "../../models/SegmentsMergeMode";
import { AreaTakeOff, IArcAreaValidationResult } from "../../models/ValidationResult";
import { Segment } from "../../models/segments/Segment";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import MathUtils from "../../utils/MathUtils";
import SceneUtils from "../../utils/SceneUtils";
import SegmentsUtils from "../../utils/SegmentsUtils";
import UnitsUtils from "../../utils/UnitsUtils";
import { WallAnalysisUtils } from "../../utils/WallAnalysisUtils";
import { IValidationTool } from "./IValidationTool";
import { catalogSettings } from "../../../entities/catalogSettings";
import { FuncCode } from "../../../entities/catalogSettings/types";
import { ValidationItemType } from "../../../ui/components/Editor/LeftBar/ValidationPanel/ValidationItem";
import { settings } from "../../../entities/settings";
import RoomUtils from "../../utils/RoomUtils";
import VectorUtils from "../../utils/GeometryUtils/VectorUtils";
import { GraphAnalysisUtils } from "../../utils/GraphAnalysisUtils";
import SceneManager from "../../managers/SceneManager/SceneManager";

/**
 * Architectural Validation Tool.
 */
export default class ArcAreaValidationTool implements IValidationTool {
  private validationResult: IArcAreaValidationResult = null;
  private floorsData: Map<string, FloorData> = new Map();
  private floorsAreaContours: Map<string, FloorAreaContours> = new Map();
  private analysisUtils = this.roomManager instanceof SceneManager ? GraphAnalysisUtils : WallAnalysisUtils;
  constructor(private roomManager: any) {}

  /**
   * Validation that happens on switch to the tab in Validations panel.
   */
  public performValidation(): void {
    this.resetResult();
    this.findAreaContours();
    this.calculateAreas();
    this.setResult();
  }

  public getFloorValidationResult(): IArcAreaValidationResult {
    return this.validationResult;
  }

  public resetResult(): void {
    this.validationResult = null;
  }

  /**
   * Visualization of performValidation result.
   */
  public visualizeValidationResult(container: THREE.Group, floorId: string): void {
    const floorData = this.floorsData.get(floorId);
    const floorContours = this.floorsAreaContours.get(floorId);

    const addAreaMeshes = (floorSpaces: FloorSpace[], color: string, renderOrder: number) => {
      floorSpaces.forEach(fs => {
        container.add(SceneUtils.createArcAreaValidationContour(fs, color, renderOrder));
      });
    };

    const addAreaSegments = (segments: Segment[], color: number, renderOrder: number) => {
      const width = 2;
      segments.forEach(segment => {
        container.add(SceneUtils.createSegmentMesh(segment, width, color, renderOrder));
      });
    };

    const getHexColor = colorNumber => "#" + colorNumber.toString(16).padStart(6, "0");

    const activeAreaColors = [];
    const restActiveAreaColors = { borderColor: "#000000", type: ValidationItemType.Area };
    const livingSpacesColor = settings.values.webAppUISettings.livingSpaces || getHexColor(ARC_LIVEABLE_AREA_FILL_COLOR);
    const garageColor = settings.values.webAppUISettings.garage || getHexColor(ARC_GARAGE_AREA_FILL_COLOR);
    const porchesAAndPatioColor = settings.values.webAppUISettings.porchesAndPatio || getHexColor(ARC_OUTDOOR_AREA_FILL_COLOR);

    if (floorContours.indoorAreaContour.liveableFloorSpaces.length) {
      activeAreaColors.push({ text: "Livable area", color: livingSpacesColor, ...restActiveAreaColors });
    }
    if (floorContours.indoorAreaContour.garageFloorSpaces.length) {
      activeAreaColors.push({ text: "Garage area", color: garageColor, ...restActiveAreaColors });
    }
    if (floorContours.outdoorAreaContour.floorSpaces.length) {
      activeAreaColors.push({
        text: "Porches & Patios area",
        color: porchesAAndPatioColor,
        ...restActiveAreaColors,
      });
    }

    appModel.setActiveAreaColors(activeAreaColors);

    addAreaMeshes(floorContours.indoorAreaContour.liveableFloorSpaces, livingSpacesColor, ARC_AREA_VALIDATION_FILL_RENDER_ORDER);
    addAreaMeshes(floorContours.indoorAreaContour.garageFloorSpaces, garageColor, ARC_AREA_VALIDATION_FILL_RENDER_ORDER);
    addAreaMeshes(floorContours.outdoorAreaContour.floorSpaces, porchesAAndPatioColor, ARC_AREA_VALIDATION_FILL_RENDER_ORDER);

    // Filter out interiorSegmentsToRemove from segments
    const filteredSegments = floorContours.indoorAreaContour.segments.filter(
      segment =>
        !floorContours.indoorAreaContour.interiorSegmentsToRemove.some(toRemove => toRemove.areSegmentsEqual(segment) || toRemove.overlapsSegment(segment))
    );

    addAreaSegments(filteredSegments, ARC_LIVEABLE_AREA_BORDER_COLOR, ARC_AREA_VALIDATION_BORDER_RENDER_ORDER);

    addAreaSegments([...floorContours.outdoorAreaContour.exteriorSegments], ARC_OUTDOOR_AREA_BORDER_COLOR, ARC_AREA_VALIDATION_OUTDOOR_BORDER_RENDER_ORDER);

    addAreaSegments(
      [...floorContours.indoorAreaContour.loopsSegments, ...floorContours.outdoorAreaContour.loopsSegments],
      INTERSECTED_COLOR,
      ARC_AREA_VALIDATION_ERROR_BORDER_RENDER_ORDER
    );

    floorData.roomsData.forEach(roomData => {
      container.add(SceneUtils.createArcAreaValidationLabel(roomData.name, `${areaToEnUsLocaleString(roomData.area)} sq ft`, roomData.netBox));
    });
  }

  public removeValidationVisualization(): void {}

  public calculateNetArea(soRooms: THREE.Object3D[]): number {
    const { liveableSoRooms, garageSoRooms } = ArcAreaValidationTool.sortRoomsByTypes(soRooms);
    const areaContour = this.findIndoorAreaContour(liveableSoRooms, garageSoRooms);

    const segments = SegmentsUtils.splitIntersectedSegments([...areaContour.segments, ...areaContour.loopsSegments]);
    const graph = new GraphManager<Segment>();
    segments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));

    const calculateArea = (soRooms: THREE.Object3D[]): number => {
      return soRooms.reduce((area, soRoom) => {
        const center = RoomUtils.getRoomBoundingBoxByModelLines(soRoom).getCenter(new THREE.Vector3());
        return area + SegmentsUtils.calculateAreaOfSmallestSurroundingContour(center, graph);
      }, 0);
    };

    return calculateArea(liveableSoRooms);
  }

  private setResult(): void {
    this.validationResult = {
      levelArea: appModel.activeCorePlan.floors.map(floor => {
        const floorData = this.floorsData.get(floor.id);
        const floorContours = this.floorsAreaContours.get(floor.id);

        return {
          floorId: floor.id,
          ...floorData,
          hasIntersections: floorContours.indoorAreaContour.loopsSegments.length > 0,
        };
      }),
    };
  }

  private findAreaContours(): void {
    appModel.activeCorePlan.floors.forEach(floor => {
      const soRooms = this.roomManager.getSoFloor(floor.id).children;
      const { liveableSoRooms, garageSoRooms, porchAndPatioSoRooms } = ArcAreaValidationTool.sortRoomsByTypes(soRooms);

      const indoorAreaContour = this.findIndoorAreaContour(liveableSoRooms, garageSoRooms);
      const outdoorAreaContour = this.findRoomsAreaContour(
        porchAndPatioSoRooms,
        indoorAreaContour.segments.map(s => s.clone()),
        0
      );

      this.floorsAreaContours.set(floor.id, new FloorAreaContours(indoorAreaContour, outdoorAreaContour));
    });
  }

  public calculateAreaContours(soRooms: THREE.Object3D[]): FloorAreaContours {
    const { liveableSoRooms, garageSoRooms, porchAndPatioSoRooms } = ArcAreaValidationTool.sortRoomsByTypes(soRooms);

    const indoorAreaContour = this.findIndoorAreaContour(liveableSoRooms, garageSoRooms);
    const outdoorAreaContour = this.findRoomsAreaContour(
      porchAndPatioSoRooms,
      indoorAreaContour.segments.map(s => s.clone()),
      0
    );

    return new FloorAreaContours(indoorAreaContour, outdoorAreaContour);
  }

  private calculateAreas(): void {
    appModel.activeCorePlan.floors.forEach(floor => {
      const soRooms = this.roomManager.getSoFloor(floor.id).children;
      const { liveableSoRooms, garageSoRooms, porchAndPatioSoRooms } = ArcAreaValidationTool.sortRoomsByTypes(soRooms);

      const collectRoomData = (soRoom: THREE.Object3D): RoomData => {
        const netBox = RoomUtils.getRoomNetBoundingBox(this.roomManager, soRoom);
        const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);
        const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
        return new RoomData(netBox, roomCategory.name, 0);
      };

      const liveableRoomsData = liveableSoRooms.map(collectRoomData);
      const garageRoomsData = garageSoRooms.map(collectRoomData);
      const porchAndPatioRoomsData = porchAndPatioSoRooms.map(collectRoomData);

      const floorContours = this.floorsAreaContours.get(floor.id);

      const indoorSegments = SegmentsUtils.splitIntersectedSegments([
        ...floorContours.indoorAreaContour.segments,
        ...floorContours.indoorAreaContour.loopsSegments,
      ]);

      const outdoorSegments = SegmentsUtils.splitIntersectedSegments([
        ...floorContours.outdoorAreaContour.exteriorSegments,
        ...floorContours.outdoorAreaContour.interiorSegments,
      ]);

      // Calculate Liveable Rooms Area
      calculateAreaForRooms(liveableRoomsData, indoorSegments);
      const liveableAreaValue = liveableRoomsData.reduce((area, data) => area + data.area, 0);

      // Calculate Garage Rooms Area
      calculateAreaForRooms(garageRoomsData, indoorSegments);
      const garageAreaValue = garageRoomsData.reduce((area, data) => area + data.area, 0);

      calculateAreaForRooms(porchAndPatioRoomsData, outdoorSegments);
      const porchesAreaValue = porchAndPatioRoomsData.reduce((area, data) => area + data.area, 0);

      const grossArea = liveableAreaValue + garageAreaValue;
      const livableArea = { title: "Livable area", value: liveableAreaValue };
      const garageArea = { title: "Garage area", value: garageAreaValue };
      const porchesArea = { title: "Porches & Patios area", value: porchesAreaValue };

      this.floorsData.set(
        floor.id,
        new FloorData(livableArea, garageArea, porchesArea, grossArea, liveableAreaValue, [...liveableRoomsData, ...garageRoomsData, ...porchAndPatioRoomsData])
      );
    });

    function calculateAreaForRooms(roomsData: RoomData[], segments: Segment[]): void {
      const graph = new GraphManager<Segment>();
      segments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));

      roomsData.forEach(data => {
        const area = SegmentsUtils.calculateAreaOfSmallestSurroundingContour(data.netBox.getCenter(new THREE.Vector3()), graph);
        data.area = area;
      });
    }
  }

  private static extractIndoorAreaContour(
    indoorRoomsAreaContour: AreaContour,
    liveableSoRooms: THREE.Object3D[],
    garageSoRooms: THREE.Object3D[]
  ): IndoorAreaContour {
    let segments = [...indoorRoomsAreaContour.exteriorSegments, ...indoorRoomsAreaContour.interiorSegments, ...indoorRoomsAreaContour.loopsSegments];
    segments = SegmentsUtils.splitSegments(segments, segments);

    const graph = new GraphManager<Segment>();
    segments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));

    const liveableBoxes = liveableSoRooms.map(RoomUtils.getRoomBoundingBoxByModelLines);
    const garageBoxes = garageSoRooms.map(RoomUtils.getRoomBoundingBoxByModelLines);

    const liveableRoomSides = liveableBoxes.flatMap(SegmentsUtils.collectSegmentsFromBoundingBox);
    const garageRoomSides = garageBoxes.flatMap(SegmentsUtils.collectSegmentsFromBoundingBox);

    const segmentsOnBorder = new Set<Segment>();
    const segmentsOnlyOnLiveableBorder = new Set<Segment>();
    const segmentsOnlyOnGarageBorder = new Set<Segment>();
    const segmentsOnIndoorBorder = new Set<Segment>();

    segments.forEach(segment => {
      if (segment.isHorizontal() || segment.isVertical()) {
        const isOnLiveable = liveableRoomSides.some(side => side.overlapsSegment(segment));
        const isOnGarage = garageRoomSides.some(side => side.overlapsSegment(segment));

        if (isOnLiveable && isOnGarage) {
          segmentsOnIndoorBorder.add(segment);
        } else if (isOnLiveable) {
          segmentsOnlyOnLiveableBorder.add(segment);
        } else if (isOnGarage) {
          segmentsOnlyOnGarageBorder.add(segment);
        }

        if (isOnLiveable || isOnGarage) {
          segmentsOnBorder.add(segment);
        }
      }
    });

    const addMovableVertex = (vertex: Vertex, offset: THREE.Vector2, verticesToMove: { vertex: Vertex; offset: THREE.Vector2 }[]) => {
      const existing = verticesToMove.find(it => it.vertex === vertex);
      if (existing) {
        if (!VectorUtils.areVectors2Equal(offset, existing.offset)) {
          existing.offset.add(offset);
        }
        return;
      }

      verticesToMove.push({ vertex, offset: offset.clone() });
    };

    const dataToMove: { vertices: { vertex: Vertex; offset: THREE.Vector2 }[]; edges: Edge<Segment>[] }[] = [];
    const halfSize = catalogSettings.walls[FuncCode.INT_2X4_GARG].coreThickness;
    // const halfSize = catalogSettings.walls[WallType.GRG].coreThickness;
    const nonMovableEdges = new Set<Edge<Segment>>(); // Contains edges that will be moved by algorithm. Should not be moved as dependent items.

    garageBoxes.forEach((bb, idx) => {
      const startEdge = SegmentsUtils.getClosestEdgeUnderPoint(bb.getCenter(new THREE.Vector3()), graph);
      const path = SegmentsUtils.graphTraverse(startEdge, graph, false);
      const edges = path.contour.filter(edge => segmentsOnIndoorBorder.has(edge.data));

      dataToMove[idx] = { vertices: [], edges: [] };
      edges.forEach(edge => {
        const delta = edge.data.delta().normalize().multiplyScalar(halfSize);
        const offset = new THREE.Vector2(-delta.y, delta.x); // Rotate 90 CCW

        dataToMove[idx].edges.push(edge);
        nonMovableEdges.add(edge);

        addMovableVertex(edge.v1, offset, dataToMove[idx].vertices);
        addMovableVertex(edge.v2, offset, dataToMove[idx].vertices);
      });
    });

    const connectors = new Set<Edge<Segment>>();

    const moveEdgeInsideGraph = (edge: Edge<Segment>, vertex: Vertex, newVertex: Vertex) => {
      // Move edge inside graph.
      if (edge.v1 === vertex) {
        edge.v1 = newVertex;
      } else {
        edge.v2 = newVertex;
      }

      // Move edge data segment.
      if (MathUtils.areNumbersEqual(edge.data.start.x, vertex.x) && MathUtils.areNumbersEqual(edge.data.start.y, vertex.y)) {
        edge.data.start.set(newVertex.x, newVertex.y);
      } else {
        edge.data.end.set(newVertex.x, newVertex.y);
      }
    };

    const moveVertex = (vertex: Vertex, offset: THREE.Vector2, moveDependent: boolean = true) => {
      const linkedEdges = graph.getLinkedEdges(vertex);
      const newVertex = graph.getOrCreateVertex(vertex.x + offset.x, vertex.y + offset.y);

      // Move linked edges.
      linkedEdges.forEach(linkedEdge => {
        const segment = linkedEdge.data;

        if (
          connectors.has(linkedEdge) ||
          nonMovableEdges.has(linkedEdge) ||
          segmentsOnlyOnLiveableBorder.has(segment) ||
          (segmentsOnlyOnGarageBorder.has(segment) && !MathUtils.areNumbersEqual(segment.delta().cross(offset), 0))
        ) {
          return;
        }

        if (moveDependent) {
          // Move continues of segment outside the building.
          if ((segment.isHorizontal() || segment.isVertical()) && !segmentsOnBorder.has(segment)) {
            const secondVertex = linkedEdge.v1 === vertex ? linkedEdge.v2 : linkedEdge.v1;
            const start = new THREE.Vector3(newVertex.x, newVertex.y);
            const end = new THREE.Vector3(secondVertex.x + offset.x, secondVertex.y + offset.y);
            const edges = graph.getEdges().filter(e => !linkedEdges.includes(e));
            let isMoved = false;

            // After moving an edge, if it crosses with another edge, then instead of leaving them separate, combine them together to prevent creating cycles.
            for (const edge of edges) {
              const intersectionPoint = GeometryUtils.getLineSegmentsIntersectionPoint(start, end, edge.data.start, edge.data.end);
              if (intersectionPoint) {
                const intersectionVertex = graph.getOrCreateVertex(intersectionPoint.x, intersectionPoint.y);
                moveEdgeInsideGraph(linkedEdge, secondVertex, intersectionVertex);
                isMoved = true;
                break;
              }
            }

            if (!isMoved) {
              moveVertex(secondVertex, offset, false);
            }
          }
        }

        moveEdgeInsideGraph(linkedEdge, vertex, newVertex);
      });

      // Create connection old point -> new point.
      const connectorSegment = new Segment(new THREE.Vector2(vertex.x, vertex.y), new THREE.Vector2(newVertex.x, newVertex.y));
      connectors.add(graph.createEdgeFromPoints(connectorSegment.start, connectorSegment.end, connectorSegment));
    };

    dataToMove.forEach(({ vertices, edges }) => {
      edges.forEach(edge => nonMovableEdges.delete(edge));
      vertices.forEach(({ vertex, offset }) => {
        moveVertex(vertex, offset, true);
      });
    });

    const removeEdgeOccurrences = (edge: Edge<Segment>, edges: Edge<Segment>[]) => {
      const removed: Edge<Segment>[] = [];

      for (let i = edges.length - 1; i >= 0; i--) {
        if ((edges[i].v1 === edge.v1 && edges[i].v2 === edge.v2) || (edges[i].v1 === edge.v2 && edges[i].v2 === edge.v1)) {
          removed.push(edge);
          edges.splice(i, 1);
        }
      }

      return removed;
    };

    // Take connectors that connect at least any two edges.
    const visitedEdges = new Set<Edge<Segment>>();
    connectors.forEach(edge => {
      if (visitedEdges.has(edge)) {
        return;
      }
      visitedEdges.add(edge);

      const linkedEdges1 = graph.getLinkedEdges(edge.v1);
      const linkedEdges2 = graph.getLinkedEdges(edge.v2);

      const edgesToRemove = [...removeEdgeOccurrences(edge, linkedEdges1), ...removeEdgeOccurrences(edge, linkedEdges2)];

      edgesToRemove.forEach(edgeToRemove => {
        if (edgeToRemove !== edge) {
          graph.removeEdge(edgeToRemove);
          visitedEdges.add(edgeToRemove);
        }
      });

      if (linkedEdges1.length === 0 || linkedEdges2.length === 0) {
        graph.removeEdge(edge);
      }
    });

    const extractFloorSpaces = (bbs: THREE.Box3[]): FloorSpace[] => {
      return bbs
        .map(bb => {
          const startEdge = SegmentsUtils.getClosestEdgeUnderPoint(bb.getCenter(new THREE.Vector3()), graph);
          if (!startEdge) return null;
          const path = SegmentsUtils.graphTraverse(startEdge, graph, false);
          return new FloorSpace(new Space(path.contour.map(e => e.data.clone())), []);
        })
        .filter(predicate => predicate !== null);
    };

    segments = graph.getEdges().map(e => e.data);
    segments = SegmentsUtils.splitSegments(segments, segments);

    graph.clear();
    segments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));

    const liveableFloorSpaces = extractFloorSpaces(liveableBoxes);
    const garageFloorSpaces = extractFloorSpaces(garageBoxes);

    return new IndoorAreaContour(
      liveableFloorSpaces,
      garageFloorSpaces,
      segments,
      indoorRoomsAreaContour.loopsSegments,
      indoorRoomsAreaContour.interiorSegmentsToRemove
    );
  }

  private findIndoorAreaContour(liveableSoRooms: THREE.Object3D[], garageSoRooms: THREE.Object3D[]): IndoorAreaContour {
    const offset = appModel.includeCladdingThickness ? UnitsUtils.getAreaCalculationExteriorOffset() : catalogSettings.walls[FuncCode.EXT_2X4].coreThickness;

    const indoorRoomsAreaContour = this.findRoomsAreaContour([...liveableSoRooms, ...garageSoRooms], [], offset);
    const liveableRoomsAreaContour = this.findRoomsAreaContour(liveableSoRooms, [], offset);
    const garageRoomsAreaContour = this.findRoomsAreaContour(garageSoRooms, [], offset);

    // Identify interiorSegments to be removed
    indoorRoomsAreaContour.interiorSegments.forEach(segment => {
      if (
        liveableRoomsAreaContour.interiorSegments.some(fSegment => fSegment.overlapsSegment(segment)) ||
        garageRoomsAreaContour.interiorSegments.some(uSegment => uSegment.overlapsSegment(segment))
      ) {
        indoorRoomsAreaContour.interiorSegmentsToRemove.push(segment);
      }
    });

    return ArcAreaValidationTool.extractIndoorAreaContour(indoorRoomsAreaContour, liveableSoRooms, garageSoRooms);
  }

  private static findExpandedSpaces(
    externalSegments: Segment[],
    boxes: THREE.Box3[],
    offset: number
  ): { externalSpaces: Space[]; internalSpaces: Space[]; offsetMappings: { original: THREE.Vector3; offset: THREE.Vector3[] }[] } {
    const graph = new GraphManager<Segment>();
    externalSegments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment.clone()));

    const unlinkedSpaces = SegmentsUtils.extractUnlinkedSpacesFromGraph(graph);

    const externalSpaces: Space[] = [];
    const internalSpaces: Space[] = [];
    const offsetMappings: { original: THREE.Vector3; offset: THREE.Vector3[] }[] = [];

    unlinkedSpaces.forEach(space => {
      const pointInsideSpace = space.contour[0].getCenter3().add(
        VectorUtils.Vector2ToVector3(
          space.contour[0]
            .delta()
            .rotateAround(new THREE.Vector2(), Math.PI / 2)
            .normalize()
            .multiplyScalar(2 * EPSILON)
        )
      );
      const isExternalContour = boxes.some(bb => bb.containsPoint(pointInsideSpace));

      if (isExternalContour) {
        externalSpaces.push(space);
      } else {
        internalSpaces.push(space);
      }

      const sign = isExternalContour ? 1 : -1;
      ArcAreaValidationTool.expandContour(space.contour, sign * offset, offsetMappings);
    });

    return { externalSpaces, internalSpaces, offsetMappings };
  }

  /**
   *  Extracts an array of spaces in graph that have no common edges and contains an array of provided points. Spaces have CCW orientation.
   */
  private static extractUnlinkedSpacesByPoints(graph: GraphManager<Segment>, startPoints: THREE.Vector3[]): Space[] {
    const spaces: Space[] = [];
    const visitedEdges = new Set<string>();
    const checkedPoints = new Set<THREE.Vector3>();

    startPoints.forEach(point => {
      if (checkedPoints.has(point)) {
        return;
      }

      const edge = SegmentsUtils.getClosestEdgeUnderPoint(point, graph);
      if (!edge || visitedEdges.has(edge.keyStr)) {
        return;
      }

      const path = SegmentsUtils.graphTraverse(edge, graph, false, visitedEdges);
      path.contour.forEach(edge => visitedEdges.add(edge.keyStr));
      path.innerEdges.forEach(edge => visitedEdges.add(edge.keyStr));

      if (path.contour.length === 0) {
        return;
      }

      const space = new Space(
        path.contour.map(edge => edge.data),
        []
      );

      startPoints.forEach(p => {
        if (!checkedPoints.has(p) && space.containsPoint(p)) {
          checkedPoints.add(p);
        }
      });

      spaces.push(space);
    });

    return spaces;
  }

  private findRoomsAreaContour(soRooms: any, clippingSegments: Segment[], offset: number): AreaContour {
    const boxes = soRooms.map(so => RoomUtils.getRoomBoundingBoxByModelLines(so));
    const { externalSegments, internalSegments } = this.analysisUtils.collectSegments(soRooms, MergeSegmentsMode.SameRoom, false);

    // eslint-disable-next-line prefer-const
    let { externalSpaces, internalSpaces, offsetMappings } = ArcAreaValidationTool.findExpandedSpaces(externalSegments, boxes, offset);

    const loopDetectionResult = SegmentsUtils.detectLoops(externalSpaces.flatMap(space => space.contour));

    const areaBorderSegments = SegmentsUtils.splitIntersectedSegments([...loopDetectionResult.contourSegments, ...clippingSegments]);
    SegmentsUtils.alignSegments(areaBorderSegments);

    const graph = new GraphManager<Segment>();
    areaBorderSegments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));

    const startPoints = boxes.map(bb => bb.getCenter(new THREE.Vector3())).sort((a, b) => a.y - b.y);

    externalSpaces = ArcAreaValidationTool.extractUnlinkedSpacesByPoints(graph, startPoints);

    const exteriorSegments = [...externalSpaces.flatMap(space => space.contour), ...internalSpaces.flatMap(space => space.contour)];
    const interiorSegments = ArcAreaValidationTool.adjustInnerSegments(
      internalSegments,
      [...exteriorSegments, ...loopDetectionResult.loopsSegments],
      offsetMappings
    );

    // Fill the results
    const floorSpaces: FloorSpace[] = [];
    externalSpaces.forEach(space => {
      floorSpaces.push(
        new FloorSpace(
          space,
          internalSpaces.filter(internalSpace => space.containsSpace(internalSpace))
        )
      );
    });

    return new AreaContour(floorSpaces, exteriorSegments, interiorSegments, loopDetectionResult.loopsSegments);
  }

  /**
   * Add an offset to a contour and track the transformation of each point.
   */
  private static expandContour(contour: Segment[], offset: number, offsetMappings: { original: THREE.Vector3; offset: THREE.Vector3[] }[]): void {
    const expandedContourPoints = [];

    // Calculate new positions
    for (let i = 0; i < contour.length; i++) {
      const p1 = contour[i === 0 ? contour.length - 1 : i - 1].start;
      const p2 = contour[i].start;
      const p3 = contour[i].end;

      const delta1 = p3.clone().sub(p2);
      const delta2 = p2.clone().sub(p1);
      // 90 degrees clockwise
      const a = new THREE.Vector2(delta1.y, -delta1.x).normalize().multiplyScalar(offset);
      const b = new THREE.Vector2(delta2.y, -delta2.x).normalize().multiplyScalar(offset);

      if (MathUtils.areNumbersEqual(a.cross(b), 0)) {
        b.multiplyScalar(0);
      }

      const point = a.add(b).add(p2);
      expandedContourPoints.push(point);

      const mapping = offsetMappings.find(m => MathUtils.areNumbersEqual(m.original.x, p2.x) && MathUtils.areNumbersEqual(m.original.y, p2.y));
      if (mapping) {
        mapping.offset.push(new THREE.Vector3(point.x, point.y));
      } else {
        offsetMappings.push({ original: new THREE.Vector3(p2.x, p2.y), offset: [new THREE.Vector3(point.x, point.y)] });
      }
    }

    // Expand contour
    for (let i = 0; i < contour.length; i++) {
      contour[i].start.copy(expandedContourPoints[i]);
      contour[i === 0 ? contour.length - 1 : i - 1].end.copy(expandedContourPoints[i]);
    }
  }

  /**
   * Connects inner segments to an external contour.
   */
  private static adjustInnerSegments(
    segments: Segment[],
    expandedContourSegments: Segment[],
    offsetMappings: { original: THREE.Vector3; offset: THREE.Vector3[] }[]
  ): Segment[] {
    const graph = new GraphManager<Segment>();
    segments.forEach(s => graph.createEdgeFromPoints(s.start, s.end, s));

    const result: Segment[] = [];
    // Used for avoiding duplication of segments.
    const checkedVertices = new Set<Vertex>();

    const getClosestPointOnExterior = (point: THREE.Vector3, directionPoint: THREE.Vector3) => {
      const searchDirection = directionPoint.clone().sub(point);
      let closestPoint: THREE.Vector3 = null;
      let closestDistance = Number.POSITIVE_INFINITY;

      expandedContourSegments.forEach(seg => {
        const pointOnLine = seg.toLine3().closestPointToPoint(point, true, new THREE.Vector3());
        const distance = point.distanceToSquared(pointOnLine);
        if (closestDistance > distance && pointOnLine.clone().sub(point).dot(searchDirection) > 0) {
          closestPoint = pointOnLine;
          closestDistance = distance;
        }
      });

      return closestPoint;
    };

    graph.getEdges().forEach(edge => {
      const segment = edge.data.clone();
      const start = VectorUtils.Vector2ToVector3(segment.start);
      const end = VectorUtils.Vector2ToVector3(segment.end);
      let isStartClipped = false;
      let isEndClipped = false;

      expandedContourSegments.forEach(clipper => {
        const intersection = GeometryUtils.getLineSegmentsIntersectionPoint(start, end, clipper.start, clipper.end);
        if (intersection) {
          if (start.distanceTo(intersection) < end.distanceTo(intersection)) {
            isStartClipped = true;
            segment.start.x = intersection.x;
            segment.start.y = intersection.y;
          } else {
            isEndClipped = true;
            segment.end.x = intersection.x;
            segment.end.y = intersection.y;
          }
        }
      });

      result.push(segment);

      // Connect start point to external contour.
      if (!isStartClipped && !checkedVertices.has(graph.getVertexByPoint(segment.start))) {
        checkedVertices.add(graph.getVertexByPoint(segment.start));
        const offsetMapping = offsetMappings.find(it => VectorUtils.areVectorsEqual(start, it.original));

        if (offsetMapping) {
          const closestPoint = getClosestPointOnExterior(start, offsetMapping.offset[0]);
          if (closestPoint) {
            result.push(new Segment(new THREE.Vector2(start.x, start.y), new THREE.Vector2(closestPoint.x, closestPoint.y)));
          }
        }
      }

      // Connect end point to external contour.
      if (!isEndClipped && !checkedVertices.has(graph.getVertexByPoint(segment.end))) {
        checkedVertices.add(graph.getVertexByPoint(segment.end));
        const offsetMapping = offsetMappings.find(it => VectorUtils.areVectorsEqual(end, it.original));

        if (offsetMapping) {
          const closestPoint = getClosestPointOnExterior(end, offsetMapping.offset[0]);
          if (closestPoint) {
            result.push(new Segment(new THREE.Vector2(end.x, end.y), new THREE.Vector2(closestPoint.x, closestPoint.y)));
          }
        }
      }
    });

    // Check for rooms that have shared only corners.
    offsetMappings.forEach(mapping => {
      if (mapping.offset.length > 1) {
        mapping.offset.forEach(offsetPoint => {
          const closestPoint = getClosestPointOnExterior(mapping.original, offsetPoint);
          if (closestPoint) {
            result.push(new Segment(new THREE.Vector2(mapping.original.x, mapping.original.y), new THREE.Vector2(closestPoint.x, closestPoint.y)));
          }
        });
      }
    });

    return result;
  }

  private static sortRoomsByTypes(soRooms: THREE.Object3D[]): {
    liveableSoRooms: THREE.Object3D[];
    garageSoRooms: THREE.Object3D[];
    porchAndPatioSoRooms: THREE.Object3D[];
  } {
    const liveableSoRooms: THREE.Object3D[] = [];
    const garageSoRooms: THREE.Object3D[] = [];
    const porchAndPatioSoRooms: THREE.Object3D[] = [];

    soRooms.forEach(soRoom => {
      const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);

      if (!roomType.attributes.indoor) {
        porchAndPatioSoRooms.push(soRoom);
        return;
      }

      if (roomType.attributes.netArea) {
        liveableSoRooms.push(soRoom);
        return;
      }

      garageSoRooms.push(soRoom);
    });

    return { liveableSoRooms, garageSoRooms, porchAndPatioSoRooms };
  }
}

class AreaContour {
  constructor(
    public floorSpaces: FloorSpace[] = [],
    public exteriorSegments: Segment[] = [],
    public interiorSegments: Segment[] = [],
    public loopsSegments: Segment[] = [],
    public interiorSegmentsToRemove: Segment[] = []
  ) {}
}

class IndoorAreaContour {
  constructor(
    public liveableFloorSpaces: FloorSpace[] = [],
    public garageFloorSpaces: FloorSpace[] = [],
    public segments: Segment[] = [],
    public loopsSegments: Segment[] = [],
    public interiorSegmentsToRemove: Segment[] = []
  ) {}
}

class FloorAreaContours {
  constructor(
    public indoorAreaContour: IndoorAreaContour = new IndoorAreaContour(),
    public outdoorAreaContour: AreaContour = new AreaContour()
  ) {}
}

class RoomData {
  constructor(
    public netBox: THREE.Box3 = new THREE.Box3(),
    public name: string = "",
    public area: number = 0
  ) {}
}
class FloorData {
  constructor(
    public livableArea: AreaTakeOff = { title: "", value: 0 },
    public garageArea: AreaTakeOff = { title: "", value: 0 },
    public porchesArea: AreaTakeOff = { title: "", value: 0 },
    public grossArea: number = 0,
    public netArea: number = 0,
    public roomsData: RoomData[] = []
  ) {}
}
