import { Segment } from "../../models/segments/Segment";

export class WallThicknessManager {
  // map of modified segements (Segment objects) with modified functionCode
  private modifiedSegments = new Map<string, Segment>();

  addModifiedSegment(segment: Segment): void {
    this.modifiedSegments.set(this.getModifiedSegmentsIndex(segment), segment);
  }

  setModifiedSegments(segments: Segment[]): void {
    segments.forEach(segment => this.modifiedSegments.set(this.getModifiedSegmentsIndex(segment), segment));
  }

  findModifiedSegment(segment: Segment): Segment | undefined {
    return this.modifiedSegments.get(this.getModifiedSegmentsIndex(segment));
  }

  getAllModifiedSegments() {
    return this.modifiedSegments;
  }

  clearModifiedSegments(): void {
    this.modifiedSegments.clear();
  }

  private getModifiedSegmentsIndex(segment: any): string {
    if (!segment.start) {
      return `${segment.userData?.segment.start.x};${segment.userData?.segment.start.y}`;
    } else {
      return `${segment.start.x};${segment.start.y}`;
    }
  }
}
