import { appModel } from "../../../models/AppModel";
import { SegmentOffsetManager } from "../../managers/SceneManager/SegmentOffsetManager";
import { RoomCommand } from "./RoomCommand";

export class ChangeWallAlignmentCommand extends RoomCommand {
  private isLocked: boolean;
  private oldOffsets: any; // associative array with key of index (start.x ; start.y ; end.x ; end.y) and value of offset

  constructor(
    private soWall: THREE.Object3D,
    oldOffsets: any
  ) {
    super(soWall.userData.id);
    this.isLocked = soWall.userData.isLocked;
    this.oldOffsets = oldOffsets;
  }

  apply(): void {
    this.lockWall();
  }

  undo(): void {
    this.unlockWall();

    appModel.clearSegmentOffsets();
    for (const [roomId, segments] of Object.entries(appModel.selectedRoomSegments)) {
      const restoredSegments = segments.map(segment => {
        const index = SegmentOffsetManager.getOffsetObject3DIndex(segment);
        if (this.oldOffsets && index in this.oldOffsets) {
          segment.userData.offset = this.oldOffsets[index];
          appModel.updateSegmentOffset(segment);
        }
        return segment.userData.segment;
      });
    }
  }

  private lockWall(): void {
    this.soWall.userData.isLocked = true;
  }

  private unlockWall(): void {
    this.soWall.userData.isLocked = this.isLocked;
  }
}
