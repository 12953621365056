import { FuncCode } from "../../../entities/catalogSettings/types";
import { appModel } from "../../../models/AppModel";
import { RoomCommand } from "./RoomCommand";

export class ChangeWallWidthCommand extends RoomCommand {
  private isLocked: boolean;
  private oldFunctionCodeMap: Map<string, string>;

  constructor(
    private soWall: THREE.Object3D,
    private functionCode: string
  ) {
    super(soWall.userData.id);

    this.isLocked = soWall.userData.isLocked;
    this.oldFunctionCodeMap = new Map();
  }

  public apply(): void {
    this.lockWall();

    for (const [roomId, segments] of Object.entries(appModel.selectedRoomSegments)) {
      const updatedSegments = segments.map(segment => {
        const oldFunctionCode = segment.userData.segment.functionCode;
        this.oldFunctionCodeMap.set(segment.userData.id, oldFunctionCode);
        this.updateSegmentFunctionCode(segment, this.functionCode);
        return segment.userData.segment;
      });

      appModel.setModifiedSegmentWalls(updatedSegments);
    }

    this.updateRoomWall();
  }

  public undo(): void {
    this.unlockWall();

    for (const [roomId, segments] of Object.entries(appModel.selectedRoomSegments)) {
      const restoredSegments = segments.map(segment => {
        const oldFunctionCode = this.oldFunctionCodeMap.get(segment.userData.id);
        if (oldFunctionCode) {
          this.updateSegmentFunctionCode(segment, oldFunctionCode);
        }
        return segment.userData.segment;
      });

      appModel.setModifiedSegmentWalls(restoredSegments);
    }

    this.restoreRoomWall();
  }

  private lockWall(): void {
    this.soWall.userData.isLocked = true;
  }

  private unlockWall(): void {
    this.soWall.userData.isLocked = this.isLocked;
  }

  private updateSegmentFunctionCode(segment: any, functionCode: string): void {
    if (segment?.userData?.segment) {
      segment.userData.segment.changeFunctionCode(Object.values(FuncCode).find(code => code === functionCode));
    }
  }

  private updateRoomWall(): void {
    const currentWall = appModel.selectedRoomWall.values().next().value;
    appModel.updateSelectedRoomWall(currentWall, this.soWall);
    appModel.baseManager.roomManager.setRoomWallSelected();
  }

  private restoreRoomWall(): void {
    const currentWall = appModel.selectedRoomWall.values().next().value;
    appModel.updateSelectedRoomWall(this.soWall, currentWall);
    appModel.baseManager.roomManager.setRoomWallSelected();
  }
}
