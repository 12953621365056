import * as THREE from "three";
import MathUtils from "../MathUtils";

export default class LineUtils {
  /**
   * Calculates the distance between two lines' midpoints, provided the lines are parallel.
   *
   * @param first - The first line represented as an object with `start` and `end` properties of type THREE.Vector3.
   * @param second - The second line represented similarly.
   * @param tolerance - The tolerance level for checking line parallelism (default is 1e-6).
   * @returns The distance between the two lines' midpoints.
   * @throws Error if the lines are not parallel within the specified tolerance.
   */
  static GetLinesAxisDistance(
    first: { start: THREE.Vector3; end: THREE.Vector3 },
    second: { start: THREE.Vector3; end: THREE.Vector3 },
    tolerance: number = 1e-6
  ): number {
    // Calculate midpoints of both lines
    const firstMid = new THREE.Vector3().addVectors(first.start, first.end).multiplyScalar(0.5);
    const secondMid = new THREE.Vector3().addVectors(second.start, second.end).multiplyScalar(0.5);

    // Get the direction of both lines
    const firstDir = new THREE.Vector3().subVectors(first.end, first.start).normalize();
    const secondDir = new THREE.Vector3().subVectors(second.end, second.start).normalize();

    // Vector between the midpoints of the two lines
    const midToMid = new THREE.Vector3().subVectors(secondMid, firstMid);

    // Calculate the cross product of the direction vectors
    const crossDir = new THREE.Vector3().crossVectors(firstDir, secondDir);

    // Check if the lines are parallel within the specified tolerance
    if (crossDir.lengthSq() > tolerance * tolerance) {
      return null;
    }

    // If the lines are parallel, project the vector between the midpoints onto one of the line's direction
    const projection = midToMid.clone().projectOnVector(firstDir);
    const result = MathUtils.round(midToMid.sub(projection).length(), 100000);
    return result;
  }
}
