import * as THREE from "three";
import MathUtils from "../MathUtils";
import { Vector3V } from "../../../models/Vector3V";
import { EPSILON } from "../../consts";
import UnitsUtils from "../UnitsUtils";

/**
 * Utility class for common vector operations.
 */
export default class VectorUtils {
  /**
   * Converts a Vector3V object to a THREE.Vector3 object.
   *
   * @param v - A Vector3V object containing x, y, and z values.
   * @returns A THREE.Vector3 object with the same x, y, and z values.
   */
  static Vector3VToVector3(v: Vector3V): THREE.Vector3 {
    return new THREE.Vector3(v.x, v.y, v.z);
  }

  /**
   * Converts a THREE.Vector3 object to a Vector3V object.
   *
   * @param v - A THREE.Vector3 object containing x, y, and z values.
   * @returns A Vector3V object with the same x, y, and z values.
   */
  static Vector3ToVector3V(v: THREE.Vector3): Vector3V {
    return new Vector3V(v.x, v.y, v.z);
  }

  /**
   * Converts a THREE.Vector2 object to a THREE.Vector3 object.
   * The z value of the resulting vector will always be 0.
   *
   * @param v - A THREE.Vector2 object containing x and y values.
   * @returns A THREE.Vector3 object with x, y, and z (set to 0).
   */
  static Vector2ToVector3(v: THREE.Vector2): THREE.Vector3 {
    return new THREE.Vector3(v.x, v.y, 0);
  }

  /**
   * Compares two THREE.Vector3 objects for equality within a given epsilon.
   *
   * @param left - The first THREE.Vector3 object.
   * @param right - The second THREE.Vector3 object.
   * @param epsilon - Optional epsilon value for floating-point comparison (default is EPSILON).
   * @returns True if the vectors are equal within the given epsilon, otherwise false.
   */
  static areVectorsEqual(left: THREE.Vector3, right: THREE.Vector3, epsilon = EPSILON): boolean {
    return MathUtils.areNumberArraysEqual(left.toArray(), right.toArray(), epsilon);
  }

  /**
   * Compares two THREE.Vector2 objects for equality within a given epsilon.
   *
   * @param left - The first THREE.Vector2 object.
   * @param right - The second THREE.Vector2 object.
   * @param epsilon - Optional epsilon value for floating-point comparison (default is EPSILON).
   * @returns True if the vectors are equal within the given epsilon, otherwise false.
   */
  static areVectors2Equal(left: THREE.Vector2, right: THREE.Vector2, epsilon = EPSILON): boolean {
    return MathUtils.areNumberArraysEqual(left.toArray(), right.toArray(), epsilon);
  }

  /**
   * Converts a direction code to a corresponding THREE.Vector3 object.
   *
   * @param directionCode - The code representing a direction:
   *   - 1: Down (0, -1, 0)
   *   - 2: Left (-1, 0, 0)
   *   - 3: Up (0, 1, 0)
   *   - 4: Right (1, 0, 0)
   *   - Any other value: Returns a zero vector (0, 0, 0) and logs a warning.
   * @returns A THREE.Vector3 object representing the direction.
   */
  static directionToVector3(directionCode): THREE.Vector3 {
    switch (directionCode) {
      case 1:
        return new THREE.Vector3(0, -1, 0);
      case 2:
        return new THREE.Vector3(-1, 0, 0);
      case 3:
        return new THREE.Vector3(0, 1, 0);
      case 4:
        return new THREE.Vector3(1, 0, 0);
      default:
        console.warn("Unknown direction code:", directionCode);
        return new THREE.Vector3(0, 0, 0);
    }
  }

  /**
   * Rounds up the components of a THREE.Vector3 object to the nearest precision value.
   *
   * @param target - The THREE.Vector3 object to be rounded.
   * @param e - The rounding precision (default is retrieved from UnitsUtils).
   * @returns The rounded THREE.Vector3 object.
   */
  static ceilVector(target: THREE.Vector3, e: number = UnitsUtils.getRoundPrecision()): THREE.Vector3 {
    target.x = MathUtils.ceil(target.x, e);
    target.y = MathUtils.ceil(target.y, e);
    target.z = MathUtils.ceil(target.z, e);

    return target;
  }
}
