import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Input, Menu, Radio } from "antd";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { Scrollbar } from "react-scrollbars-custom";
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FileImageOutlined,
  MinusOutlined,
  PicLeftOutlined,
  PictureOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { SceneEditorMode } from "../../../../editor/models/SceneEditorMode";
import CorePlanCostTool from "../../../../editor/tools/CorePlanCostTools";
import { entityManager } from "../../../../entities/entityManager";
import { settings } from "../../../../entities/settings";
import { handlePendingChanges } from "../../../../helpers/appUtils";
import { showToastMessage } from "../../../../helpers/messages";
import { getDropdownTrigger } from "../../../../helpers/utilities";
import { appModel } from "../../../../models/AppModel";
import { CorePlan } from "../../../../models/CorePlan";
import { SceneMode } from "../../../../models/SceneMode";
import ModalCorePlanWindow from "../../CorePlans/ModalCorePlanWindow";
import AlignmentTestModal from "./../AlignmentTestModal";
import EditorBackgroundSelector from "./../EditorBackgroundSelector/EditorBackgroundSelector";
import ModellingIssues from "./../ModellingIssues/ModellingIssues";
import ModalCorePlanPackageWindow from "../../CorePlans/ModalCorePlanPackageWindow";
import ModalVariationPackageWindow from "../../CorePlans/ModalVariationPackageWindow";
import EditorSpinner from "./../EditorSpinner";
import HomeButton from "./HomeButton";

import "./../Editor.sass";
import ErrorContainer from "./ErrorContainer";
import DimensionTypeSetting, { DIMENSION_CORE_FACE, DIMENSION_FINISH_FACE } from "./DimensionTypeSetting";
import GridOptions from "../GridOptions";

const ButtonGroup = Button.Group;

const EditorHeader = observer(() => {
  const history = useHistory();
  const [corePlan] = useState<CorePlan>(appModel.activeCorePlan);
  const [backgroundSelectorOpen, setBackgroundSelectorOpen] = useState(false);
  const [alignTestOpen, setAlignTestOpen] = useState(false);

  const isEditorMode = appModel.sceneMode == SceneMode.Editor;
  const isEnabled = appModel.activeCorePlan && appModel.activeFloor;
  const dropdownTrigger = getDropdownTrigger();
  let keyIndex = 1;
  const activeSceneHeaderButton = appModel.sceneMode === SceneMode.Render ? SceneMode.Preview : appModel.sceneMode;

  const logoTitle = `${corePlan?.lennar_id} ${corePlan?.name}`;

  const onSceneModeChanged = async (e: any) => {
    if (!(await handlePendingChanges())) {
      return;
    }

    appModel.setSceneEditorMode(SceneEditorMode.Room);
    appModel.setSceneMode(e.target.value as SceneMode);
  };
  const onZoomToFitClick = (e: any) => {
    if (isEditorMode) {
      appModel.baseManager.roomManager.zoomToFit();
    } else {
      appModel.baseManager.previewManager.zoomToFit();
    }
  };

  const notImplementedNotification = () => {
    // FREEZED
    //showToastMessage("Warning", "not implemented yet");
  };

  const onMeasurementClicked = (): void => {
    if (appModel.sceneEditorMode === SceneEditorMode.Room) {
      appModel.setSceneEditorMode(SceneEditorMode.Measurement);
    } else {
      appModel.setSceneEditorMode(SceneEditorMode.Room);
    }
  };
  const isEnabledBackground = !appModel.isViewOnlyMode && appModel.sceneEditorMode === SceneEditorMode.Background && !!appModel.activeFloor.background;

  const floorSetting = (
    <Menu
      items={(corePlan?.floors || [])
        .slice()
        .sort((f1, f2) => {
          return f1.index > f2.index ? 1 : -1;
        })
        .map(floor => ({
          label: `${floor.name}`,
          key: floor.id,
        }))}
      onClick={e => {
        if (appModel.isViewOnlyMode) {
          appModel.setActiveFloor(e.key);
          return;
        }

        appModel.baseManager.roomManager.updateRoomDrawings(false);

        if (appModel.pendingChanges) {
          appModel.setActiveFloor(e.key);
          return;
        }

        entityManager.saveFloor(appModel.activeFloor, true).then(savedFloor => {
          if (savedFloor.notFound) {
            appModel.setActiveCorePlan(null, false);
            history.push("/");
            return;
          }

          if (!savedFloor.result) return;
          appModel.setActiveFloor(e.key);
        });
      }}
    />
  );
  const visibilitySetting = (
    <Menu
      items={[
        {
          icon: appModel.showBelowFloor ? <EyeOutlined /> : <EyeInvisibleOutlined />,
          label: "Stories below",
          key: keyIndex++,
          onClick: () => {
            runInAction(() => {
              appModel.showBelowFloor = !appModel.showBelowFloor;
            });
          },
        },
        {
          icon: appModel.showAboveFloor ? <EyeOutlined /> : <EyeInvisibleOutlined />,
          label: "Stories above",
          key: keyIndex++,
          onClick: () => {
            runInAction(() => {
              appModel.showAboveFloor = !appModel.showAboveFloor;
            });
          },
        },
        {
          icon: appModel.showGrid ? <EyeOutlined /> : <EyeInvisibleOutlined />,
          label: "Grid lines",
          key: keyIndex++,
          onClick: () => {
            appModel.setShowGrid(!appModel.showGrid);
          },
        },
        appModel.sceneEditorMode !== SceneEditorMode.Background && {
          icon: appModel.showBackground ? <EyeOutlined /> : <EyeInvisibleOutlined />,
          label: "Background image",
          key: keyIndex++,
          onClick: () => {
            appModel.setShowBackground(!appModel.showBackground);
          },
        },
        appModel.activeCorePlan &&
          appModel.activeFloor.index === appModel.activeCorePlan.floors.length - 1 && {
            icon: appModel.showRoof ? <EyeOutlined /> : <EyeInvisibleOutlined />,
            label: "Roof contour",
            key: keyIndex++,
            onClick: () => {
              appModel.setShowRoof(!appModel.showRoof);
            },
          },
        {
          icon: appModel.showCladding ? <EyeOutlined /> : <EyeInvisibleOutlined />,
          label: "Cladding",
          key: keyIndex++,
          onClick: () => {
            appModel.setShowCladding(!appModel.showCladding);
          },
        },
      ]}
    />
  );
  const toolsSetting = (
    <Menu
      items={[
        {
          icon: <div className="icon icon-edit-lotline" />,
          label: appModel.activeFloor?.lotLine ? "Edit lot lines" : "Add lot lines",
          key: keyIndex++,
          onClick: async () => {
            if (appModel.sceneEditorMode === SceneEditorMode.LotLine || !(await handlePendingChanges())) {
              return;
            }

            appModel.setSceneEditorMode(SceneEditorMode.LotLine);
          },
          disabled: appModel.isViewOnlyMode,
        },
        {
          icon: <PictureOutlined />,
          label: "Edit background image",
          key: keyIndex++,
          onClick: async () => {
            if (appModel.sceneEditorMode === SceneEditorMode.Background || !(await handlePendingChanges())) {
              return;
            }

            appModel.setSceneEditorMode(SceneEditorMode.Background);
          },
          disabled: appModel.isViewOnlyMode,
        },
        {
          icon: <img className="scale-icon" src={`/assets/icons/${!isEnabledBackground ? "scale-disabled" : "scale"}.svg`} alt="measure" />,
          label: "Scale Image",
          key: keyIndex++,
          onClick: () => appModel.baseManager.roomManager.backgroundManager.toggleScaleMode(),
          disabled: !isEnabledBackground,
        },
        {
          icon: <img className="measure-icon" src="/assets/icons/measure.svg" alt="measure" />,
          label: "Measurment",
          key: keyIndex++,
          onClick: onMeasurementClicked,
          disabled: appModel.isViewOnlyMode,
        },
      ]}
    />
  );
  const gridSetting = (
    <Menu
      items={[
        {
          icon: appModel.snapToGrid ? <CheckOutlined /> : null,
          label: "Snap to grid",
          key: keyIndex++,
          onClick: () => {
            runInAction(() => {
              appModel.setSnapToGrid(!appModel.snapToGrid);
            });
          },
          disabled: appModel.isViewOnlyMode,
        },
        {
          icon: appModel.showGridOverPlan ? <CheckOutlined /> : null,
          label: "Show grid over plan",
          key: keyIndex++,
          onClick: () => {
            appModel.setShowGridOverPlan(!appModel.showGridOverPlan);
          },
        },
        {
          icon: <GridOptions />,
          key: keyIndex++,
        },
      ]}
    />
  );
  const zoomSetting = (
    <Menu
      items={[
        {
          label: "100%",
          key: keyIndex++,
          onClick: () => {
            notImplementedNotification();
          },
        },
        {
          label: "75%",
          key: keyIndex++,
          onClick: () => {
            notImplementedNotification();
          },
        },
        {
          label: "50%",
          key: keyIndex++,
          onClick: () => {
            notImplementedNotification();
          },
        },
      ]}
    />
  );

  const handleLogoClicked = async () => {
    if (appModel.isViewOnlyMode) {
      appModel.setActiveCorePlan(null);
      history.push("/");
      return;
    }
    if (!(await handlePendingChanges())) {
      return;
    }

    appModel.baseManager.roomManager.updateRoomDrawings(false);
    const corePlan = appModel.activeCorePlan;
    corePlan.setSnapEvents(Object.keys(appModel.baseManager.roomManager.snapEvents));
    entityManager.saveCorePlan(corePlan, true).then(savedCorePlan => {
      if (savedCorePlan.notFound) {
        appModel.setActiveCorePlan(null, false);
        history.push("/");
        return;
      }
      if (!savedCorePlan.result) return;
      appModel.setActiveCorePlan(null);
      history.push("/");
    });
  };

  return (
    <>
      <div className="editor-header">
        <HomeButton onClick={handleLogoClicked} title={logoTitle} />
        <Scrollbar noScrollY>
          <div className="editor-header-toolbar">
            <div className="group">
              {isEditorMode && (
                <>
                  <Dropdown overlay={floorSetting} placement="bottomLeft" trigger={dropdownTrigger as any}>
                    <Button className={"dropdown-floor"} data-testid="floorDropdown">
                      <DownOutlined />
                      {appModel.activeFloor?.name}
                    </Button>
                  </Dropdown>
                  <div className="toolbutton-separator" />
                  <Dropdown overlay={visibilitySetting} placement="bottomLeft" trigger={dropdownTrigger as any}>
                    <Button className={"dropdown-visibility"} data-testid="opacityDropdown">
                      <DownOutlined />
                      Visibility options
                    </Button>
                  </Dropdown>
                  <div className="toolbutton-separator" />
                  <Dropdown overlay={gridSetting} placement="bottomLeft" trigger={dropdownTrigger as any}>
                    <Button className={"dropdown-grid"} data-testid="gridDropdown">
                      <DownOutlined />
                      Grid options
                    </Button>
                  </Dropdown>
                  <div className="toolbutton-separator" />
                  <Dropdown overlay={toolsSetting} placement="bottomLeft" trigger={dropdownTrigger as any}>
                    <Button className={"dropdown-lotline"} data-testid="lotlineDropdown">
                      <DownOutlined />
                      Tools
                    </Button>
                  </Dropdown>
                  <div className="toolbutton-separator" />
                  <Dropdown overlay={<DimensionTypeSetting />} placement="bottomLeft" trigger={dropdownTrigger as any}>
                    <Button className={"dropdown-dimension-type"} data-testid="dimensionTypeDropdown">
                      <DownOutlined />
                      {appModel.showFinishFaceDimension ? DIMENSION_FINISH_FACE : DIMENSION_CORE_FACE}
                    </Button>
                  </Dropdown>

                  {isEnabled && appModel.sceneEditorMode === SceneEditorMode.Background ? (
                    <>
                      <div className="toolbutton-separator" />
                      <Button type="link" size="small" className="icon-upload-background" onClick={() => setBackgroundSelectorOpen(true)}>
                        <FileImageOutlined /> Select Background Image
                      </Button>
                    </>
                  ) : null}
                </>
              )}
            </div>

            <div className="group">
              <ModellingIssues />
              <div className="toolbutton-separator" />

              {isEditorMode && new URLSearchParams(window.location.search).has("oatest") && (
                <>
                  <Button className="btn btn-icon icon icon-alignment" type="link" onClick={() => setAlignTestOpen(true)}>
                    <PicLeftOutlined />
                  </Button>
                  <div className="toolbutton-separator" />
                </>
              )}
              {!isEditorMode && appModel.dutchGableError && <ErrorContainer type={appModel.dutchGableError} />}

              <Button className="btn btn-icon icon icon-zoom-to-fit" data-testid="buttonZoomToFit" onClick={onZoomToFitClick}>
                <DownOutlined></DownOutlined>
              </Button>

              <div className="toolbutton-separator" />

              <Radio.Group value={activeSceneHeaderButton} size="large" className="btn-mode" buttonStyle="solid" onChange={onSceneModeChanged}>
                <Radio.Button value={SceneMode.Editor}>Editor</Radio.Button>
                <Radio.Button value={SceneMode.Preview}>Preview</Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </Scrollbar>

        <EditorSpinner />
        <EditorBackgroundSelector isOpen={backgroundSelectorOpen} onSelect={() => {}} onClose={() => setBackgroundSelectorOpen(false)} />
        <AlignmentTestModal isOpen={alignTestOpen} onSelect={() => {}} onClose={() => setAlignTestOpen(false)} />
        <ModalCorePlanWindow />
        <ModalCorePlanPackageWindow />
        <ModalVariationPackageWindow />
      </div>

      {(appModel.sceneEditorMode === SceneEditorMode.LotLine || appModel.sceneEditorMode === SceneEditorMode.Background) && (
        <div className="editor-header-overlay">
          {appModel.sceneEditorMode === SceneEditorMode.LotLine && <p>Lot line editing mode</p>}
          {appModel.sceneEditorMode === SceneEditorMode.Background && <p>Background editing mode</p>}
          <hr />
          <Button
            className="button-success"
            type="primary"
            icon={
              <div className="icon-container">
                <CheckOutlined />
              </div>
            }
            onClick={onFinalizeChangesClicked}
          >
            Finalize edits
          </Button>
          <Button
            className="button-failed"
            type="primary"
            icon={
              <div className="icon-container">
                <CloseOutlined />
              </div>
            }
            onClick={onCancelChangesClicked}
          >
            Cancel changes
          </Button>
        </div>
      )}
    </>
  );
});

const onFinalizeChangesClicked = (e: React.MouseEvent): void => {
  appModel.setPendingChanges(false);
  appModel.setSceneEditorMode(SceneEditorMode.Room);
};
const onCancelChangesClicked = (e: React.MouseEvent): void => {
  appModel.setPendingChanges(false);
  switch (appModel.sceneEditorMode) {
    case SceneEditorMode.LotLine: {
      appModel.baseManager.roomManager.lotLineManager.rollbackChanges();
      break;
    }
    case SceneEditorMode.Background: {
      appModel.baseManager.roomManager.backgroundManager.rollbackChanges();
      break;
    }
  }

  appModel.setSceneEditorMode(SceneEditorMode.Room);
};
/* FREEZED: #DCP-1153
function updateCorePlanCost(corePlan: CorePlan, finalOnClick: () => void) {
  if (!corePlan.isCostOutdated || true) {
    finalOnClick();
    return;
  }

  corePlan.setIsEstimatingCost(true);
  const jsonTool = new CorePlanCostTool(appModel.baseManager.roomManager);
  const corePlanCostJson = jsonTool.generateCorePlanCostJson();
  finalOnClick();
  jsonTool.updateCorePlanCost(corePlan, corePlanCostJson).then(() => {
    corePlan.setIsEstimatingCost(false);
  });
}*/

export default EditorHeader;
