import { Vector2 } from "three";
import { Side } from "../../../models/Side";
import { Segment } from "./Segment";
import MathUtils from "../../utils/MathUtils";

export class Vertex {
  id: string;
  point: Vector2;
  edges: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  private cornerCount: number = 0;

  constructor(point: Vector2) {
    this.point = point;
    this.id = Vertex.generateVertexId(point);
    this.edges = {};
  }
  static generateVertexId(point: Vector2): string {
    return `${MathUtils.round(point.x)},${MathUtils.round(point.y)}`;
  }
  getCornerCount(): number {
    return this.cornerCount;
  }
  incrementCornerCount(): void {
    this.cornerCount++;
  }
  decrementCornerCount(): void {
    this.cornerCount--;
  }
  getSegmantIdBySide(Side: Side): string {
    return this.edges[Side];
  }

  addWallIdBySide(side: string, WallId: string): void {
    this.edges[side] = WallId;
  }
}
