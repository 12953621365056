import "./ModalSystemSettings.sass";
import { observer } from "mobx-react-lite";
import { Modal, Tabs } from "antd";
import { useEffect, useState } from "react";
import RoomManagementTab from "./RoomManagementTab";
import SettingsTab, { SubCategorySettings } from "./SettingsTab";
import { useForm } from "antd/lib/form/Form";
import { settings } from "../../../../entities/settings";
import { getDisplayValue } from "../../../../helpers/measures";
import { defaultSettings, defaultUnits } from "../../../../entities/settings/defaults";
import { CorePlanDefaultsKeys, CorePlanRangesKeys, TSettingsValues, ValidationSettingsKeys, WebAppUISettingsKeys } from "../../../../entities/settings/types";

const defaultActiveTabKey = "roomManagement";
export const requestDataSettings: SubCategorySettings[] = [
  {
    subCategoryDisplayName: "Ranges",
    subCategoryName: "corePlanRangesSettings",
    settings: [
      CorePlanRangesKeys.storiesMax,
      CorePlanRangesKeys.minFloorToPlateLevelHeight,
      CorePlanRangesKeys.maxFloorToPlateLevelHeight,
      null,
      CorePlanRangesKeys.bedroomMin,
      CorePlanRangesKeys.bedroomMax,
      CorePlanRangesKeys.bathroomMin,
      CorePlanRangesKeys.bathroomMax,
      CorePlanRangesKeys.carsMax,
    ],
  },
  {
    subCategoryDisplayName: "Defaults",
    subCategoryName: "corePlanDefaults",
    settings: [
      CorePlanDefaultsKeys.numOfStories,
      CorePlanDefaultsKeys.floorToPlateLevelHeight,
      CorePlanDefaultsKeys.floorThickness,
      CorePlanDefaultsKeys.numOfBedrooms,
      CorePlanDefaultsKeys.numOfBathrooms,
      CorePlanDefaultsKeys.numOfCars,
    ],
  },
  {
    subCategoryName: "corePlanDefaults",
    settings: [
      CorePlanDefaultsKeys.minBuildingNetArea,
      CorePlanDefaultsKeys.maxBuildingGrossArea,
      CorePlanDefaultsKeys.lotArea,
      CorePlanDefaultsKeys.lotWidth,
      CorePlanDefaultsKeys.lotLength,
      CorePlanDefaultsKeys.lotFrontSetback,
      CorePlanDefaultsKeys.lotBackSetback,
      CorePlanDefaultsKeys.lotSideSetback,
      CorePlanDefaultsKeys.lotFront2Setback,
    ],
  },
];

export const validationSettings: SubCategorySettings[] = [
  {
    subCategoryDisplayName: "Structural",
    subCategoryName: "validationSettings",
    settings: [
      ValidationSettingsKeys.gravityMaxFloorSpan,
      ValidationSettingsKeys.gravityMaxBeamSpan,
      ValidationSettingsKeys.gravityMaxCantileverSpan,
      ValidationSettingsKeys.shearF1cap,
      ValidationSettingsKeys.shearF2cap,
      ValidationSettingsKeys.shearSeismicFactorCd,
      ValidationSettingsKeys.shearComboDesignFactor,
      ValidationSettingsKeys.shearPreliminaryFactor,
      null,
      ValidationSettingsKeys.exteriorWallWeight,
      ValidationSettingsKeys.interiorWallWeight,
      ValidationSettingsKeys.beamWeight,
      ValidationSettingsKeys.floorWeight,
      ValidationSettingsKeys.roofWeight,
    ],
  },
  {
    subCategoryDisplayName: "Common",
    subCategoryName: "validationSettings",
    settings: [
      ValidationSettingsKeys.openingAlignmentMinGap,
      ValidationSettingsKeys.areaCalculationExteriorOffset,
      ValidationSettingsKeys.gridCellSizeForNewCorePlans,
      ValidationSettingsKeys.stretchAllowance,
      ValidationSettingsKeys.frameSize,
      ValidationSettingsKeys.minimalWallSegmentLength,
      ValidationSettingsKeys.roofDefaultSlope,
      ValidationSettingsKeys.roofDefaultOverhang,
      ValidationSettingsKeys.minimumGableSeparation,
      ValidationSettingsKeys.windowEdgeOffset,
      ValidationSettingsKeys.doorEdgeOffset,
    ],
  },
  {
    subCategoryDisplayName: "MEP",
    subCategoryName: "validationSettings",
    className: "mep-category-settings",
    settings: [
      ValidationSettingsKeys.openingStructuralTolerance,
      ValidationSettingsKeys.edgeOfExteriorWallTolerance,
      ValidationSettingsKeys.firstFloorPlumbingPointMaxOffset,
      ValidationSettingsKeys.pipeFallTolerance,
    ],
  },
];

export const displaySettings: SubCategorySettings[] = [
  {
    subCategoryDisplayName: "Canvas",
    subCategoryName: "webAppUISettings",
    settings: [
      WebAppUISettingsKeys.selectionColor,
      WebAppUISettingsKeys.highlightColor,
      WebAppUISettingsKeys.wallsColor,
      WebAppUISettingsKeys.floorsColor,
      WebAppUISettingsKeys.openingsColor,
      WebAppUISettingsKeys.gridPrimaryColor,
      WebAppUISettingsKeys.gridSecondaryColor,
      WebAppUISettingsKeys.lotLinesColor,
      WebAppUISettingsKeys.lotSetbackColor,
      WebAppUISettingsKeys.obsoleteRoomsColor,
      WebAppUISettingsKeys.glassColor,
      WebAppUISettingsKeys.doorColor,
      WebAppUISettingsKeys.roofSurface,
      WebAppUISettingsKeys.roofEdge,
    ],
  },
  {
    subCategoryDisplayName: "Validations",
    subCategoryName: "webAppUISettings",
    settings: [
      WebAppUISettingsKeys.gravityExteriorLBColor,
      WebAppUISettingsKeys.gravityStackedLBColor,
      WebAppUISettingsKeys.gravityNonLBColor,
      WebAppUISettingsKeys.gravityBeamColor,
      WebAppUISettingsKeys.shearWalls,
      WebAppUISettingsKeys.shearObstructionOnCurrentLevel,
      WebAppUISettingsKeys.shearObstructionOnLevelBelow,
    ],
  },

  {
    subCategoryDisplayName: "Areas Take Off",
    subCategoryName: "webAppUISettings",
    settings: [WebAppUISettingsKeys.livingSpaces, WebAppUISettingsKeys.garage, WebAppUISettingsKeys.porchesAndPatio, WebAppUISettingsKeys.roof],
  },
];

let ModalSystemSettings = ({ isOpen, handleModalClose, handleBulkUploadModalOpen }) => {
  const [activeTabKey, setActiveTabKey] = useState<string>(defaultActiveTabKey);
  const [form] = useForm();

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setFormValues(settings.values);
  }, [settings.values, isOpen, form]);

  if (!isOpen) return <></>;

  const handleTabClick = key => {
    setActiveTabKey(key);
  };
  const onCloseClick = () => {
    setActiveTabKey(defaultActiveTabKey);
    handleModalClose();
  };
  const setFormValues = (settingsValues: TSettingsValues) => {
    const formSettings = {};

    for (const settingCategoryKey in settingsValues) {
      if (typeof settingsValues[settingCategoryKey] !== "object") {
        continue;
      }

      formSettings[settingCategoryKey] = {};

      for (const settingKey in defaultSettings[settingCategoryKey]) {
        const setting = settingsValues[settingCategoryKey][settingKey] ?? defaultSettings[settingCategoryKey][settingKey];
        formSettings[settingCategoryKey][settingKey] = getDisplayValue(setting, defaultUnits[settingCategoryKey][settingKey]);
      }
    }
    form.setFieldsValue(formSettings);
  };

  const corePlanTabs = [
    {
      label: "Room Management",
      key: defaultActiveTabKey,
      children: <RoomManagementTab handleModalClose={onCloseClick} handleBulkUploadModalOpen={handleBulkUploadModalOpen} />,
    },
    {
      label: "Request data",
      key: "requestData",
      children: <SettingsTab handleModalClose={onCloseClick} tabSettings={requestDataSettings} form={form} resetToDefaults={setFormValues} />,
    },
    {
      label: "Validations",
      key: "validations",
      children: <SettingsTab handleModalClose={onCloseClick} tabSettings={validationSettings} form={form} resetToDefaults={setFormValues} />,
    },
    {
      label: "Display",
      key: "display",
      children: <SettingsTab handleModalClose={onCloseClick} tabSettings={displaySettings} form={form} resetToDefaults={setFormValues} />,
    },
  ];

  return (
    <Modal className="system-settings-modal" open={isOpen} onCancel={onCloseClick} title="System settings" footer={[]} destroyOnClose>
      <Tabs className="tabs-settings" items={corePlanTabs} activeKey={activeTabKey} onTabClick={handleTabClick} />
    </Modal>
  );
};

ModalSystemSettings = observer(ModalSystemSettings);
export default ModalSystemSettings;
