import { soGroup } from "../soGroup";
import * as THREE from "three";
import { soBoundaryLine } from "./soBoundaryLine";

/**
 * Class representing the boundary of a room.
 * Inherits from soGroup to allow handling multiple boundary lines.
 */
export class soRoomBoundary extends soGroup {
  boundaryLines: soBoundaryLine[];
  boundaryType: string;
  //each RoomBundary is multiple modelLines
  /**
   * Constructs an instance of RoomBoundary.
   * @param boundaryLines - Array of soBoundaryLine objects representing the room boundary.
   * @param boundaryType - Type of the boundary.
   */
  constructor(boundaryLines: soBoundaryLine[], boundaryType: string) {
    super();

    this.boundaryLines = boundaryLines;
    if (boundaryLines.length > 0) {
      this.add(...boundaryLines);
    }
    this.boundaryType = boundaryType;
  }
}
