import { catalogSettings } from "../../../entities/catalogSettings";
import { appModel } from "../../../models/AppModel";
import { Segment } from "../../models/segments/Segment";

export class WallAlignmentManager {
  alignSelectedWallsByPosition(position: string) {
    // centerlize the selected walls - remove offsets if exist
    if (position === "center") {
      Object.entries(appModel.selectedRoomSegments).forEach(([key, segments]) => {
        segments.forEach(item => {
          appModel.removeSegmentOffset(item);
        });
      });
    } else {
      // otherwise, align the selected walls to the position
      const maxThickness = this.findThickestSegment();
      Object.entries(appModel.selectedRoomSegments).forEach(([key, segments]) => {
        segments.forEach(item => {
          const wallThickness = WallAlignmentManager.getSegmentCoreThickness(item);
          if (wallThickness === maxThickness) {
            return;
          }
          let offset = maxThickness - wallThickness;
          offset = position === "left" || position === "bottom" ? -offset : offset;
          item.userData.offset = offset;
          appModel.updateSegmentOffset(item);
        });
      });
    }

    appModel.baseManager.roomManager.runChangeWallWidthAlignmentCommand();
    appModel.baseManager.roomManager.setRoomWallSelected(100);
  }

  findThickestSegment = () => {
    let maxThickness = 0;

    appModel.selectedRoomWall.forEach(wall => {
      const coreThickness = WallAlignmentManager.getSegmentCoreThickness(wall);
      if (coreThickness > maxThickness) {
        maxThickness = coreThickness;
      }
    });
    return maxThickness;
  };

  static getSegmentCoreThickness(item: any): number {
    //check if modified, otherwise get core thickness from passed segment
    const modifiedThicknessWall = appModel.findModifiedSegmentWall(item);
    return modifiedThicknessWall
      ? catalogSettings.walls[modifiedThicknessWall.functionCode].coreThickness
      : catalogSettings.walls[item.userData.segment?.functionCode].coreThickness;
  }

  static getOffset = (segment: Segment): number => {
    return appModel.findSegmentOffset(segment);
  };
}

const wallAlignmentManager = new WallAlignmentManager();
export default wallAlignmentManager;
