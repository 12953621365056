import { IReactionDisposer, reaction } from "mobx";
import * as THREE from "three";

import { appModel } from "../../../models/AppModel";
import { CursorStyle } from "../../../models/CursorStyle";
import { CorePlan } from "../../../models/CorePlan";
import { DragMode } from "../../models/DragMode";
import { Keys } from "../../models/Keys";
import { SceneEditorMode } from "../../models/SceneEditorMode";
import { GraphAnalysisUtils } from "../../utils/GraphAnalysisUtils";
import { SceneEntityType } from "../../models/SceneEntityType";

import SceneManager from "./SceneManager";
import { HOVERD_WALL, SELECTED_WALL, SELECTED_WALL_RENDER_ORDER, WALL_RENDER_ORDER } from "../../consts";
import { settings } from "../../../entities/settings";
import { WebAppUISettingsKeys } from "../../../entities/settings/types";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import { catalogSettings } from "../../../entities/catalogSettings";
import log from "loglevel";
import { IManager } from "../IManager";
import { Direction } from "../../models/Direction";

export default class RoomWallManager implements IManager {
  private reactions: IReactionDisposer[] = [];
  private dragMode: DragMode = DragMode.none;

  constructor(public sceneManager: SceneManager) {
    reaction(() => appModel.activeCorePlan, this.onActiveCorePlanChanged.bind(this), { fireImmediately: true });
    reaction(() => appModel.sceneEditorMode, this.onEditModeChanged.bind(this));
  }

  public onMouseMove(e: MouseEvent) {
    const intersectedWall = this.getIntersectedWall() as THREE.Object3D;
    const intersectedOpening = this.sceneManager.roomOpeningManager.getIntersectedOpening();
    this.resetWallSelection();

    if (intersectedWall && !intersectedOpening) {
      (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Pointer;
      this.handleHoverWall(intersectedWall);
    } else {
      (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Default;
    }
    appModel.selectedRoomWall.forEach(wall => {
      this.paintSelectedWall(wall, true);
    });
    if (appModel.selectedRoomWall.size > 0) {
      appModel.setTooltipOptions({ show: false });
    }
  }

  public onMouseLeave(e: MouseEvent): void {
    appModel.setSceneEditorMode(SceneEditorMode.Room);
  }

  public onMouseUp(e: MouseEvent) {
    appModel.clearSelectedRoomsIds();
    const intersectedOpening = this.sceneManager.roomOpeningManager.getIntersectedOpening();

    if (appModel.isViewOnlyMode || !this.sceneManager.baseManager.isMouseHandlersEnabled) {
      return;
    }
    /////////////////////////////////////////////////////
    // no ctrl
    //     if wall-selected single and wall was clicked => deselect
    //     else reset and select wall clicked
    // ctrl
    //     if wall-selected and wall was clicked => deselect
    //     else => select wall clicked
    /////////////////////////////////////////////////////
    if (this.dragMode === DragMode.none) {
      if (e.button === 0) {
        const intersectedWall = this.getIntersectedWall() as THREE.Object3D;
        const intersectedWallStart = intersectedWall?.userData.segment?.start;

        // CTRL key is not pressed
        if (!e.ctrlKey || !appModel.featureFlags["wallAlignment"]) {
          const selectedWall = appModel.selectedRoomWall.values().next().value;
          const selectedWallStart = selectedWall?.userData.segment?.start;
          if (
            appModel.selectedRoomWall.size == 1 &&
            intersectedWallStart &&
            selectedWallStart &&
            intersectedWallStart.x === selectedWallStart.x &&
            intersectedWallStart.y === selectedWallStart.y
          ) {
            this.resetWallSelection();
            appModel.clearSelectedRoomWalls();
            appModel.setSceneEditorMode(SceneEditorMode.Room);
            return;
          }
          appModel.clearSelectedRoomOpenings();
          // remove color from all walls
          this.resetWallSelection();
          this.clearSelection();
          if (intersectedWall && !intersectedOpening) {
            // replace single selected wall with the new one
            this.addSoWallToSelected(intersectedWall);
          } else {
            appModel.setSceneEditorMode(SceneEditorMode.Room);
          }
        } else {
          // CTRL key is pressed
          // check if the clicked wall is already selected
          if (appModel.selectedRoomWall.has(intersectedWall)) {
            appModel.removeSelectedRoomWall(intersectedWall);
            this.areSelectedRoomWallsEligible();
            this.resetColorSelectedWall(intersectedWall);
            if (appModel.selectedRoomWall.size === 0) {
              appModel.setSceneEditorMode(SceneEditorMode.Room);
            }
          } else {
            this.addSoWallToSelected(intersectedWall);
          }
        }
      }
    }
  }

  public clearSelection() {
    appModel.clearSelectedRoomWalls();
  }

  public init() {
    this.clearSelection();
    this.resetWallSelection();
  }

  public getIntersectedWall(): THREE.Object3D | null {
    const activeFloor = this.getActiveFloorSynteticSoWalls();

    const intersections = this.sceneManager.raycaster.intersectObjects(activeFloor);

    return intersections.length ? intersections[0].object : null;
  }

  public onKeyUp(e: KeyboardEvent) {
    if (appModel.isViewOnlyMode) {
      return;
    }

    switch (e.code) {
      case Keys.Esc: {
        this.clearSelection();
        this.resetWallSelection();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
        break;
      }
      case Keys.Y: {
        this.redo();
        break;
      }
      case Keys.Z: {
        this.undo();
        break;
      }
    }
  }
  //Need to add this.sceneManager.commandManager.add for spesific command
  public undo(): void {
    if (this.dragMode === DragMode.none) {
      const command = this.sceneManager.commandManager.undo();

      if (command) {
        appModel.clearSelectedRoomWalls();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
      }
    }
  }

  //Need to add this.sceneManager.commandManager.add for spesific command
  public redo(): void {
    if (this.dragMode === DragMode.none) {
      const command = this.sceneManager.commandManager.redo();
      if (command) {
        appModel.clearSelectedRoomWalls();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
      }
    }
  }

  public addSoWallToSelected(soWall: THREE.Object3D): void {
    if (!soWall) return;
    this.paintSelectedWall(soWall, false);
    appModel.addSelectedRoomWall(soWall);
    this.areSelectedRoomWallsEligible();
  }

  public handleHoverWall(soWall: THREE.Object3D): void {
    if (!soWall) return;
    const syntheticWalls = this.getActiveFloorSynteticSoWalls();
    const soWallStart = soWall.userData.segment?.start;

    syntheticWalls.forEach(sWall => {
      const sWallStart = sWall.userData.segment?.start;
      if (soWallStart && sWallStart && soWallStart.x === sWallStart.x && soWallStart.y === sWallStart.y) {
        const wallMesh = sWall as THREE.Mesh;
        if (wallMesh.material instanceof THREE.MeshStandardMaterial || wallMesh.material instanceof THREE.MeshBasicMaterial) {
          wallMesh.material.color.set(HOVERD_WALL);
          wallMesh.renderOrder = SELECTED_WALL_RENDER_ORDER;
        }
      }
    });
    appModel.setTooltipOptions({ show: false });
  }

  public leaveWall(): void {
    this.regenerateRoomsWalls();
  }

  public getActiveFloorWall(wall: any): THREE.Object3D {
    return this.getActiveFloorSynteticSoWalls().find(soWall => {
      const soWallbb = new THREE.Box3().setFromObject(soWall);
      const wallbb = new THREE.Box3().setFromObject(wall);
      soWallbb.intersectsBox(wallbb);
    });
  }

  public async onActiveCorePlanChanged(corePlan?: CorePlan): Promise<void> {
    this.unsubscribe(this.reactions);

    if (corePlan) {
      this.subscribeActiveCorePlan();
    }
  }

  private onActiveFloorChanged(): void {
    appModel.clearSelectedRoomOpenings();
  }

  private onEditModeChanged(mode: SceneEditorMode, oldMode: SceneEditorMode): void {
    if (oldMode === SceneEditorMode.RoomOpening) {
      appModel.clearSelectedRoomOpenings();
    }
  }

  private subscribeActiveCorePlan(): void {
    this.reactions.push(reaction(() => appModel.activeFloor, this.onActiveFloorChanged.bind(this)));
  }

  private unsubscribe(reactions: IReactionDisposer[]): void {
    reactions.forEach(r => r());
    reactions.length = 0;
  }

  private getActiveFloorSynteticSoWalls(): THREE.Object3D[] {
    return this.sceneManager.getActiveSoFloor()?.children.flatMap(this.extractSynteticSoWalls) ?? [];
  }

  private extractSynteticSoWalls(soRoom: THREE.Object3D): THREE.Object3D[] {
    return soRoom.children.filter(child => {
      return child.userData.type === SceneEntityType.SyntheticWall;
    });
  }

  public paintSelectedWall(soWall: THREE.Object3D, inMove?: boolean): void {
    if (!soWall) return;
    const syntheticWalls = this.getActiveFloorSynteticSoWalls();
    syntheticWalls.forEach(sWall => {
      const soWallStart = soWall.userData.segment?.start;
      const sWallStart = sWall.userData.segment?.start;
      if (soWallStart && sWallStart && soWallStart.x === sWallStart.x && soWallStart.y === sWallStart.y) {
        if (!inMove) {
          appModel.addSelectedRoomSegment(sWall);
        }
        const wallMesh = sWall as THREE.Mesh;
        if (wallMesh.material instanceof THREE.MeshStandardMaterial || wallMesh.material instanceof THREE.MeshBasicMaterial) {
          wallMesh.material.color.set(SELECTED_WALL);
          wallMesh.renderOrder = SELECTED_WALL_RENDER_ORDER;
        }
      }
    });
  }

  private resetColorSelectedWall(wall: any): void {
    wall.material.color.set(settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
    wall.renderOrder = WALL_RENDER_ORDER;
  }

  // Function to check if any segments in the array intersect
  private checkIntersections(wallSegments1: any[], wallSegments2: any[]): number {
    for (let i = 0; i < wallSegments1.length; i++) {
      for (let j = 0; j < wallSegments2.length; j++) {
        const wallBb1 = GeometryUtils.getGeometryBoundingBox2D(wallSegments1[i]);
        const l1 = GeometryUtils.getBoundingBoxCenterLine(wallBb1);
        const wallBb2 = GeometryUtils.getGeometryBoundingBox2D(wallSegments2[j]);
        if (GeometryUtils.lineIntersectsBoundingBox(l1, wallBb2)) {
          return 1;
        }
      }
    }
    return 0;
  }

  /**
   * Checks if all selected walls in the multi-select set on the same axis and intersects each other.
   * If the size of multi-select is 1 => true.
   * Otherwise, it iterates through the walls segements to check all segements intersections (2 - both sides) and
   * checks that only 2 edges on both side have only 1 intersect.
   *
   * @private
   * @returns {void}
   */
  private areSelectedRoomWallsEligible(): void {
    if (!appModel.featureFlags["wallAlignment"]) {
      return;
    }
    if (appModel.selectedRoomWall.size == 1) {
      appModel.isSelectedRoomWallsEligible = true;
      return;
    }
    const edgesSet = new Set();
    let isSelectedRoomWallsEligible = true;
    let direction = null;

    for (const index1 in appModel.selectedRoomSegments) {
      let intersectCount = 0;

      for (const index2 in appModel.selectedRoomSegments) {
        // skip the same wall
        if (index1 !== index2) {
          if (this.checkIntersections(appModel.selectedRoomSegments[index1], appModel.selectedRoomSegments[index2])) {
            intersectCount++;
          }
        }
      }
      const wall1Bb = GeometryUtils.getGeometryBoundingBox2D(appModel.selectedRoomSegments[index1][0]);
      const wallDrection = GeometryUtils.getLineDirection(GeometryUtils.getBoundingBoxCenterLine(wall1Bb));
      if (!direction) {
        direction = wallDrection;
      }

      if (!intersectCount || direction !== wallDrection) {
        isSelectedRoomWallsEligible = false;
        break;
      } else if (intersectCount == 1) {
        edgesSet.add(index1);
      }
      // else it intersects both sides
    }

    appModel.isSelectedRoomSegmentsHorizontal = direction === Direction.Horizontal;

    if (edgesSet.size !== 2) {
      // 2 edges are intersected only once or not at all. On the wall line can be only 2 edges on both side: <-|-----|-----|-----|->
      isSelectedRoomWallsEligible = false;
    }

    appModel.isSelectedRoomWallsEligible = isSelectedRoomWallsEligible && !this.areWallsHaveSameThickness();
  }

  private getSegmentCoreThickness(item: any): number {
    //check if modified, otherwise get core thickness from passed segment
    const modifiedThicknessWall = appModel.findModifiedSegmentWall(item);
    return modifiedThicknessWall
      ? catalogSettings.walls[modifiedThicknessWall.functionCode].coreThickness
      : catalogSettings.walls[item.userData.segment?.functionCode].coreThickness;
  }

  /**
   * Checks if all selected walls in the multi-select have same thickness
   * Pre-condition: multi-select is eligible.
   * If the size of multi-select is 1 => true.
   *
   * @private
   * @returns {boolean}
   */
  private areWallsHaveSameThickness(): boolean {
    if (!appModel.featureFlags["wallAlignment"]) {
      return true;
    }

    const firstObject = Object.values(appModel.selectedRoomSegments)[0][0];

    const initialThickness = this.getSegmentCoreThickness(firstObject);
    let wallsHaveSameThicknessOrAligned = true;

    Object.entries(appModel.selectedRoomSegments).forEach(([key, segments]) => {
      segments.forEach(item => {
        if (this.getSegmentCoreThickness(item) !== initialThickness) {
          wallsHaveSameThicknessOrAligned = false;
          return;
        }
      });
    });

    return wallsHaveSameThicknessOrAligned;
  }

  private resetWallSelection(): void {
    const activeFloor = this.getActiveFloorSynteticSoWalls();
    activeFloor.forEach(wall => this.resetColorSelectedWall(wall));
  }

  private regenerateRoomsWalls() {
    const activeFloor = this.sceneManager.getActiveSoFloor();
    if (activeFloor) {
      GraphAnalysisUtils.regenerateRoomsWalls(this.sceneManager, activeFloor);
    }
  }
}
