import * as THREE from "three";
import { appModel } from "../../models/AppModel";
import RoomManager from "../managers/RoomManager/RoomManager";
import { Direction } from "../models/Direction";
import { SnapData } from "../models/SnapData";
import GeometryUtils from "../utils/GeometryUtils/GeometryUtils";
import SceneUtils from "../utils/SceneUtils";
import UnitsUtils from "../utils/UnitsUtils";
import { WallAnalysisUtils } from "../utils/WallAnalysisUtils";
import { MessageKindsEnum, showToastMessage } from "../../helpers/messages";
import MathUtils from "../utils/MathUtils";
import { Side } from "../../models/Side";
import { Segment } from "../models/segments/Segment";
import { RoomEntityType } from "../../models/RoomEntityType";
import {
  GRID_MAJOR_CELL_RATIO,
  GRID_MINOR_CELL_RATIO,
  GRID_MIN_CELL_SIZE_CUTOFF_2D,
  MESSAGE_DURATION,
  MODEL_LINE_COLOR,
  MODEL_LINE_RENDER_ORDER,
  SNAP_ERROR_MESSAGE,
  SNAP_WARNING_MESSAGE,
} from "../consts";
import SnapEvent from "../models/SnapEvent";
import RoomUtils from "../utils/RoomUtils";
import { WallType } from "../../entities/catalogSettings/types";
import SceneManager from "../managers/SceneManager/SceneManager";
import { GraphAnalysisUtils } from "../utils/GraphAnalysisUtils";
import FloorUtils from "../utils/FloorUtils";
import { soRoom2D } from "../models/SceneObjects/Room/soRoom2D";

enum FloorMode {
  Same = "Same",
  Other = "Other",
  All = "All",
}

const H = Direction.Horizontal;
const V = Direction.Vertical;

export default class RoomSnapTool {
  private precisionFactor: number = 150.0;
  private snapData: { [key in Direction]: SnapData } = {
    [H]: null,
    [V]: null,
  };
  private soRoot = new THREE.Group();
  public snapPerformed: boolean = false;
  public statusChanged: boolean = false;
  private analysisUtils = this.roomManager instanceof SceneManager ? GraphAnalysisUtils : WallAnalysisUtils;
  constructor(private roomManager: any) {
    if (!(this.roomManager instanceof RoomManager) && !(this.roomManager instanceof SceneManager)) {
      throw new Error("Manager is not an instance of RoomManager");
    }
    this.soRoot.name = "Room Snap Tool Root";
    this.roomManager.getSoRoot().add(this.soRoot);
  }

  /**
   * Performs snapping of the primary object to align with other objects or grid lines.
   * @param primaryObject - The main object to perform snapping on.
   * @param secondaryObjects - Optional array of secondary objects to move along with the primary object.
   */
  public performSnapping(primaryObject: THREE.Object3D, secondaryObjects: THREE.Object3D[] = []): void {
    if (!primaryObject) {
      return;
    }

    // Initialize snapping data for horizontal (H) and vertical (V) directions
    this.initializeSnapData();

    // Calculate the snapping tolerance based on precision factors and unit conversions
    const tolerance = this.calculateTolerance();

    // Get the bounding box of the primary object
    const primaryBoundingBox = RoomUtils.getRoomBoundingBoxByModelLines(primaryObject);

    // Variables to store alignment offsets and colinear snapping flags
    let alignOffsetH = 0;
    let alignOffsetV = 0;
    let colinearSnapH = false;
    let colinearSnapV = false;

    // Step 1: Check snapping on the same floor
    ({ alignOffsetH, alignOffsetV } = this.checkSnappingOnSameFloor(primaryObject, primaryBoundingBox, tolerance));

    //Step 2: Check snapping to colinear walls on the same floor
    if (this.shouldCheckColinearSnapping(tolerance)) {
      ({ colinearSnapH, colinearSnapV } = this.checkColinearSnapping(primaryObject, primaryBoundingBox, tolerance));
    }

    // Step 3: Check snapping on other floors
    if (this.shouldCheckOtherFloorSnapping(tolerance)) {
      ({ alignOffsetH, alignOffsetV } = this.checkSnappingOnOtherFloors(primaryObject, primaryBoundingBox, tolerance));
    }

    // Step 4: Check snapping to grid
    if (this.shouldCheckGridSnapping(tolerance)) {
      ({ alignOffsetH, alignOffsetV } = this.checkSnappingToGrid(primaryBoundingBox, tolerance));
    }

    // Apply snapping adjustments to the primary and secondary objects
    this.applySnappingAdjustments(primaryObject, secondaryObjects, alignOffsetH, alignOffsetV, colinearSnapH, colinearSnapV, tolerance);
  }

  /**
   * Initializes the snapping data for horizontal and vertical directions.
   */
  private initializeSnapData(): void {
    this.snapData[H] = new SnapData(H);
    this.snapData[V] = new SnapData(V);
    this.deleteColinearSnapLines();
  }

  /**
   * Calculates the snapping tolerance based on the room manager's recommended precision.
   * @returns The calculated tolerance value.
   */
  private calculateTolerance(): number {
    return this.roomManager.getRaycasterRecommendedPrecision() * this.precisionFactor * UnitsUtils.getConversionFactor();
  }

  /**
   * Checks snapping on the same floor and updates snapping data.
   * @param primaryObject - The main object to perform snapping on.
   * @param boundingBox - The bounding box of the primary object.
   * @param tolerance - The snapping tolerance.
   * @returns An object containing horizontal and vertical alignment offsets.
   */
  private checkSnappingOnSameFloor(primaryObject: THREE.Object3D, boundingBox: THREE.Box3, tolerance: number): { alignOffsetH: number; alignOffsetV: number } {
    let alignOffsetH = 0;
    let alignOffsetV = 0;

    const snapResult = this.getSnapData(FloorMode.Same, primaryObject, boundingBox, tolerance);

    if (snapResult.H.absDistance <= tolerance) {
      alignOffsetH = this.calculateAlignmentOffset(snapResult.H, tolerance);
      RoomSnapTool.updateSnapDataSharedObjects(
        snapResult.H,
        primaryObject,
        this.roomManager.getCorePlanSoRoom(snapResult.H.otherRoomId),
        snapResult.H.distance,
        alignOffsetH
      );
      this.snapData[H] = snapResult.H;
    }

    if (snapResult.V.absDistance <= tolerance) {
      alignOffsetV = this.calculateAlignmentOffset(snapResult.V, tolerance);
      RoomSnapTool.updateSnapDataSharedObjects(
        snapResult.V,
        primaryObject,
        this.roomManager.getCorePlanSoRoom(snapResult.V.otherRoomId),
        alignOffsetV,
        snapResult.V.distance
      );
      this.snapData[V] = snapResult.V;
    }

    return { alignOffsetH, alignOffsetV };
  }

  /**
   * Determines if colinear snapping should be checked based on current snapping data.
   * @param tolerance - The snapping tolerance.
   * @returns True if colinear snapping should be checked; otherwise, false.
   */
  private shouldCheckColinearSnapping(tolerance: number): boolean {
    return this.snapData[H].absDistance > tolerance || this.snapData[V].absDistance > tolerance;
  }

  /**
   * Checks snapping to colinear walls on the same floor and updates snapping data.
   * @param primaryObject - The main object to perform snapping on.
   * @param boundingBox - The bounding box of the primary object.
   * @param tolerance - The snapping tolerance.
   * @returns An object containing colinear snapping flags for horizontal and vertical directions.
   */
  private checkColinearSnapping(primaryObject: THREE.Object3D, boundingBox: THREE.Box3, tolerance: number): { colinearSnapH: boolean; colinearSnapV: boolean } {
    let colinearSnapH = false;
    let colinearSnapV = false;

    const snapResult = this.getSnapDataCollinear(primaryObject, boundingBox);

    if (snapResult.H.absDistance <= tolerance) {
      this.snapData[H] = snapResult.H;
      colinearSnapH = true;
    }

    if (snapResult.V.absDistance <= tolerance) {
      this.snapData[V] = snapResult.V;
      colinearSnapV = true;
    }

    return { colinearSnapH, colinearSnapV };
  }

  /**
   * Determines if snapping on other floors should be checked based on current snapping data.
   * @param tolerance - The snapping tolerance.
   * @returns True if snapping on other floors should be checked; otherwise, false.
   */
  private shouldCheckOtherFloorSnapping(tolerance: number): boolean {
    return this.snapData[H].absDistance > tolerance || this.snapData[V].absDistance > tolerance;
  }

  /**
   * Checks snapping on other floors and updates snapping data.
   * @param primaryObject - The main object to perform snapping on.
   * @param boundingBox - The bounding box of the primary object.
   * @param tolerance - The snapping tolerance.
   * @returns An object containing horizontal and vertical alignment offsets.
   */
  private checkSnappingOnOtherFloors(
    primaryObject: THREE.Object3D,
    boundingBox: THREE.Box3,
    tolerance: number
  ): { alignOffsetH: number; alignOffsetV: number } {
    let alignOffsetH = 0;
    let alignOffsetV = 0;

    const snapResult = this.getSnapData(FloorMode.Other, primaryObject, boundingBox, tolerance);

    if (snapResult.H.absDistance <= tolerance) {
      alignOffsetH = this.calculateAlignmentOffset(snapResult.H, tolerance);
      this.snapData[H] = snapResult.H;
    }

    if (snapResult.V.absDistance <= tolerance) {
      alignOffsetV = this.calculateAlignmentOffset(snapResult.V, tolerance);
      this.snapData[V] = snapResult.V;
    }

    return { alignOffsetH, alignOffsetV };
  }

  /**
   * Determines if grid snapping should be checked based on current snapping data and application settings.
   * @param tolerance - The snapping tolerance.
   * @returns True if grid snapping should be checked; otherwise, false.
   */
  private shouldCheckGridSnapping(tolerance: number): boolean {
    return appModel.showGrid && appModel.snapToGrid && this.snapData[H].absDistance > tolerance && this.snapData[V].absDistance > tolerance;
  }

  /**
   * Checks snapping to the grid and updates snapping data.
   * @param boundingBox - The bounding box of the primary object.
   * @param tolerance - The snapping tolerance.
   * @returns An object containing horizontal and vertical alignment offsets.
   */
  private checkSnappingToGrid(boundingBox: THREE.Box3, tolerance: number): { alignOffsetH: number; alignOffsetV: number } {
    let alignOffsetH = 0;
    let alignOffsetV = 0;

    const gridSnapResult = RoomSnapTool.getSnapDataByBoundingBoxInGrid(
      boundingBox,
      tolerance,
      GeometryUtils.getSceneUnitPixels(this.roomManager.camera, this.roomManager.baseManager.renderer)
    );

    if (gridSnapResult.H.absDistance <= tolerance) {
      alignOffsetH = this.calculateAlignmentOffset(gridSnapResult.H, tolerance);
      this.snapData[H] = gridSnapResult.H;
    }

    if (gridSnapResult.V.absDistance <= tolerance) {
      alignOffsetV = this.calculateAlignmentOffset(gridSnapResult.V, tolerance);
      this.snapData[V] = gridSnapResult.V;
    }

    return { alignOffsetH, alignOffsetV };
  }

  /**
   * Applies the final snapping adjustments to the primary and secondary objects.
   * @param primaryObject - The main object to adjust.
   * @param secondaryObjects - Array of secondary objects to move along with the primary object.
   * @param alignOffsetH - Horizontal alignment offset.
   * @param alignOffsetV - Vertical alignment offset.
   * @param colinearSnapH - Flag indicating horizontal colinear snapping.
   * @param colinearSnapV - Flag indicating vertical colinear snapping.
   * @param tolerance - The snapping tolerance.
   */
  private applySnappingAdjustments(
    primaryObject: THREE.Object3D,
    secondaryObjects: THREE.Object3D[],
    alignOffsetH: number,
    alignOffsetV: number,
    colinearSnapH: boolean,
    colinearSnapV: boolean,
    tolerance: number
  ): void {
    if (this.snapData[H].absDistance <= tolerance || this.snapData[V].absDistance <= tolerance) {
      let offsetX = 0;
      let offsetY = 0;
      if (this.snapData[H].absDistance <= tolerance) {
        offsetX = this.snapData[H].distance;
        offsetY = this.snapData[V].absDistance <= tolerance ? this.snapData[V].distance : alignOffsetH;
      }

      if (this.snapData[V].absDistance <= tolerance) {
        offsetX = this.snapData[H].absDistance <= tolerance ? this.snapData[H].distance : alignOffsetV;
        offsetY = this.snapData[V].distance;
      }

      if (offsetX !== 0 || offsetY !== 0) {
        secondaryObjects.push(primaryObject);
        secondaryObjects.forEach(object => {
          const position = object.position.clone();
          object.position.copy(position.setX(position.x + offsetX).setY(position.y + offsetY));

          object.updateMatrixWorld();
        });

        const updatedBoundingBox = RoomUtils.getRoomBoundingBoxByModelLines(primaryObject);
        if (colinearSnapH || colinearSnapV) {
          this.addColinearSnapLines(primaryObject, updatedBoundingBox);
        }
      }
      this.setSnapStatus(true);
    } else {
      this.setSnapStatus(false);
    }
  }

  private setSnapStatus(status: boolean): void {
    if (this.snapPerformed !== status) {
      this.snapPerformed = status;
      this.statusChanged = true;
    } else {
      this.snapPerformed = status;
      this.statusChanged = false;
    }
  }
  /**
   * Calculates the alignment offset based on snapping data and tolerance.
   * @param snapData - The snapping data for a specific direction.
   * @param tolerance - The snapping tolerance.
   * @returns The calculated alignment offset.
   */
  private calculateAlignmentOffset(snapData: SnapData, tolerance: number): number {
    if (snapData.absAlignDistance > tolerance) {
      return snapData.cornerDistance > tolerance ? 0 : snapData.cornerDistance;
    } else {
      return snapData.alignDistance;
    }
  }

  public checkSnappingWhileMovingStretchTriangles(
    primaryObject: THREE.Object3D,
    direction: Direction,
    sign: number,
    tolerance?: number,
    soRooms?: THREE.Object3D[]
  ): SnapData {
    this.snapData[H] = new SnapData(H);
    this.snapData[V] = new SnapData(V);

    this.deleteColinearSnapLines();

    if (tolerance === undefined) {
      tolerance = this.roomManager.getRaycasterRecommendedPrecision() * this.precisionFactor * UnitsUtils.getConversionFactor();
    }

    const bb = RoomUtils.getRoomBoundingBoxByModelLines(primaryObject);
    const primaryBb = new THREE.Box3();
    if (direction === H) {
      if (sign === 1) {
        // right side
        primaryBb.expandByPoint(bb.max);
        primaryBb.expandByPoint(new THREE.Vector3(bb.max.x, bb.min.y, 0));
      } else {
        // left side
        primaryBb.expandByPoint(bb.min);
        primaryBb.expandByPoint(new THREE.Vector3(bb.min.x, bb.max.y, 0));
      }
    } else {
      if (sign === 1) {
        // top side
        primaryBb.expandByPoint(bb.max);
        primaryBb.expandByPoint(new THREE.Vector3(bb.min.x, bb.max.y, 0));
      } else {
        // bottom side
        primaryBb.expandByPoint(bb.min);
        primaryBb.expandByPoint(new THREE.Vector3(bb.max.x, bb.min.y, 0));
      }
    }

    // 1) check snapping to other rooms on all floors
    const { [direction]: result } = this.getSnapData(FloorMode.All, primaryObject, primaryBb, tolerance, soRooms);
    if (result.absDistance <= tolerance) {
      const offsetX = direction === H ? result.distance : 0;
      const offsetY = direction === H ? 0 : result.distance;
      RoomSnapTool.updateSnapDataSharedObjects(result, primaryObject, this.roomManager.getCorePlanSoRoom(result.otherRoomId), offsetX, offsetY);
      this.snapData[direction] = result;
    }

    // 2) check snapping to colinear walls on the same floor
    if (this.snapData[direction].absDistance > tolerance) {
      const { [direction]: result } = this.getSnapDataCollinear(primaryObject, primaryBb);
      if (result.absDistance <= tolerance) {
        this.snapData[direction] = result;
      }
    }

    // 3) check snapping to grid
    if (appModel.showGrid && appModel.snapToGrid && this.snapData[direction].absDistance > tolerance) {
      const { [direction]: result } = RoomSnapTool.getSnapDataByBoundingBoxInGrid(
        primaryBb,
        tolerance,
        GeometryUtils.getSceneUnitPixels(this.roomManager.camera, this.roomManager.baseManager.renderer)
      );
      if (result.absDistance <= tolerance) {
        this.snapData[direction] = result;
      }
    }

    return this.snapData[direction];
  }
  public showSnappingMessages(): void {
    if (this.snapData[H]?.hasIntersectedSharedObjects) {
      const bbox = RoomUtils.getRoomBoundingBox(this.roomManager.getActiveFloorSoRoom(this.snapData[H].roomId));
      const bbox2 = RoomUtils.getRoomBoundingBox(this.roomManager.getCorePlanSoRoom(this.snapData[H].otherRoomId));
      if (bbox.intersectsBox(bbox2)) {
        showToastMessage(MessageKindsEnum.Error, SNAP_ERROR_MESSAGE, { autoClose: MESSAGE_DURATION });
      }
    } else if (this.snapData[V]?.hasIntersectedSharedObjects) {
      const bbox = RoomUtils.getRoomBoundingBox(this.roomManager.getActiveFloorSoRoom(this.snapData[V].roomId));
      const bbox2 = RoomUtils.getRoomBoundingBox(this.roomManager.getCorePlanSoRoom(this.snapData[V].otherRoomId));
      if (bbox.intersectsBox(bbox2)) {
        showToastMessage(MessageKindsEnum.Error, SNAP_ERROR_MESSAGE, { autoClose: MESSAGE_DURATION });
      }
    } else if (this.snapData[H]?.hasSharedObjects || this.snapData[V]?.hasSharedObjects) {
      showToastMessage(MessageKindsEnum.Warning, SNAP_WARNING_MESSAGE, { autoClose: MESSAGE_DURATION });
    }
  }
  public addColinearSnapLines(/*colinearSnapH: boolean, colinearSnapV: boolean, */ primaryObject: THREE.Object3D, primaryBB: THREE.Box3): void {
    // if (colinearSnapH || colinearSnapV) {
    const soRooms = this.roomManager
      .getVisibleSoFloors()
      .flatMap(soFloor => FloorUtils.getFloorSoRooms(soFloor))
      .filter(soRoom => RoomUtils.getRoomId(soRoom) !== RoomUtils.getRoomId(primaryObject) && !appModel.selectedRoomsIds.includes(RoomUtils.getRoomId(soRoom)));
    const currentFloorSoRooms = soRooms.filter(soRoom => appModel.activeFloor.rooms.some(r => r.id === RoomUtils.getRoomId(soRoom)));

    const leftYY: number[] = [primaryBB.min.y, primaryBB.max.y];
    const rightYY: number[] = [primaryBB.min.y, primaryBB.max.y];
    const bottomXX: number[] = [primaryBB.min.x, primaryBB.max.x];
    const topXX: number[] = [primaryBB.min.x, primaryBB.max.x];

    currentFloorSoRooms.forEach(soRoom => {
      const bb = RoomUtils.getRoomBoundingBoxByModelLines(soRoom);

      // if (colinearSnapH) {
      if (MathUtils.areNumbersEqual(primaryBB.min.x, bb.min.x) || MathUtils.areNumbersEqual(primaryBB.min.x, bb.max.x)) {
        leftYY.push(bb.min.y, bb.max.y);
      }
      if (MathUtils.areNumbersEqual(primaryBB.max.x, bb.min.x) || MathUtils.areNumbersEqual(primaryBB.max.x, bb.max.x)) {
        rightYY.push(bb.min.y, bb.max.y);
      }
      // }
      // if (colinearSnapV) {
      if (MathUtils.areNumbersEqual(primaryBB.min.y, bb.min.y) || MathUtils.areNumbersEqual(primaryBB.min.y, bb.max.y)) {
        bottomXX.push(bb.min.x, bb.max.x);
      }
      if (MathUtils.areNumbersEqual(primaryBB.max.y, bb.min.y) || MathUtils.areNumbersEqual(primaryBB.max.y, bb.max.y)) {
        topXX.push(bb.min.x, bb.max.x);
      }
      // }
    });

    const fnSort = (n1: number, n2: number) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    };

    if (leftYY.length >= 4) {
      const ar = leftYY.sort(fnSort);
      const p1 = new THREE.Vector3(primaryBB.min.x, ar[1], 0);
      const p2 = new THREE.Vector3(primaryBB.min.x, ar[ar.length - 2], 0);
      this.soRoot.add(RoomSnapTool.createColinearSnapLine(p1, p2));
    }
    if (rightYY.length >= 4) {
      const ar = rightYY.sort(fnSort);
      const p1 = new THREE.Vector3(primaryBB.max.x, ar[1], 0);
      const p2 = new THREE.Vector3(primaryBB.max.x, ar[ar.length - 2], 0);
      this.soRoot.add(RoomSnapTool.createColinearSnapLine(p1, p2));
    }

    if (bottomXX.length >= 4) {
      const ar = bottomXX.sort(fnSort);
      const p1 = new THREE.Vector3(ar[1], primaryBB.min.y, 0);
      const p2 = new THREE.Vector3(ar[ar.length - 2], primaryBB.min.y, 0);
      this.soRoot.add(RoomSnapTool.createColinearSnapLine(p1, p2));
    }
    if (topXX.length >= 4) {
      const ar = topXX.sort(fnSort);
      const p1 = new THREE.Vector3(ar[1], primaryBB.max.y, 0);
      const p2 = new THREE.Vector3(ar[ar.length - 2], primaryBB.max.y, 0);
      this.soRoot.add(RoomSnapTool.createColinearSnapLine(p1, p2));
    }
    // }
  }
  public end() {
    this.deleteColinearSnapLines();
  }

  // --------------------------------------------

  private getSnapData(
    floorMode: FloorMode,
    primaryObject: THREE.Object3D,
    primaryBb: THREE.Box3,
    tolerance: number,
    soRooms?: any
  ): { [key in Direction]: SnapData } {
    const result: { [key in Direction]: SnapData } = {
      [H]: new SnapData(H),
      [V]: new SnapData(V),
    };

    if (!soRooms) {
      soRooms = this.roomManager
        .getVisibleSoFloors()
        .flatMap(soFloor => FloorUtils.getFloorSoRooms(soFloor))
        .filter(
          soRoom => RoomUtils.getRoomId(soRoom) !== RoomUtils.getRoomId(primaryObject) && !appModel.selectedRoomsIds.includes(RoomUtils.getRoomId(soRoom))
        );
    }

    const currentFloorSoRooms = soRooms.filter(soRoom => appModel.activeFloor.rooms.some(r => r.id === RoomUtils.getRoomId(soRoom)));
    const { internalSegments } = this.analysisUtils.collectSegments(currentFloorSoRooms);

    if (floorMode == FloorMode.Same || floorMode == FloorMode.All) {
      // current floor
      for (let i = 0; i < currentFloorSoRooms.length; i++) {
        const snapData = RoomSnapTool.getSnapDataByBoundingBoxes(
          primaryBb,
          RoomUtils.getRoomBoundingBoxByModelLines(currentFloorSoRooms[i]),
          tolerance,
          true,
          internalSegments
        );

        if (snapData[H] && (result[H].absDistance > snapData[H].absDistance || result[H].alignDistance > snapData[H].alignDistance)) {
          result[H] = snapData[H];
          result[H].isSameFloor = true;
          result[H].roomId = primaryObject.userData.id;
          result[H].otherRoomId = currentFloorSoRooms[i].userData.id;
          result[H].snapEvent = new SnapEvent(primaryObject, currentFloorSoRooms[i], tolerance);
        }
        if (snapData[V] && (result[V].absDistance > snapData[V].absDistance || result[V].alignDistance > snapData[V].alignDistance)) {
          result[V] = snapData[V];
          result[V].isSameFloor = true;
          result[V].roomId = primaryObject.userData.id;
          result[V].otherRoomId = currentFloorSoRooms[i].userData.id;
          result[H].snapEvent = new SnapEvent(primaryObject, currentFloorSoRooms[i], tolerance);
        }
      }
    }

    if (floorMode == FloorMode.Other || (floorMode == FloorMode.All && (result[H].absDistance > tolerance || result[V].absDistance > tolerance))) {
      // other floors
      const otherFloorSoRooms = soRooms.filter(soRoom => !appModel.activeFloor.rooms.some(r => r.id === soRoom.userData.id));
      for (let i = 0; i < otherFloorSoRooms.length; i++) {
        const snapData = RoomSnapTool.getSnapDataByBoundingBoxes(
          primaryBb,
          RoomUtils.getRoomBoundingBoxByModelLines(otherFloorSoRooms[i]),
          tolerance,
          false,
          internalSegments
        );

        if (snapData[H] && (result[H].absDistance > snapData[H].absDistance || result[H].alignDistance > snapData[H].alignDistance)) {
          result[H] = snapData[H];
          result[H].isSameFloor = false;
          result[H].roomId = primaryObject.userData.id;
          result[H].otherRoomId = otherFloorSoRooms[i].userData.id;
        }
        if (snapData[V] && (result[V].absDistance > snapData[V].absDistance || result[V].alignDistance > snapData[V].alignDistance)) {
          result[V] = snapData[V];
          result[V].isSameFloor = false;
          result[V].roomId = primaryObject.userData.id;
          result[V].otherRoomId = otherFloorSoRooms[i].userData.id;
        }
      }
    }

    return result;
  }
  private getSnapDataCollinear(primaryObject: THREE.Object3D, primaryBb: THREE.Box3): { [key in Direction]: SnapData } {
    const result: { [key in Direction]: SnapData } = {
      [H]: new SnapData(H),
      [V]: new SnapData(V),
    };

    const soRooms = this.roomManager
      .getVisibleSoFloors()
      .flatMap(soFloor => soFloor.children)
      .filter(soRoom => primaryObject !== soRoom && !appModel.selectedRoomsIds.includes(soRoom.userData.id));
    const currentFloorSoRooms = soRooms.filter(soRoom => appModel.activeFloor.rooms.some(r => r.id === soRoom.userData.id));

    for (let i = 0; i < currentFloorSoRooms.length; i++) {
      const bb = RoomUtils.getRoomBoundingBoxByModelLines(currentFloorSoRooms[i]);

      // Avoid collinear snapping for intersected rooms (from inside).
      if (primaryBb.intersectsBox(bb)) {
        continue;
      }

      let offsetX = bb.min.x - primaryBb.min.x;
      if (Math.abs(offsetX) < Math.abs(result[H].distance)) {
        result[H].distance = offsetX;
      }
      offsetX = bb.max.x - primaryBb.min.x;
      if (Math.abs(offsetX) < Math.abs(result[H].distance)) {
        result[H].distance = offsetX;
      }
      offsetX = bb.min.x - primaryBb.max.x;
      if (Math.abs(offsetX) < Math.abs(result[H].distance)) {
        result[H].distance = offsetX;
      }
      offsetX = bb.max.x - primaryBb.max.x;
      if (Math.abs(offsetX) < Math.abs(result[H].distance)) {
        result[H].distance = offsetX;
      }

      let offsetY = bb.min.y - primaryBb.min.y;
      if (Math.abs(offsetY) < Math.abs(result[V].distance)) {
        result[V].distance = offsetY;
      }
      offsetY = bb.max.y - primaryBb.min.y;
      if (Math.abs(offsetY) < Math.abs(result[V].distance)) {
        result[V].distance = offsetY;
      }
      offsetY = bb.min.y - primaryBb.max.y;
      if (Math.abs(offsetY) < Math.abs(result[V].distance)) {
        result[V].distance = offsetY;
      }
      offsetY = bb.max.y - primaryBb.max.y;
      if (Math.abs(offsetY) < Math.abs(result[V].distance)) {
        result[V].distance = offsetY;
      }
    }

    return result;
  }
  private deleteColinearSnapLines(): void {
    while (this.soRoot.children.length > 0) {
      GeometryUtils.disposeObject(this.soRoot.children[0]);
      this.soRoot.remove(this.soRoot.children[0]);
    }
  }

  private static getSnapDataByBoundingBoxes(
    bb: THREE.Box3,
    bbOther: THREE.Box3,
    tolerance: number = UnitsUtils.getSnapTolerance(),
    isSameFloors = false,
    internalSegments?: Segment[] // shared wall parts
  ): { [key in Direction]: SnapData | null } {
    const result = { [H]: new SnapData(H), [V]: new SnapData(V) };
    const internalWallSnap = { [H]: null, [V]: null };

    // Calculate centers and sizes of bounding boxes
    const center = bb.getCenter(new THREE.Vector3());
    const size = bb.getSize(new THREE.Vector3());
    const center2 = bbOther.getCenter(new THREE.Vector3());
    const size2 = bbOther.getSize(new THREE.Vector3());

    // Calculate distances between the bounding boxes
    let distX = Math.abs(center.x - center2.x) - size.x / 2.0 - size2.x / 2.0;
    let distY = Math.abs(center.y - center2.y) - size.y / 2.0 - size2.y / 2.0;

    // Handle zero distances
    distX = MathUtils.areNumbersEqual(0, distX) ? 0 : distX;
    distY = MathUtils.areNumbersEqual(0, distY) ? 0 : distY;

    // Determine snapping based on distances
    if (distX <= tolerance || distY <= tolerance) {
      if (distX <= tolerance && distY <= tolerance && distX > 0 && distY > 0 && size.x > 0 && size.y > 0) {
        // Snap to corner
        const cornerDistX = bb.max.x <= bbOther.min.x ? distX : -distX;
        const cornerDistY = bb.min.y <= bbOther.min.y ? distY : -distY;
        result[H] = new SnapData(H, cornerDistX);
        this.alignSnapData(bb, bbOther, result[H], tolerance, H, cornerDistY);
        result[V] = new SnapData(V, cornerDistY);
        this.alignSnapData(bb, bbOther, result[V], tolerance, V, cornerDistX);
        return result;
      }

      // Use the external method to set snap data
      this.setSnapDataBySides(result, internalWallSnap, bb, bbOther, distX, distY, tolerance, isSameFloors, internalSegments);
    }

    // Nullify results exceeding tolerance
    if (result[H].absDistance > tolerance) {
      result[H] = null;
    }
    if (result[V].absDistance > tolerance) {
      result[V] = null;
    }

    // Return results, prioritizing internal wall snaps if no external snaps are found
    return !result[H] && !result[V] ? internalWallSnap : result;
  }

  /**
   * Sets snap data for given sides and direction.
   *
   * @param result - The snap data result object.
   * @param internalWallSnap - The snap data for internal walls.
   * @param bb - The first bounding box.
   * @param bbOther - The second bounding box.
   * @param distX - The distance in the X direction.
   * @param distY - The distance in the Y direction.
   * @param tolerance - The tolerance for snapping.
   * @param isSameFloors - Indicates if the boxes are on the same floor.
   * @param internalSegments - Internal wall segments for snapping to.
   */
  private static setSnapDataBySides(
    result: { [key in Direction]: SnapData },
    internalWallSnap: { [key in Direction]: SnapData },
    bb: THREE.Box3,
    bbOther: THREE.Box3,
    distX: number,
    distY: number,
    tolerance: number,
    isSameFloors: boolean,
    internalSegments?: Segment[] // shared wall parts
  ): void {
    let tempSnap = new SnapData();

    const updateSnapData = (direction: Direction, side: Side, sideOther: Side, distance: number, distanceOther: number) => {
      if (tempSnap.absDistance <= tolerance) {
        if (this.checkSnapToInternalWall(bb, direction, side, tempSnap.absDistance, internalSegments || [])) {
          internalWallSnap[direction] = tempSnap;
        } else {
          result[direction] = tempSnap;
        }
      }
    };

    const checkSides = (direction: Direction, side: Side, sideOther: Side) => {
      let distance = Number.MAX_VALUE;
      let distanceOther = Number.MAX_VALUE;

      if (direction === H) {
        distanceOther = distY;
        if (side === Side.Left) {
          distance = sideOther === Side.Left ? bb.min.x - bbOther.min.x : bb.min.x - bbOther.max.x;
        } else if (side === Side.Right) {
          distance = sideOther === Side.Left ? bb.max.x - bbOther.min.x : bb.max.x - bbOther.max.x;
        }
        tempSnap = this.updateSnapDataDistances(result[direction], false, tolerance, distance, distanceOther, bb, bbOther);
      } else {
        distanceOther = distX;
        if (side === Side.Top) {
          distance = sideOther === Side.Top ? bb.max.y - bbOther.max.y : bb.max.y - bbOther.min.y;
        } else if (side === Side.Bottom) {
          distance = sideOther === Side.Top ? bb.min.y - bbOther.max.y : bb.min.y - bbOther.min.y;
        }
        tempSnap = this.updateSnapDataDistances(result[direction], true, tolerance, distance, distanceOther, bb, bbOther);
      }

      updateSnapData(direction, side, sideOther, distance, distanceOther);
    };

    checkSides(H, Side.Left, Side.Right);
    checkSides(H, Side.Right, Side.Left);
    checkSides(V, Side.Top, Side.Bottom);
    checkSides(V, Side.Bottom, Side.Top);

    if (!isSameFloors) {
      // Snap to internal wall sides for rooms on different floors.
      checkSides(V, Side.Top, Side.Top);
      checkSides(V, Side.Bottom, Side.Bottom);
      checkSides(H, Side.Left, Side.Left);
      checkSides(H, Side.Right, Side.Right);
    }
  }

  /**
   * Adjusts the alignment distance of snap data based on bounding box alignments.
   *
   * @param bb - The first bounding box.
   * @param bbOther - The second bounding box.
   * @param snapData - The SnapData object to adjust.
   * @param tolerance - The tolerance for alignment checking.
   * @param direction - The direction of the snap data (H or V).
   * @param cornerDistance - The distance to the corner for alignment.
   */
  private static alignSnapData(bb: THREE.Box3, bbOther: THREE.Box3, snapData: SnapData, tolerance: number, direction: Direction, cornerDistance: number): void {
    // Check for alignment based on the corner distance
    if (Math.abs(cornerDistance) <= tolerance) {
      const topDelta = bb.max.y - bbOther.max.y;
      if (Math.abs(topDelta) <= tolerance && Math.abs(topDelta) < Math.abs(snapData.alignDistance)) {
        snapData.alignDistance = -topDelta;
      }

      const bottomDelta = bb.min.y - bbOther.min.y;
      if (Math.abs(bottomDelta) <= tolerance && Math.abs(bottomDelta) < Math.abs(snapData.alignDistance)) {
        snapData.alignDistance = -bottomDelta;
      }

      const rightDelta = bb.max.x - bbOther.max.x;
      if (Math.abs(rightDelta) <= tolerance && Math.abs(rightDelta) < Math.abs(snapData.alignDistance)) {
        snapData.alignDistance = -rightDelta;
      }

      const leftDelta = bb.min.x - bbOther.min.x;
      if (Math.abs(leftDelta) <= tolerance && Math.abs(leftDelta) < Math.abs(snapData.alignDistance)) {
        snapData.alignDistance = -leftDelta;
      }
    }
  }

  private static getSnapDataByBoundingBoxInGrid(bb: THREE.Box3, tolerance: number, sceneUnitSize: number): { [key in Direction]: SnapData } {
    const calculateDistance = (value: number, cellSize): number => {
      const dev = MathUtils.floor(value / cellSize, 1);
      let diff = value - dev * cellSize;
      if (Math.abs(diff) > tolerance) {
        diff = value - (dev + 1) * cellSize;
      }
      if (MathUtils.areNumbersEqual(0, diff)) {
        diff = 0;
      }
      return diff;
    };

    const result = {
      [H]: new SnapData(H),
      [V]: new SnapData(V),
    };

    const cellSize = UnitsUtils.getGridCellSize();
    let minorCellSize = cellSize / GRID_MINOR_CELL_RATIO;
    const minorCellSizePixels = minorCellSize * sceneUnitSize;
    if (minorCellSizePixels < GRID_MIN_CELL_SIZE_CUTOFF_2D) {
      minorCellSize = minorCellSize * 2;
      if (minorCellSizePixels * GRID_MINOR_CELL_RATIO < GRID_MIN_CELL_SIZE_CUTOFF_2D) {
        minorCellSize = cellSize * GRID_MAJOR_CELL_RATIO;
      }
    }
    const diffMinX = calculateDistance(bb.min.x, minorCellSize);
    const diffMinY = calculateDistance(bb.min.y, minorCellSize);
    const diffMaxX = calculateDistance(bb.max.x, minorCellSize);
    const diffMaxY = calculateDistance(bb.max.y, minorCellSize);

    const diffX = Math.abs(diffMinX) > Math.abs(diffMaxX) ? diffMaxX : diffMinX;
    const diffY = Math.abs(diffMinY) > Math.abs(diffMaxY) ? diffMaxY : diffMinY;

    if (Math.abs(diffX) <= tolerance) {
      result[H].distance = -diffX;
    }
    if (Math.abs(diffY) <= tolerance) {
      result[V].distance = -diffY;
    }

    return result;
  }

  private static checkSnapToInternalWall(bb: THREE.Box3, direction: Direction, side: Side, distance: number, internalSegments: Segment[]): boolean {
    return internalSegments.some(segment => {
      if (direction === Direction.Horizontal) {
        if (
          (side === Side.Left && MathUtils.areNumbersEqual(segment.start.x, bb.min.x - distance)) ||
          (side === Side.Right && MathUtils.areNumbersEqual(segment.start.x, bb.max.x + distance))
        ) {
          return MathUtils.isNumberInRange(bb.min.y, segment.start.y, segment.end.y) && MathUtils.isNumberInRange(bb.max.y, segment.start.y, segment.end.y);
        }
      } else {
        if (
          (side === Side.Top && MathUtils.areNumbersEqual(segment.start.y, bb.max.y + distance)) ||
          (side === Side.Bottom && MathUtils.areNumbersEqual(segment.start.y, bb.min.y - distance))
        ) {
          return MathUtils.isNumberInRange(bb.min.x, segment.start.x, segment.end.x) && MathUtils.isNumberInRange(bb.max.x, segment.start.x, segment.end.x);
        }
      }

      return false;
    });
  }
  private static updateSnapDataSharedObjects(
    snapData: SnapData,
    soRoom: THREE.Object3D,
    soRoomOther: THREE.Object3D,
    shiftX: number,
    shiftY: number
  ): SnapData {
    if (snapData.isSameFloor) {
      const wallDirection = snapData.direction === Direction.Horizontal ? Direction.Vertical : Direction.Horizontal;

      const shift = new THREE.Vector3(shiftX, shiftY, 0);
      const soWalls = SceneUtils.getRoomWallLines(soRoom, wallDirection, shift);
      const openingsBbs = soRoom.children
        .filter(item => item.userData.type === RoomEntityType.Window || item.userData.type === RoomEntityType.Door)
        .map(item => {
          const bbox = GeometryUtils.getGeometryBoundingBox2D(item);
          bbox.min.add(shift);
          bbox.max.add(shift);
          return bbox;
        });

      const soOtherWalls = SceneUtils.getRoomWallLines(soRoomOther, wallDirection);
      const openingsBbsOther = soRoomOther?.children
        .filter(item => item.userData.type === RoomEntityType.Window || item.userData.type === RoomEntityType.Door)
        .map(item => GeometryUtils.getGeometryBoundingBox2D(item));

      const sharedOpenings = openingsBbs.filter(item => soOtherWalls.some(w => GeometryUtils.lineIntersectsBoundingBox(w, item)));
      const sharedOpenings2 = openingsBbsOther.filter(item => soWalls.some(w => GeometryUtils.lineIntersectsBoundingBox(w, item)));

      snapData.hasSharedObjects = sharedOpenings.length > 0 || sharedOpenings2.length > 0;
      snapData.hasIntersectedSharedObjects = GeometryUtils.doBoundingBoxesIntersect(sharedOpenings, sharedOpenings2);
    }

    return snapData;
  }
  private static updateSnapDataDistances(
    snapData: SnapData,
    isVertical: boolean,
    maxRange: number,
    distance: number,
    distanceOther: number,
    bb: THREE.Box3,
    bb2: THREE.Box3
  ): SnapData {
    const absoluteDistance = Math.abs(distance);

    if (absoluteDistance <= maxRange && absoluteDistance < snapData.absDistance && distanceOther <= 0) {
      snapData = new SnapData(isVertical ? V : H, -distance);

      const deltaMax = isVertical ? bb.max.x - bb2.max.x : bb.max.y - bb2.max.y;
      const deltaMin = isVertical ? bb.min.x - bb2.min.x : bb.min.y - bb2.min.y;
      const cornerDelta1 = isVertical ? bb.max.x - bb2.min.x : bb.max.y - bb2.min.y;
      const cornerDelta2 = isVertical ? bb.min.x - bb2.max.x : bb.min.y - bb2.max.y;

      if (Math.abs(deltaMax) <= maxRange && Math.abs(deltaMax) < Math.abs(snapData.alignDistance)) {
        snapData.alignDistance = -deltaMax;
      }
      if (Math.abs(deltaMin) <= maxRange && Math.abs(deltaMin) < Math.abs(snapData.alignDistance)) {
        snapData.alignDistance = -deltaMin;
      }
      if (Math.abs(cornerDelta1) <= maxRange && Math.abs(cornerDelta1) < Math.abs(snapData.cornerDistance)) {
        snapData.cornerDistance = -cornerDelta1;
      }
      if (Math.abs(cornerDelta2) <= maxRange && Math.abs(cornerDelta2) < Math.abs(snapData.cornerDistance)) {
        snapData.cornerDistance = -cornerDelta2;
      }
    }

    return snapData;
  }

  private static createColinearSnapLine(p1: THREE.Vector3, p2: THREE.Vector3): THREE.Object3D {
    const result = new THREE.Line(
      new THREE.BufferGeometry().setFromPoints([p1, p2]),
      new THREE.LineDashedMaterial({
        color: MODEL_LINE_COLOR,
        dashSize: 0.3 * UnitsUtils.getConversionFactor(),
        gapSize: 0.3 * UnitsUtils.getConversionFactor(),
        transparent: true,
        opacity: 1.0,
      })
    );
    result.computeLineDistances();
    result.renderOrder = MODEL_LINE_RENDER_ORDER;

    return result;
  }
}
