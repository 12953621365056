import { appModel } from "../../../models/AppModel";
import { Room } from "../../../models/Room";
import SceneManager from "../../managers/SceneManager/SceneManager";
import { soRoom2D } from "../SceneObjects/Room/soRoom2D";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

export class SoAddRoomCommand extends RoomCommand {
  constructor(
    private soRoom: soRoom2D,
    private room?: Room
  ) {
    super(soRoom.userData.id);

    this.type = CommandTypes.AddRoomCommand;
  }

  apply(roomManager: SceneManager): void {
    roomManager.getActiveSoFloor()?.addRoom(this.soRoom);
    appModel.activeFloor.addRoom(this.room);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.soRoom.userData.id]);
  }

  undo(roomManager: SceneManager): void {
    this.room = appModel.activeFloor.rooms.find(r => r.id === this.soRoom.userData.id);

    roomManager.selectRoom(this.soRoom, false);
    roomManager.getActiveSoFloor()?.remove(this.soRoom);

    appModel.activeFloor.removeRoom(this.soRoom.userData.id);
  }
}
